import React from 'react';
import Box from '@mui/material/Box';

export function Insights(props) {

    let tableauReports = [];
    let count = 0;

    props.tableauUrls.forEach(u => {
        let key = "tableau - viz" + (++count);
        tableauReports.push(
            <tableau-viz
                key={key}
                id={key}
                src={u[1]}
                toolbar='hidden'
                device='desktop'
                token={u[0]}>
                <viz-filter
                    field="Cycle_id"
                    value={props.cycleId}>
                </viz-filter>
                <viz-filter
                    field="User_Email"
                    value={props.userEmail}>
                </viz-filter>
            </tableau-viz>
        )
    });

    return (
        <div>
            <Box sx={{ height: "100%" }}>
                {tableauReports} 
            </Box>
        </div>
    )
}