import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { IChartLegend } from '../../../../../models/IChartLegend';
import { IChartConfig } from '../../../../../models/IChartConfig';

import styles from './LineChartWidget.module.css'
import getChartConfig from "./LineChartWidgetConfig";
import { LineChart } from '../../../../Charts/LineChart/LineChart';

interface IProps {
    titleText: string;
    chartLegends: IChartLegend[];
    chartData: any;
}

export function LineChartWidget(props: IProps) {
    const [chartConfig, setChartConfig] = useState<IChartConfig | null>(null);

    useEffect(() => {

        let theChartConfig:any = getChartConfig(); 

        setChartConfig({ data: props.chartData, options: theChartConfig.options });
    }, [props.chartData]);

    let chartLegendItems: JSX.Element[] = []; 
    props.chartLegends.forEach( (chartLegend: IChartLegend ) => {
        chartLegendItems.push( 
            <Grid item >
                <Box style={{ color: 'white' }}>
                    <Typography variant="body2">
                        <span className={styles.bar} style={{ backgroundColor: chartLegend.colour}}></span>
                        {chartLegend.text}
                    </Typography>
                </Box>
            </Grid>
            );
    })

    return <Card sx={{ height: 330,width: '100%', bgcolor: '#222222', color: '#222222',textAlign:'left', backgroundImage:'none'}}>
                { chartConfig &&
                    <Box style={{ padding: '8px 16px 16px 16px' }}>
                        <Box sx={{ color: 'white', textAlign: 'left' }}>
                            <span><Typography variant="h6">{props.titleText}</Typography></span>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                        <Box sx={{ height: '100%', width: '100%' }} >
                                <LineChart chartConfig={chartConfig} backgroundColor={'#222222'} />
                            </Box>
                        </Box>

                        {props.chartLegends.length > 0 &&
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                sx={{ paddingTop: "20px", }}
                            >
                            {chartLegendItems}
                            </Grid>
                        }
                    </Box>
                }
           </Card>
}