import React, { useEffect } from 'react';
import settings from '../../../../config';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import AutoCompleteSelectAll from '../AutoCompleteSelectAll/AutoCompleteSelectAll';

interface Props {
  onMATypeChange: Function;
  selectedCycleId: string;
  filterHasNoData: Function;
}

const DashboardTypeFilter = (props: Props) => {
  const maxRegionNameCharacters = 24;
  let emptyTypes: any[] = [];

  /* Initialisation of the states */
  const [types, setTypes] = React.useState(emptyTypes);
  const [selectedTypes, setSelectedTypes] = React.useState([]);
  const [iniliatised, setIniliatised] = React.useState(false); //Indicates whether the api has been called or not
  let isDisabled = types.length === 0;

  useEffect(() => {
    const getMATypes = async () => {
      try {
        let response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'ManagementArea/managementAreaTypesFromProgramManagementAreas/' + props.selectedCycleId);
        setIniliatised(true);
        if (response && response.data) {
          let data = response.data.sort((a: any, b: any) => a.type.toLowerCase().localeCompare(b.type.toLowerCase()));

          setTypes(data);

          props.filterHasNoData('Type', data.length === 0);

          if (window.localStorage.getItem('cycleId') === props.selectedCycleId) {
            let types = JSON.parse(localStorage.getItem('managementAreaTypes') || '{}');
            if (types.length > 0) {
              let selectedTypes = data.filter((value: any) => types.includes(value.type));
              let isSame = selectedTypes.length === data.length && selectedTypes.every((e: any, i: any) => e.type === data[i].type);

              setSelectedTypes(isSame ? [] : selectedTypes);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (props.selectedCycleId != '0') {
      getMATypes();
    }
  }, [props.selectedCycleId]);

  /*Render*/
  if (!iniliatised) return null; // Render nothing if the api is not called

  return (
    <div>
      <AutoCompleteSelectAll
        options={types}
        selectedOptions={selectedTypes}
        isDisabled={isDisabled}
        noOptionLabel="No types available"
        selectLabel="Select Type"
        selectedAllLabel="All Types"
        keyName="type"
        valueName="type"
        isShowBoth={false}
        cssStyle={null}
        maxCharsInLabel={18}
        maxCharsInOption={22}
        onOptionChange={props.onMATypeChange}
        equalityTest={(option: any, value: any): boolean => option.type === value.type}
      />
    </div>
  );
};

export default DashboardTypeFilter;
