import React, { useState, useEffect } from 'react';
import {
    Grid,
    Box,  
    Popover,
    Typography
} from '@mui/material'
import styles from './MapPicker.module.css'
import { IMapPickerConfig } from '../../../models/IMapPickerConfig';


interface IMapPickerProps {
    config: IMapPickerConfig | null;
    onShapefileDataSelected: Function;
    onClose: Function;
}

export function MapPicker(props: IMapPickerProps) {
    if (props.config == null || (props.config.shapefileData.length) == 0 || props.config.screenX == null || props.config.screenY == null)
        return null; 
      

    let shapefileDataList: JSX.Element[] = [];
    props.config.shapefileData.forEach(shapefileData => {
        console.log(shapefileData)
        shapefileDataList.push(
            <Grid key={shapefileData.id} container className={styles.objectContainer} onClick={() => props.onShapefileDataSelected(shapefileData, props?.config?.screenX, props?.config?.screenY)} >
                <Grid item style={{ width: 37, marginLeft: 3 }}>
                    <img src={`../../../images/MapLayerIcons/${shapefileData.iconName != '' ? shapefileData.iconName : (shapefileData.lineType != '' ? shapefileData.lineType +'_'+ shapefileData.displayColor.substring(1) + '.png' :  'Empty.png')}`} width="30px" />
                </Grid>
                <Grid item >
                    <Box className={styles.bigProp} style={{ paddingTop: 6 }}>
                        <Typography className={styles.text} variant="caption" gutterBottom>{shapefileData.shapefileName}</Typography>
                    </Box>
                </Grid>
            </Grid>
        )
    });

    return <Popover
        onClose={() => props.onClose()}
        anchorPosition={{ left: props.config.screenX - 6, top: props.config.screenY + 10 }}
        anchorReference={'anchorPosition'}
       
        open={true}
    >
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.objectsContainer}>                 
                    {shapefileDataList}
                </div>
            </div>
        </div>
    </Popover>
        ;
}