import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from './ManagementAreaTypeFilter.module.css';
import TextField from '@mui/material/TextField';
import { fetchWithAuthorisationHeader } from '../../../../../services/AuthenticationService';
import settings from '../../../../../config';


interface IManagementAreaTypeFilterProps {
    onFilterChanged: Function;
    clearFilters: number;
    cycleId: string;
}

interface IManagementAreaTypeFilter {
    type: string;
    isSelected: boolean;
}

const labelStyle = {
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7F4A8'
    }
};

export function ManagementAreaTypeFilter(props: IManagementAreaTypeFilterProps) {
    const initialManagementAreaTypes: IManagementAreaTypeFilter[] = [];
    const [ManagementAreaTypes, setManagementAreaTypes] = useState(initialManagementAreaTypes);
    const [filteredManagementAreaTypes, setFilteredManagementAreaTypes] = useState(initialManagementAreaTypes);
    const [lastClearFilters, setLastClearFilters] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const searchFilterRef = useRef<HTMLInputElement>(null);

    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {
        if (props.clearFilters != lastClearFilters) {
            ManagementAreaTypes.forEach(s => s.isSelected = false);
            setManagementAreaTypes(ManagementAreaTypes.slice());
            setSelectAll(false);
            setLastClearFilters(props.clearFilters);

            //reset the search field textbox
            if (searchFilterRef.current) {
                searchFilterRef.current.value = "";
            }
            setFilteredManagementAreaTypes(ManagementAreaTypes);
        }

    }, [props.clearFilters, lastClearFilters, ManagementAreaTypes]);

    //Handle when cycle has been changed
    useEffect(() => {
        async function getManagementAreaTypes() {
            try {
                let ManagementAreaTypeList: IManagementAreaTypeFilter[] = [];
                ManagementAreaTypes.forEach(s => s.isSelected = false);
                let selectedManagementAreaTypes = ManagementAreaTypes.filter(s => s.isSelected && s.type != 'Select All').map(s => s.type);
                props.onFilterChanged(selectedManagementAreaTypes);
                if (props.cycleId != '0') {
                    var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + "managementArea/types/" + props.cycleId);
                    
                    response.data.forEach((t: string) => {
                        // Null values need not be displayed for now. So filter them. 
                        if (t != null) {
                            var newManagementAreaType = { type: t, isSelected: false };
                            ManagementAreaTypeList.push(newManagementAreaType);
                        }
                   });

                    setManagementAreaTypes(ManagementAreaTypeList);
                    setFilteredManagementAreaTypes(ManagementAreaTypeList);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        getManagementAreaTypes();
    }, [props.cycleId]);

    function onSelectAll(selectAll: boolean) {
        if (!selectAll) {
            filteredManagementAreaTypes.forEach(s => s.isSelected = true);
        } else {
            filteredManagementAreaTypes.forEach(s => s.isSelected = false);
        }

        setManagementAreaTypes(ManagementAreaTypes.slice());
        let selectedManagementAreaTypes = ManagementAreaTypes.filter(s => s.isSelected).map(s => s.type);
        props.onFilterChanged(selectedManagementAreaTypes);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(ManagementAreaType: IManagementAreaTypeFilter) {
        ManagementAreaType.isSelected = !ManagementAreaType.isSelected;
        setManagementAreaTypes(ManagementAreaTypes.slice());
        let selectedManagementAreaTypes = ManagementAreaTypes.filter(s => s.isSelected).map(s => s.type);
        props.onFilterChanged(selectedManagementAreaTypes);
        syncSelectAll();
    };

    function syncSelectAll() {
        let selectedManagementAreaTypes = ManagementAreaTypes.filter(s => s.isSelected);
        if (!selectAll && selectedManagementAreaTypes.length == ManagementAreaTypes.length) {
            setSelectAll(true);
        } else if (selectAll && selectedManagementAreaTypes.length < ManagementAreaTypes.length) {
            setSelectAll(false);
        }
    }

    function onManagementAreaTypeChange(value: string) {
        if (value == "") {
            syncSelectAll();
            setFilteredManagementAreaTypes(ManagementAreaTypes);
        } else {
            setFilteredManagementAreaTypes(ManagementAreaTypes.filter(s => s.type.toLowerCase().includes(value.toLowerCase())));
        }
    };

    let ManagementAreaTypeCheckboxes: JSX.Element[] = [];
    filteredManagementAreaTypes.forEach(ManagementAreaType => {
        ManagementAreaTypeCheckboxes.push(
            <FormControlLabel
                key={ManagementAreaType.type}
                sx={{ '& .MuiFormControlLabel-label': ManagementAreaType.isSelected ? labelStyle.labelSelected : labelStyle.label }}
                control={<Checkbox color = "secondary" checked={ManagementAreaType.isSelected} style={{ opacity: '0.5 !important' }}  onChange={() => onCheckboxChanged(ManagementAreaType)} name={ManagementAreaType.type} size={'small'} />}
                label={ManagementAreaType.type}
            />
        );
    });

    let selectAllCheckbox = (
        <FormControlLabel
            key={"Select All"}
            sx={{ '& .MuiFormControlLabel-label': selectAll ? labelStyle.labelSelected : labelStyle.label }}
            control={<Checkbox color="secondary"  style={{ opacity: '0.5 !important' }} checked={selectAll}  onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
            label={"Select All"}
        />
    );

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <FormGroup>
                    <TextField inputRef={searchFilterRef} size="small" style={{ color: '#F7F4A8', marginTop: 8 }} id="ManagementAreaType-filter" label="Search" onChange={(event) => onManagementAreaTypeChange(event.target.value)} />
                    {selectAllCheckbox}
                    {ManagementAreaTypeCheckboxes}
                </FormGroup>
            </div>
        </div>
    );
}
