import { Divider, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/system';
import { IApproval } from '../../../../models/IApproval';
import styles from './ApprovalListItem.module.css';
import moment from 'moment';
import { ApprovalStatusColor } from '../../../../Enum/ApprovalStatusColor';

interface IApprovalListItemProps {
    approval: IApproval;
    onAssetSelected: Function;
    onApprovalSelected: Function;
}

export default function ApprovalListItem(props: IApprovalListItemProps) {

    const onAssetSelected = (e: any) => {
        e.stopPropagation();
        props.onAssetSelected(props.approval.asset);
    }

    return (
        <Grid container spacing={2} onClick={() => props.onApprovalSelected(props.approval) }>
            <Grid item xs={0.75}>
                <span>
                    <CheckCircleIcon
                        className={styles.checkCircle}
                        style={{ color: props.approval.isActive ? ApprovalStatusColor[props.approval.status as keyof typeof ApprovalStatusColor] : ApprovalStatusColor["Inactive"] }}
                    />
                </span>
            </Grid>

             <Grid item xs={1.75}>
              <Box>
                    <Typography className={styles.itemHeader}  variant="subtitle2">Approval</Typography>
                    <Typography className={styles.itemContent}  variant="subtitle1">{props.approval.isActive? "Active" : "Inactive"}</Typography>
              </Box>
            </Grid>

            <Grid item xs={2.5}>
                <Box>
                    <Typography className={styles.itemHeader}  variant="subtitle2">Date</Typography>
                    <Typography className={styles.itemContent}  variant="subtitle1">{moment(props.approval.createdDate).local().format('DD-MM-YYYY')}</Typography>
                </Box>
            </Grid>

            <Grid item xs={3}>
                <Box>
                    <Typography className={styles.itemHeader}  variant="subtitle2">Task Type</Typography>
                    <Typography className={styles.itemContent}  variant="subtitle1">{props.approval.taskType}</Typography>
                </Box>
            </Grid>

            <Grid item xs={4}>
                <Box onClick={(e: any) => onAssetSelected(e)} style={{ cursor: "pointer"}}>
                    <Typography className={styles.itemHeader}  variant="subtitle2" >Span ID</Typography>
                    <u><Typography className={styles.itemContent} variant="subtitle1">{props.approval.asset.externalReferenceId}</Typography></u>
                </Box>
            </Grid>

            <Grid container direction="column">
                <Grid item>
                    <Divider style={{marginLeft: 18}}/>
                </Grid>
            </Grid>
        </Grid>
    );
}
