import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';

import { IChartLegend } from '../../../../../models/IChartLegend';
import { IAssetTaskQualityValues } from '../../../../../models/IAssetTaskQualityValues';
import { DonutChartWidget } from '../../../Shared/Widgets/DonutChartWidget/DonutChartWidget';

interface IProps {
    assetTaskQualityCounts: IAssetTaskQualityValues | null;
}

export function SRAuditConformanceWidget(props: IProps) {

    const titleText = 'Audit conformance - SR'

    const [firstChartLegends, set1stChartLegends] = useState<IChartLegend[]>([]);
  
    const [chartData, setChartData] = useState<object | null>(null);

    const [centreBigText, setCentreBigText] = useState('');
    const [totalAuditCount, setTotalAuditCount] = useState(0);

    useEffect(() => {
        if (props.assetTaskQualityCounts != null && props.assetTaskQualityCounts.auditReasonsData != null) {

            setTotalAuditCount(props.assetTaskQualityCounts.totalSRAuditTasks);
            setCentreBigText(props.assetTaskQualityCounts.totalSRAuditTasks.toString());
            setChartData(getChartData(props.assetTaskQualityCounts));
        }
    }, [props.assetTaskQualityCounts]);

    // Returns a ChartJS data object
    function getChartData(auditValues: IAssetTaskQualityValues) {

        let data: any = {};
        let dataset: any = {};
        data.datasets = [dataset];
        dataset.borderWidth = 2;
      
        set1stChartLegends([
            { text: 'Assessed as conformant', colour: '#BCE89A', borderColour: '#BCE89A' },
            { text: 'Treated as conformant', colour: '#084C1B', borderColour: '#084C1B' },
            { text: 'Assessed as non-conformant', colour: '#F7E777', borderColour: '#F7E777' },
            { text: 'Treated as non-conformant', colour: '#BC8927', borderColour: '#BC8927' },
        ]);

        data.labels = ['Assessed as conformant', 'Treated as conformant', 'Assessed as non-conformant', 'Treated as non-conformant'];
        dataset.data = [auditValues.totalSRAssessmentAuditConformant,
                        auditValues.totalSRTreatmentAuditConformant,
                        auditValues.totalSRAssessmentAuditNonConformant,
                        auditValues.totalSRTreatmentAuditNonConformant];
        dataset.borderColor = [' #BCE89A', '#084C1B', '#F7E777', '#BC8927'];
        dataset.backgroundColor = [' #BCE89A', '#084C1B', '#F7E777', '#BC8927'];

        return data;
    }

    function tooltipLabelCallback(tooltipItem: any) {
        // This is called by ChartJs onMouseOver the chart
        return tooltipItem.label + ': ' + tooltipItem.formattedValue;
    };

    return <Card sx={{ height: "100%", backgroundImage: 'none' }}>
        {chartData &&
            <DonutChartWidget
                topText={''}
                centreBigText={centreBigText}
                titleText={titleText}
                totalCount={totalAuditCount}
                firstChartLegends={firstChartLegends}
                chartData={chartData}
                tooltipLabelCallback={tooltipLabelCallback}
            ></DonutChartWidget>
        }
    </Card>
}