import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from './ManagementAreaStatusFilter.module.css';
import TextField from '@mui/material/TextField';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import settings from '../../../../config';

interface IManagementAreaStatusFilterProps {
    onFilterChanged: Function;
    clearFilters: number;
    cycleId: string;
}

interface IManagementAreaStatusFilter {
    id: number;
    name: string;
    isSelected: boolean;
}

const labelStyle = {
    label: {
        fontSize: 14,
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7F4A8',
    },
};

export function ManagementAreaStatusFilter(props: IManagementAreaStatusFilterProps) {
    const initialManagementAreaStatuses: IManagementAreaStatusFilter[] = [];

    const [managementAreaStatuses, setManagementAreaStatuses] = useState(initialManagementAreaStatuses);
    const [filteredManagementAreaStatuses, setFilteredManagementAreaStatuses] = useState(initialManagementAreaStatuses);
    const [lastClearFilters, setLastClearFilters] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const searchFilterRef = useRef<HTMLInputElement>(null);

    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {
        if (props.clearFilters !== lastClearFilters) {
            managementAreaStatuses.forEach((s) => (s.isSelected = false));
            setManagementAreaStatuses(managementAreaStatuses.slice());
            setSelectAll(false);
            setLastClearFilters(props.clearFilters);

            //reset the search field textbox
            if (searchFilterRef.current) {
                searchFilterRef.current.value = '';
            }
            setFilteredManagementAreaStatuses(managementAreaStatuses);
        }
    }, [props.clearFilters, lastClearFilters, managementAreaStatuses]);

    //Handle when cycle has been changed
    useEffect(() => {
        async function getManagementAreaTypes() {
            try {
                let ManagementAreaStatusList: IManagementAreaStatusFilter[] = [];
                managementAreaStatuses.forEach((s) => (s.isSelected = false));
                let selectedManagementAreaTypes = managementAreaStatuses.filter((s) => s.isSelected && s.name != 'Select All').map((s) => s.id);
                props.onFilterChanged(selectedManagementAreaTypes);
                if (props.cycleId != '0') {
                    var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'managementArea/statuses/');

                    response.data.forEach((s: IManagementAreaStatusFilter) => {
                        var managementAreaStatus = { id: s.id, name: s.name, isSelected: false };
                        ManagementAreaStatusList.push(managementAreaStatus);
                    });

                    setManagementAreaStatuses(ManagementAreaStatusList);
                    setFilteredManagementAreaStatuses(ManagementAreaStatusList);
                }
            } catch (e) {
                console.log(e);
            }
        }
        getManagementAreaTypes();
    }, [props.cycleId]);

    function onSelectAll(selectAll: boolean) {
        if (!selectAll) {
            filteredManagementAreaStatuses.forEach((s) => (s.isSelected = true));
        } else {
            filteredManagementAreaStatuses.forEach((s) => (s.isSelected = false));
        }

        setManagementAreaStatuses(managementAreaStatuses.slice());
        let selectedManagementAreaStatuses = managementAreaStatuses.filter((s) => s.isSelected).map((s) => s.id);
        props.onFilterChanged(selectedManagementAreaStatuses);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(managementAreaStatus: IManagementAreaStatusFilter) {
        managementAreaStatus.isSelected = !managementAreaStatus.isSelected;
        setManagementAreaStatuses(managementAreaStatuses.slice());
        let selectedManagementAreaStatuses = managementAreaStatuses.filter((s) => s.isSelected).map((s) => s.id);
        props.onFilterChanged(selectedManagementAreaStatuses);
        syncSelectAll();
    }

    function syncSelectAll() {
        let selectedManagementAreaStatuses = managementAreaStatuses.filter((s) => s.isSelected);
        if (!selectAll && selectedManagementAreaStatuses.length === managementAreaStatuses.length) {
            setSelectAll(true);
        } else if (selectAll && selectedManagementAreaStatuses.length < managementAreaStatuses.length) {
            setSelectAll(false);
        }
    }

    function onManagementAreaStatusChange(value: string) {
        if (value === '') {
            syncSelectAll();
            setFilteredManagementAreaStatuses(managementAreaStatuses);
        } else {
            setFilteredManagementAreaStatuses(managementAreaStatuses.filter((s) => s.name.toLowerCase().includes(value.toLowerCase())));
        }
    }

    let ManagementAreaStatusCheckboxes: JSX.Element[] = [];
    filteredManagementAreaStatuses.forEach((ManagementAreaStatus) => {
        ManagementAreaStatusCheckboxes.push(
            <FormControlLabel
                key={ManagementAreaStatus.id}
                sx={{ '& .MuiFormControlLabel-label': ManagementAreaStatus.isSelected ? labelStyle.labelSelected : labelStyle.label }}
                control={
                    <Checkbox
                        color="secondary"
                        checked={ManagementAreaStatus.isSelected}
                        style={{ opacity: '0.5 !important' }}
                        onChange={() => onCheckboxChanged(ManagementAreaStatus)}
                        name={ManagementAreaStatus.name}
                        size={'small'}
                    />
                }
                label={ManagementAreaStatus.name}
            />
        );
    });

    let selectAllCheckbox = (
        <FormControlLabel
            key={'Select All'}
            sx={{ '& .MuiFormControlLabel-label': selectAll ? labelStyle.labelSelected : labelStyle.label }}
            control={
                <Checkbox color="secondary" style={{ opacity: '0.5 !important' }} checked={selectAll} onChange={() => onSelectAll(selectAll)} name={'Select All'} size={'small'} />
            }
            label={'Select All'}
        />
    );

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <FormGroup>
                    <TextField
                        inputRef={searchFilterRef}
                        size="small"
                        style={{ color: '#F7F4A8', marginTop: 8 }}
                        id="ManagementAreaStatus-filter"
                        label="Search"
                        onChange={(event) => onManagementAreaStatusChange(event.target.value)}
                    />
                    {selectAllCheckbox}
                    {ManagementAreaStatusCheckboxes}
                </FormGroup>
            </div>
        </div>
    );
}
