import React, { useState, useEffect } from "react";
import renderToStaticMarkup from "react-dom/server";
import {
    Grid,
    Box,
    Popover,
    Typography,
    IconButton,
    ImageList,
    ImageListItem  

} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import Popper, { PopperProps } from "@mui/material/Popper";
import styles from "./ShapefileDataDetails.module.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IShapefileDataDetailsConfig } from "../../../models/IShapefileDataDetailsConfig";

interface IShapefileDataDetailsProp {
  config: IShapefileDataDetailsConfig | null;
  onClose: Function;
}

export function ShapefileDataDetails(props: IShapefileDataDetailsProp) {
  let shapefileName: string = "";
  let iconPath: string = "../../../images/MapLayerIcons/";
  let fullIconPath: string = "";
  const [attributesPanelOpen, setattributesPanelOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState<PopperProps["anchorEl"]>(null);

  useEffect(() => {
    if (props.config) {
      const selection: ClientRect = {
          top: props.config.screenY + 5,
          right: props.config.screenX + 12,
          width: 1,
          height: 1,
          bottom: props.config.screenY + 1,
          left: props.config.screenX - 1,
          x: 0,
          y: 0,
          toJSON: function() {
              throw new Error("Function not implemented.");
          }
      };
      const getBoundingClientRect = () => selection;

      setAnchorEl({
      
        getBoundingClientRect: getBoundingClientRect,
      });
    }
  }, [props.config]);

  let attributesElement: JSX.Element[] = [];
  if (
    props.config &&
    props.config.shapefileData &&
    props.config.screenX &&
    props.config.screenY 
  ) {
    shapefileName = props.config.shapefileData.shapefileName;
      fullIconPath = `${iconPath}${props.config.shapefileData.iconName ? props.config.shapefileData.iconName : props.config.shapefileData.icon}`;
    var attributes:any = props.config.shapefileData.attributes;
      attributesElement.push(
          <ImageList cols={1}>
              {Object.keys(attributes).map((key) => (
                  <ImageListItem key={key} className={styles.attributes}>
                      <Grid container style={{ color: props.config?.shapefileData?.attributeSearchValue && props.config?.shapefileData.attributeSearchValue.toLowerCase() == attributes[key].toLowerCase() ? '#F7B500' : '#D6D7D8'}}>
                          <Grid item xs={6}>
                              <Typography className={styles.text} variant="caption" gutterBottom>
                                  {key.toLowerCase()}
                              </Typography>
                      </Grid>
                          <Grid item xs={6} style={{ fontSize: 12, paddingRight: 5 }}>
                              <Typography className={styles.text} variant="caption" gutterBottom>{attributes[key]}</Typography>
                      </Grid>
                    </Grid>
                  </ImageListItem >
              ))}
          </ImageList>
    );
  }


return (
  <Popper
    placement="right-start"
    disablePortal={false}
    open={true}
    anchorEl={anchorEl}   
  >
    <div className={styles.container}>
            <Grid container className={styles.content} alignItems="center" style={{ display: 'flex' }} >

        <Grid item  className={styles.header} >
                    <Grid container justifyContent="space-between" alignItems="center"style={{display:'flex'}}>
            <Grid item alignItems="center"style={{display:'flex'}} >
              <img src={fullIconPath} style={{ paddingRight:6}}></img>
            </Grid>

            <Grid item>
              <IconButton
                disableRipple={true}
                disableFocusRipple={true}
                onClick={() => props.onClose()}
              >
                <ClearIcon className={styles.clear} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={styles.header} style={{paddingTop:7}} >
          <Grid container alignItems="center"style={{display:'flex'}}>
            <Grid item alignItems="center" style={{display:'flex'}} >
              <a style={{ fontWeight: 400, fontSize: 16 }}>
                <Typography className={styles.text} variant="caption" gutterBottom>   {shapefileName}</Typography>
              </a>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ width: '100%',  display: 'block', marginRight: 6}}>
          <Grid container justifyContent="space-between" direction="row" alignItems="center" style={{display:'flex'}}>
            <Grid item  alignItems="center" style={{display:'flex',}}>
              <span
                className={styles.header}
                style={{fontWeight: 500 }}
                            >
                  <Typography className={styles.text} variant="caption" gutterBottom>Attributes</Typography>
              </span>
            </Grid>

            <Grid item >
              <IconButton
                style={{marginRight: -10, fontWeight: 500 }}
                onClick={() => setattributesPanelOpen(!attributesPanelOpen)}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Grid>

          </Grid>
          <Grid item>
          {attributesPanelOpen && (
            <div style={{ marginBottom: 24 }}> {attributesElement} </div>
          )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  </Popper>
);
          }
