import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { DoughnutChart } from '../../../../Charts/DoughnutChart/DoughnutChart';
import { IChartLegend } from '../../../../../models/IChartLegend';
import { IChartConfig } from '../../../../../models/IChartConfig';

import styles from './HalfDoughnutWidget.module.css'
import getChartConfig from "./HalfDoughnutChartConfig";

interface IProps {
    topText: string;
    titleText: string;
    centreBigText: string;
    totalCount:number;
    chartLegends: IChartLegend[];
    chartData: any;
    tooltipFooterCallback?: Function | null;
    tooltipLabelCallback: Function | null;
}

export function HalfDoughnutWidget(props: IProps) {
    const [chartConfig, setChartConfig] = useState<IChartConfig | null>(null);

    useEffect(() => {

        let theChartConfig:any = getChartConfig(); 

        if ( props.tooltipFooterCallback != null ){
            theChartConfig.options.plugins.tooltip.callbacks.footer = props.tooltipFooterCallback;
        }

        if ( props.tooltipLabelCallback != null ){
            theChartConfig.options.plugins.tooltip.callbacks.label = props.tooltipLabelCallback;
        }

        // Display tooltip ourselves so that it will display on top of other text
        theChartConfig.options.plugins.tooltip.external = externalTooltipHandler;

        setChartConfig({data: props.chartData, options: theChartConfig.options });
    }, [props.chartData, props.tooltipFooterCallback, props.tooltipLabelCallback ]);

    const getOrCreateTooltip = (chart:any) => {
      let tooltipEl = chart.canvas.parentNode.querySelector('div');

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'black';
        tooltipEl.style.borderRadius = '5px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';
        tooltipEl.style.width = '200px';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    // Display tooltip ourselves so that it will display on top of other text
    const externalTooltipHandler = (context:any) => {

      const {chart, tooltip} = context;
      const tooltipEl = getOrCreateTooltip(chart);

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set Text
      if (tooltip.body) {
        //const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b:any) => b.lines);

        const tableHead = document.createElement('thead');

        const tableBody = document.createElement('tbody');
        bodyLines.forEach((body:any, i:any) => {
          const colors = tooltip.labelColors[i];

          // Square for segment colour
          const span = document.createElement('span');
          span.style.borderColor = colors.borderColor;
          span.style.background = colors.backgroundColor; 
          span.style.borderWidth = '2px';
          span.style.marginRight = '10px';
          span.style.height = '10px';
          span.style.width = '10px';
          span.style.display = 'inline-block';

          const tr:any = document.createElement('tr');
          tr.style.backgroundColor = 'black';
          tr.style.borderWidth = 0;

          const td:any = document.createElement('td');
          td.style.borderWidth = 0;

          const text = document.createTextNode(body);

          td.appendChild(span);
          td.appendChild(text);
          tr.appendChild(td);
          tableBody.appendChild(tr);
        });

        tooltip.footer.forEach((footer:any) => {
          const tr:any = document.createElement('tr');
          tr.style.backgroundColor = 'black';
          tr.style.borderWidth = 0;

          const td:any = document.createElement('td');
          td.style.borderWidth = 0;

          const text = document.createTextNode(footer);

          td.appendChild(text);
          tr.appendChild(td);
          tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');

        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }

      const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + 'px';

      if ( tooltip.caretX < 60 ){
          tooltipEl.style.left = '60px';
      }

      tooltipEl.style.top = positionY + tooltip.caretY + 'px';
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    };

    let chartLegendItems: JSX.Element[] = []; 
    props.chartLegends.forEach( (chartLegend: IChartLegend ) => {

        chartLegendItems.push( 
            <Grid item >
                <Box style={{color:'white'}}>
                    <Typography variant="h6" style={{fontSize:12}}>                    
                        <span className={styles.dot} style={{height: 10, width: 10, backgroundColor: chartLegend.colour, marginRight:9, borderColor: chartLegend.borderColour }}></span>
                        {chartLegend.text}
                    </Typography>
                </Box>
            </Grid>
            );
    })

    return <Card sx={{ height: 235,bgcolor: '#222222', color: '#222222',textAlign:'left', backgroundImage:'none'}}>
                { chartConfig &&
                    <Box style={{padding:'8px 16px 16px 16px'}}>
                        <Box sx={{ height: "20px",color:'white', textAlign:'center'}}>
                            <span><Typography variant="h6" style={{fontSize:10}}>{props.topText}</Typography></span>
                        </Box>
                        <Box 
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                            <Box sx={{ height: 135,width: 210,position:'relative'}} >  
                                <div style={{position:'absolute',bottom:40,color: 'white',width:'100%',textAlign:'center', pointerEvents:'none'}} >        
                                    <Typography variant="h4">{props.centreBigText}</Typography>
                                </div>
                                <Box sx={{ height: 'calc(100% - 35px)',width: '100%'}} >
                                    <DoughnutChart chartConfig={chartConfig} backgroundColor={'#222222'}/>
                                </Box>
                                <Box sx={{ height: "10px",color:'white'}}>
                                    <span style={{float:'left'}}> <Typography variant="h6" style={{fontSize:10}}>0</Typography></span>
                                    <span style={{float:'right'}}><Typography variant="h6" style={{fontSize:10}}>{props.totalCount}</Typography></span>
                                </Box>
                                <Box sx={{ height: "20px",color:'white',textAlign:'center'}}>
                                    <Typography variant="body2">{props.titleText}</Typography>
                                </Box>            
                            </Box>
                        </Box>
                        { props.chartLegends.length > 0 &&
                          <Box >
                            <Grid 
                              sx={{height:80}}
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"    
                            >
                             {chartLegendItems}
                            </Grid>
                          </Box>
                        }  
                    </Box>
                }
           </Card>
}