
// Returns data and options config for use with react-chartjs-2 / chart.js
// See https://www.chartjs.org/docs/3.9.1/configuration/
function getConfig() {
  return {
    options: {
      responsive: true,
      aspectRatio: 1.3, // clips edges tight so no wasted space
      borderWidth: 0,
      maintainAspectRatio: true,
      plugins: {
        datalabels: {
            color: 'white',
            display: function(context) {
              
              let totalCount = 0;
              context.dataset.data.forEach(n => totalCount += n );

              let thisValPercent = ( context.dataset.data[context.dataIndex] / totalCount) * 100;

              let minPercentForDisplay = 10;

              return thisValPercent >= minPercentForDisplay;
            },
            font: {
              weight: 'bold'
            },
            formatter: Math.round
        },
        legend: {
          display: false,
        },
       title: {
        display: false,
        },
        tooltip: {
            enabled: true,
            callbacks: {
            }
        }
      },
    },
  };
}

export default getConfig;
