import { Divider, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import settings from '../../../../config';
import { IProgramNameType } from '../../../../models/IProgramNameType';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import ProgramListItem from '../ProgramListItem/ProgramListItem';

interface IProgramListProps {
  managementAreaId: number;
}

export default function ProgramList(props: IProgramListProps) {
  const [programList, setProgramList] = useState<IProgramNameType[]>([]);

  useEffect(() => {
    async function getManagementAreaDetail() {
      try {
        var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'Program/programsByManagementAreaId/' + props.managementAreaId);
        setProgramList(response.data as IProgramNameType[]);
      } catch (e) {
        console.log(e);
        setProgramList([]);
      }
    }

    setProgramList([]);

    if (props.managementAreaId > 0) {
      getManagementAreaDetail();
    }
  }, [props.managementAreaId]);

  return (
    <>
      {programList.map((item: IProgramNameType, i: number) => (
        <div>
          <ProgramListItem key={i} program={item} managementAreaId={props.managementAreaId} />
          <Grid item xs={12}>
            <Divider sx={{ marginBottom: '8px' }} light={true} />
          </Grid>
        </div>
      ))}
    </>
  );
}
