import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import styles from './ApprovalResults.module.css';
import Typography from '@mui/material/Typography';
import { Divider, Grid } from '@mui/material';
import { IAssetSearchFilters } from '../../../../models/IAssetSearchFilters';
import { postWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import settings from '../../../../config';
import { IApproval } from '../../../../models/IApproval';
import { IApprovalsSearchRequest } from '../../../../models/IApprovalsSearchRequest';
import { IProgressChartDetails } from '../../../../models/IProgressChartDetails';
import ApprovalListItem from '../ApprovalListItem/ApprovalListItem';
import { ApprovalStatusColor } from '../../../../Enum/ApprovalStatusColor';
import LinearProgressChart from '../../../Charts/LinearProgressChart/LinearProgressChart';
import { IAsset } from '../../../../models/IAsset';

interface IApprovalResultsProps {
    cycleId: string;
    managementAreaId: number; 
    spanListSearchGuid: string;
    selectedAssetSearchFilters: IAssetSearchFilters | null;
    isApprovalRefreshRequired: boolean;
    onAssetSelected: Function;
    onApprovalSelected: Function;
    onApprovalResultsUpdated: Function;
}

export function ApprovalResults(props: IApprovalResultsProps) {

    const [approvals, setApprovals] = useState<IApproval[]>([]);
    const [counts, setCounts] = useState<IProgressChartDetails[]>([]);
        
    function calculateApprovalStatusCounts(approvals: IApproval[]) {
        if (approvals.length > 0) {
            let uniqueStatuses = Array.from(new Set(approvals.map((approval: any) => approval.status))).sort();
            let counts: IProgressChartDetails[] =[];

            uniqueStatuses.forEach((status: string) => {
                let count = {
                    name: status,
                    count: approvals.reduce((acc, cur) => cur.status == status ? ++acc : acc, 0),
                    color: ApprovalStatusColor[status as keyof typeof ApprovalStatusColor]
                }
                counts.push(count);
            });
            setCounts(counts);
        }
    }
    
    async function getApprovals() {
        try {
            let approvalsSearchRequest: IApprovalsSearchRequest = {
                managementAreaId: props.managementAreaId,
                searchId: props.spanListSearchGuid,
                filters: props.selectedAssetSearchFilters
            }

            let response = await postWithAuthorisationHeader(settings.CMPLY_API_URL + "approval/fetch", approvalsSearchRequest);
            if (response.status == 200) {
                let approvals = response.data as IApproval[];
                setApprovals(approvals);
                calculateApprovalStatusCounts(approvals);
                props.onApprovalResultsUpdated();
            }
        }
        catch (e) {
                console.log(e);
        }
    }

    const getPercentage = (taskCount: number) => {
        return approvals.length > 0 ? (100 * taskCount) / approvals.length : 0;
    };
  
    useEffect(() => {
        if (props.managementAreaId > 0 && (props.isApprovalRefreshRequired || props.spanListSearchGuid)) {
            getApprovals();
        }
    }, [props.spanListSearchGuid, props.isApprovalRefreshRequired, props.managementAreaId]);

    const noResults = (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '90%' }}>
            <Typography style={{ color: "white" }} variant="subtitle2" gutterBottom>
                No approvals, please refine your search
            </Typography>
        </Box>
    );

    const approvalResultsTab: JSX.Element = (
        <div>
            {approvals.length > 0 && (
                <Box sx={{ marginTop: '-28px', marginBottom: '20px' }}>
                    {counts.map((item: IProgressChartDetails, i: number) => (
                        <LinearProgressChart key={i} color={item.color} title={item.name} value={item.count} progress={getPercentage(item.count)} />
                    ))}
                </Box>
            )}
            <Box className={styles.content}>
                {approvals.map((item: IApproval, i: number) => (
                    <div>
                        <ApprovalListItem
                            key={i} approval={item}
                            onAssetSelected={(asset: IAsset) => props.onAssetSelected(asset)}
                            onApprovalSelected={(approval: IApproval) => props.onApprovalSelected(approval) }
                        />
                    </div>
                ))}
            </Box>
        </div>
    );

    return (
        <div>
            {approvals.length > 0 ? approvalResultsTab : noResults}
        </div>
    );
 }
