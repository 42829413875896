import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from './ManagementAreaNameFilter.module.css';
import TextField from '@mui/material/TextField';
import { fetchWithAuthorisationHeader } from '../../../../../services/AuthenticationService';
import settings from '../../../../../config';


interface IManagementAreaNameFilterProps {
    onFilterChanged: Function;
    clearFilters: number;
    cycleId: string;
}

interface IManagementAreaNameFilter {
    id: number;
    name: string;
    isSelected: boolean;
}

const labelStyle = {
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7F4A8'
    }
};

export function ManagementAreaNameFilter(props: IManagementAreaNameFilterProps) {
    const initialManagementAreaNames: IManagementAreaNameFilter[] = [];
    const [managementAreaNames, setManagementAreaNames] = useState(initialManagementAreaNames);
    const [filteredManagementAreaNames, setFilteredManagementAreaNames] = useState(initialManagementAreaNames);
    const [lastClearFilters, setLastClearFilters] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const searchFilterRef = useRef<HTMLInputElement>(null);

    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {
        if (props.clearFilters != lastClearFilters) {
            managementAreaNames.forEach(s => s.isSelected = false);
            setManagementAreaNames(managementAreaNames.slice());
            setSelectAll(false);
            setLastClearFilters(props.clearFilters);

            //reset the search field textbox
            if (searchFilterRef.current) {
                searchFilterRef.current.value = "";
            }
            setFilteredManagementAreaNames(managementAreaNames);
        }

    }, [props.clearFilters, lastClearFilters, managementAreaNames]);

    //Handle when cycle has been changed
    useEffect(() => {
        async function getManagementAreaNames() {
            try {
                let managementAreaNameList: IManagementAreaNameFilter[] =[];
                managementAreaNames.forEach(s => s.isSelected = false);
                let selectedManagementAreaNames = managementAreaNames.filter(s => s.isSelected && s.name != 'Select All').map(s => s.name);
                props.onFilterChanged(selectedManagementAreaNames);
                if (props.cycleId != '0') {
                    var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + "managementArea/cycle/" + props.cycleId);
                    var managementAreas = response.data;

                    managementAreas.forEach(((managementArea: { managementAreaId: any; managementAreaName: any; }) => {
                        // Null values need not be displayed for now. So filter them. 
                        if (managementArea.managementAreaName != null) {
                            var newManagementArea = { id: managementArea.managementAreaId, name: managementArea.managementAreaName, isSelected: false };
                            managementAreaNameList.push(newManagementArea);
                        }
                    }));

                    setManagementAreaNames(managementAreaNameList);
                    setFilteredManagementAreaNames(managementAreaNameList);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        getManagementAreaNames();
    }, [props.cycleId]);

    function onSelectAll(selectAll: boolean) {
        if (!selectAll) {
            filteredManagementAreaNames.forEach(s => s.isSelected = true);
        } else {
            filteredManagementAreaNames.forEach(s => s.isSelected = false);
        }

        setManagementAreaNames(managementAreaNames.slice());
        let selectedManagementAreaNames = managementAreaNames.filter(s => s.isSelected).map(s => s.id);
        props.onFilterChanged(selectedManagementAreaNames);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(managementAreaName: IManagementAreaNameFilter) {
        managementAreaName.isSelected = !managementAreaName.isSelected;
        setManagementAreaNames(managementAreaNames.slice());
        let selectedManagementAreaNames = managementAreaNames.filter(s => s.isSelected).map(s => s.id);
        props.onFilterChanged(selectedManagementAreaNames);
        syncSelectAll();
    };

    function syncSelectAll() {
        let selectedManagementAreaNames = managementAreaNames.filter(s => s.isSelected);
        if (!selectAll && selectedManagementAreaNames.length == managementAreaNames.length) {
            setSelectAll(true);
        } else if (selectAll && selectedManagementAreaNames.length < managementAreaNames.length) {
            setSelectAll(false);
        }
    }

    function onManagementAreaNameChange(value: string) {
        if (value == "") {
            syncSelectAll();
            setFilteredManagementAreaNames(managementAreaNames);
        } else {
            setFilteredManagementAreaNames(managementAreaNames.filter(s => s.name.toLowerCase().includes(value.toLowerCase())));
        }
    };

    let managementAreaNameCheckboxes: JSX.Element[] = [];
    filteredManagementAreaNames.forEach(managementAreaName => {
        managementAreaNameCheckboxes.push(
            <FormControlLabel
                key={managementAreaName.name}
                sx={{ '& .MuiFormControlLabel-label': managementAreaName.isSelected ? labelStyle.labelSelected : labelStyle.label }}
                control={<Checkbox color = "secondary" checked={managementAreaName.isSelected} style={{ opacity: '0.5 !important' }}  onChange={() => onCheckboxChanged(managementAreaName)} name={managementAreaName.name} size={'small'} />}
                label={managementAreaName.name}
            />
        );
    });

    let selectAllCheckbox = (
        <FormControlLabel
            key={"Select All"}
            sx={{ '& .MuiFormControlLabel-label': selectAll ? labelStyle.labelSelected : labelStyle.label }}
            control={<Checkbox color="secondary"  style={{ opacity: '0.5 !important' }} checked={selectAll}  onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
            label={"Select All"}
        />
    );

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <FormGroup>
                    <TextField inputRef={searchFilterRef} size="small" style={{ color: '#F7F4A8', marginTop: 8 }} id="managementAreaName-filter" label="Search" onChange={(event) => onManagementAreaNameChange(event.target.value)} />
                    {selectAllCheckbox}
                    {managementAreaNameCheckboxes}
                </FormGroup>
            </div>
        </div>
    );
}
