import styles from './AssetListItem.module.css';
import Typography from '@mui/material/Typography';
import { IAsset } from '../../../../models/IAsset';
import { Divider, Grid } from '@mui/material';
import { SpanStatusColor } from '../../../../Enum/SpanStatusColor';


interface IAssetResultProps {
    asset: IAsset;
    onAssetSelected: Function;
}

export function AssetListItem(props: IAssetResultProps) {
    if (props.asset == null) return null;

    return (
        <Grid container className={styles.container} >
            <Grid container direction="column">
                <Grid item>
                    <Divider />
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginBottom: 8 }} onClick={() => props.onAssetSelected(props.asset)} >
                <span className={styles.dot}
                    style={{ backgroundColor: SpanStatusColor[props.asset.statusAcrossAllPrograms as keyof typeof SpanStatusColor], borderColor: SpanStatusColor[props.asset.statusAcrossAllPrograms as keyof typeof SpanStatusColor] }}>
                </span>
                <Typography variant="subtitle2">{props.asset.externalReferenceId}</Typography>
            </Grid>
            
        </Grid>
    ); 
}
