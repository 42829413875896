import React, { useState, useEffect } from 'react'
import {
    Grid,
    Box,
    Badge,
    Chip,
    Tooltip,
    Avatar,
    Switch,
    FormControlLabel,
    FormControl,
    Typography
} from '@mui/material'
import styles from './LayerItem.module.css'

interface ILayerItemProps {
    group: string | "",
    label: string | "---",
    thumbnail: string | "",
    isActive: boolean | true,
    isAvailable: boolean | true,
    onVisibilityToggle: Function
}

export function LayerItem(props: ILayerItemProps) {
    const [isAvailable, setIsAvailable] = useState(props.isAvailable)
    const [isActive, setIsActive] = useState(props.isActive)
    const [label, setLabel] = useState(props.label)

    //method designed to be fired only when user checks/unchecks
    const triggerSetActive = (e: React.ChangeEvent<HTMLInputElement>) => {
        // setIsActive(e.target.checked);
        //send back to layerlist
        props.onVisibilityToggle(props.group, props.label, e.target.checked)
    }

    const switchDom = (
        <div>
            <span>
                <Typography className={styles.text} variant="caption" gutterBottom>
                <a style={{ color: props.isActive && props.isAvailable ? 'rgba(249, 252, 255, 0.75)' : 'rgba(249,252,255,0.4)' }}>Off</a>
                </Typography>
            </span>
            <Switch
                disabled={!props.isAvailable}
                onChange={triggerSetActive}
                checked={props.isActive}
                color={'primary'}
                name='genericSwitch'
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <span>
            <Typography className={styles.text} variant="caption" gutterBottom>
                <a style={{ color: props.isActive && props.isAvailable ? 'rgba(249, 252, 255, 0.75)' : 'rgba(249,252,255,0.4)' }}>On</a>
                </Typography>
                </span>
        </div>
    )

    const layerAvailableStyle = {}

    useEffect(() => {
        //if (props.isActive      !=    isActive)      setIsActive(props.isActive);
        //incoming
        if (props.isAvailable != isAvailable) setIsAvailable(props.isAvailable)
        if (props.label != label) setLabel(props.label)
    }, [props.isActive, props.isAvailable, props.label])

    return (
        <Grid
            container
            className={styles.gridRow}
            spacing={1}>
            <Grid item
                className={styles.content}
                style={{ minWidth: "100%" }}
            >
                <Grid container spacing={3} className={styles.mapItemContainer}>
                    {/* MAIN container */}
                    <Grid item style={{ "minWidth": "20px", paddingLeft: "30px" }}>
                        <img
                            src={props.thumbnail} width="40px"></img>
                    </Grid>
                    <Grid item style={{ "minWidth": "50%" }}>
                        <Box className={styles.bigProp} style={{ "opacity": props.isAvailable ? "1" : "0.4" }}>
                            <span>
                                <Typography className={styles.text} variant="body2" gutterBottom>
                                    {props.label || "---"}
                                </Typography>
                            </span>
                        </Box>
                        <Box className={styles.sessionLabel} style={{ "opacity": props.isAvailable ? "0.75" : "0.4" }}>
                            <span>
                                <Typography className={styles.text} variant="caption" gutterBottom>
                                    {props.isActive ? "Layer Visible" : "Layer Hidden"}
                                </Typography>
                            </span>
                        </Box>
                    </Grid>
                    <Grid item>
                        {switchDom}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>);
}

