import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';

import { IChartLegend } from '../../../../../models/IChartLegend';
import { IAssetTaskQualityValues } from '../../../../../models/IAssetTaskQualityValues';
import { DonutChartWidget } from '../../../Shared/Widgets/DonutChartWidget/DonutChartWidget';

interface IProps {
    assetTaskQualityCounts: IAssetTaskQualityValues | null;
}

export function AuditNonConformanceWidget(props: IProps) {

    const titleText = 'Audit non-conformance'

    const [firstChartLegends, set1stChartLegends] = useState<IChartLegend[]>([]);
    const [chartData, setChartData] = useState<object | null>(null);

    const [centreBigText, setCentreBigText] = useState('');
    const [totalNonConformanceCount, setTotalNonConformanceCount] = useState(0);

    useEffect(() => {       
        if (props.assetTaskQualityCounts != null && props.assetTaskQualityCounts.auditReasonsData !=null) {

            setTotalNonConformanceCount(props.assetTaskQualityCounts.totalNonConformanceCount);
            setCentreBigText(props.assetTaskQualityCounts.totalNonConformanceCount.toString());

           
            setChartData(getChartData(props.assetTaskQualityCounts));
           
        }

    }, [props.assetTaskQualityCounts]);

    // Returns a ChartJS data object
    function getChartData(nonConformanceValues: IAssetTaskQualityValues) {

        let data: any = {};

        let dataset: any = {};
        let values: number[] = [];
        let descriptions: string[] = [];
        let colours: IChartLegend[] = [];
        let colourNos: string[] = [];
        data.datasets = [dataset];
        dataset.borderWidth = 2;
        Object.entries(nonConformanceValues.auditReasonsData).forEach(([key, value]) => {
            values.push(value);
            Object.entries(nonConformanceValues.auditReasonsDescription).forEach(([keydesc, desc]) => {   
                if(keydesc == key)
                   descriptions.push(desc);
            });
            Object.entries(nonConformanceValues.auditReasonsColour).forEach(([keycolour, colour]) => {
                if (keycolour == key) {
                    colours.push({ text: keycolour.toUpperCase(), colour: colour, borderColour: colour });
                    colourNos.push(colour);
                }
            });
        });
        set1stChartLegends(colours);
        data.labels = descriptions;
        dataset.data = values;
        dataset.borderColor =colourNos;
        dataset.backgroundColor = colourNos;

        return data;
    }

    function tooltipLabelCallback(tooltipItem: any) {
        // This is called by ChartJs onMouseOver the chart
        return tooltipItem.label + ': ' + tooltipItem.formattedValue;
    };

    return <Card sx={{ height: "100%", backgroundImage: 'none' }}>
        {chartData &&
            <DonutChartWidget
                topText={''}
                centreBigText={centreBigText}
                titleText={titleText}
                totalCount={totalNonConformanceCount}
                firstChartLegends={firstChartLegends}
                chartData={chartData}
                tooltipLabelCallback={tooltipLabelCallback}
            ></DonutChartWidget>
        }
    </Card>
}