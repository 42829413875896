import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { IAssetTaskQualityValues } from '../../../../../models/IAssetTaskQualityValues';
import { SimpleDisplayCountWidget } from '../../../Shared/Widgets/SimpleDisplayCountWidget/SimpleDisplayCountWidget';

interface IProps {
    assetTaskQualityCounts: IAssetTaskQualityValues | null;
}

export function NoAuditManagementAreasWidget(props: IProps) {

    const topText = 'MAs commenced, no audits';
    const height = 145;
    const width = 184;
    const color = "#47211B";
    const [bottomText, setBottomText] = useState('')
    const [count, setCount] = useState(0);


    useEffect(() => {
        let totalCount = 0
        if (props.assetTaskQualityCounts != null) {
            setCount(props.assetTaskQualityCounts.noAuditManagementAreaCount);
            totalCount = props.assetTaskQualityCounts.totalMAWithAtLeastOneProgramCount;
        }
        setBottomText("Across " + totalCount + " MA" + (totalCount > 1 ? 's' : ''));
    }, [props.assetTaskQualityCounts]);

    return <Card sx={{ height: "100%", backgroundImage: 'none' }}>
        <SimpleDisplayCountWidget
            height={height}
            width={width}
            color={color}
            topText={topText}
            count={count}
            bottomText={bottomText} />
    </Card>
}