import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import { IDashboardProgramManagementArea } from "../../../../../models/IDashboardProgramManagementArea";
import { IProgramAsset } from "../../../../../models/IProgramAsset";
import { SimpleDisplayCountWidget } from "../../../Shared/Widgets/SimpleDisplayCountWidget/SimpleDisplayCountWidget";

interface IProps {
    count: number;
    countLBRA: number;
    countHBRA: number;
}

export function TreatmentsOverdueWidget(props: IProps) {

    const topText = 'Treatments overdue';
    const height = 120;
    const width = 164;
    const color = "#47211B";
    const [bottomText, setBottomText] = useState('HBRA - 0, LBRA - 0')
    const [count, setCount] = useState(0);

    useEffect(() => {

        let dueAssetCount = props.count;
        setCount(dueAssetCount);
        setBottomText(`HBRA - ${props.countHBRA}, LBRA -  ${props.countLBRA}`);

    }, [props.count]);

    return <Card sx={{ height: "100%", backgroundImage: 'none' }}>
        <SimpleDisplayCountWidget
            height={height}
            width={width}
            color={color}
            topText={topText}
            count={count ?? 0}
            bottomText={bottomText} />
    </Card>
}