import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from './ProgramTypeFilter.module.css';
import TextField from '@mui/material/TextField';
import { fetchWithAuthorisationHeader } from '../../../../../services/AuthenticationService';
import settings from '../../../../../config';


interface IProgramTypeFilterProps {
    onFilterChanged: Function;
    clearFilters: number;
    cycleId: string;
}

interface IProgramType {
    id: number;
    name: string;
    isSelected: boolean;
}

const labelStyle = {
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7F4A8'
    }
};

export function ProgramTypeFilter(props: IProgramTypeFilterProps) {
    const initialProgramTypes: IProgramType[] = [];
    const [programTypes, setProgramTypes] = useState(initialProgramTypes);
    const [filteredProgramTypes, setFilteredProgramTypes] = useState(initialProgramTypes);
    const [lastClearFilters, setLastClearFilters] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const searchFilterRef = useRef<HTMLInputElement>(null);


    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {
        if (props.clearFilters != lastClearFilters) {
            programTypes.forEach(s => s.isSelected = false);
            setProgramTypes(programTypes.slice());
            setSelectAll(false);
            setLastClearFilters(props.clearFilters);

            //reset the search field textbox
            if (searchFilterRef.current) {
                searchFilterRef.current.value = "";
            }
            setFilteredProgramTypes(programTypes);
        }
    }, [props.clearFilters, lastClearFilters, programTypes]);

    //Handle when cycle has been changed
    useEffect(() => {
        async function getProgramTypes() {
            try {
                let programTypeList: IProgramType[] =[];
                programTypes.forEach(s => s.isSelected = false);
                let selectedProgramTypes = programTypes.filter(s => s.isSelected && s.name != 'Select All').map(s => s.id);
                props.onFilterChanged(selectedProgramTypes);
                if (props.cycleId != '0') {
                    var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + "program/programTypes/");
                    var programTypesResponse = response.data;

                    programTypesResponse.forEach(((type: { id: any; name: any; }) => {
                        var newProgramType = { id: type.id, name: type.name, isSelected: false };
                        programTypeList.push(newProgramType);
                    }));

                    setProgramTypes(programTypeList);
                    setFilteredProgramTypes(programTypeList);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        getProgramTypes();
    }, [props.cycleId]);

    function onSelectAll(selectAll: boolean) {
        if (!selectAll) {
            filteredProgramTypes.forEach(s => s.isSelected = true);
        } else {
            filteredProgramTypes.forEach(s => s.isSelected = false);
        }

        setProgramTypes(programTypes.slice());
        let selectedProgramTypes = programTypes.filter(s => s.isSelected).map(s => s.id);
        props.onFilterChanged(selectedProgramTypes);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(programType: IProgramType) {
        programType.isSelected = !programType.isSelected;
        setProgramTypes(programTypes.slice());
        let selectedProgramTypes = programTypes.filter(s => s.isSelected).map(s => s.id);
        props.onFilterChanged(selectedProgramTypes);
        syncSelectAll();
    };

    function syncSelectAll() {
        let selectedProgramTypes = programTypes.filter(s => s.isSelected);
        if (!selectAll && selectedProgramTypes.length == programTypes.length) {
            setSelectAll(true);
        } else if (selectAll && selectedProgramTypes.length < programTypes.length) {
            setSelectAll(false);
        }
    }

    function onProgramTypeChange(value: string) {
        if (value == "") {
            syncSelectAll();
            setFilteredProgramTypes(programTypes);
        } else {
            setFilteredProgramTypes(programTypes.filter(s => s.name.toLowerCase().includes(value.toLowerCase())));
        }
    };

    let ProgramTypeCheckboxes: JSX.Element[] = [];
    filteredProgramTypes.forEach(programType => {
        ProgramTypeCheckboxes.push(
            <FormControlLabel
                key={programType.name}
                sx={{ '& .MuiFormControlLabel-label': programType.isSelected ? labelStyle.labelSelected : labelStyle.label }}
                control={<Checkbox color="secondary" checked={programType.isSelected} style={{ opacity: '0.5 !important' }} onChange={() => onCheckboxChanged(programType)} name={programType.name} size={'small'} />}
                label={programType.name}
            />
        );
    });

    let selectAllCheckbox = (
        <FormControlLabel
            key={"Select All"}
            sx={{ '& .MuiFormControlLabel-label': selectAll ? labelStyle.labelSelected : labelStyle.label }}
            control={<Checkbox color="secondary"  style={{ opacity: '0.5 !important' }} checked={selectAll}  onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
            label={"Select All"}
        />
    );

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <FormGroup>
                    <TextField inputRef={searchFilterRef} size="small" style={{ color: '#F7F4A8', marginTop:8 }} id="ProgramType-filter" label="Search" onChange={(event) => onProgramTypeChange(event.target.value)} />
                    {selectAllCheckbox}
                    {ProgramTypeCheckboxes}
                </FormGroup>
            </div>
        </div>
    );
}
