import React, { useEffect } from 'react';
import settings from '../../../../config';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import AutoCompleteSelectAll from '../AutoCompleteSelectAll/AutoCompleteSelectAll';

interface Props {
  onRegionIdChange: Function;
  selectedCycleId: string;
  filterHasNoData: Function;
}

const DashboardRegionFilter = (props: Props) => {
  const maxRegionNameCharacters = 24;
  let emptyRegions: any[] = [];

  /* Initialisation of the states */
  const [regions, setRegions] = React.useState(emptyRegions);
  const [selectedRegions, setSelectedRegions] = React.useState([]);
  const [iniliatised, setIniliatised] = React.useState(false);
  let isDisabled = regions.length === 0;

  useEffect(() => {
    const getRegions = async () => {
      try {
        let response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'Region/regionFromProgramManagementAreas/' + props.selectedCycleId);
        setIniliatised(true);
        if (response && response.data) {
          let data = response.data.sort((a: any, b: any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          setRegions(data);

          props.filterHasNoData('Region', data.length === 0);

          if (window.localStorage.getItem('cycleId') === props.selectedCycleId) {
            let ids = JSON.parse(localStorage.getItem('selectedRegionIds') || '{}');
            if (ids.length > 0) {
              let selectedRegions = data.filter((value: any) => ids.includes(value.id));
              let isSame = selectedRegions.length === data.length && selectedRegions.every((e: any, i: any) => e.id === data[i].id && e.name === data[i].name);

              setSelectedRegions(isSame ? [] : selectedRegions);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (props.selectedCycleId != '0') {
      getRegions();
    }
  }, [props.selectedCycleId]);

  /*Render*/
  if (!iniliatised) return null; // Render nothing if the api is not called

  return (
    <div>
      <AutoCompleteSelectAll
        options={regions}
        selectedOptions={selectedRegions}
        isDisabled={isDisabled}
        noOptionLabel="No regions available"
        selectLabel="Select Region"
        selectedAllLabel="All Regions"
        keyName="id"
        valueName="name"
        isShowBoth={false}
        cssStyle={null}
        maxCharsInLabel={18}
        maxCharsInOption={22}
        onOptionChange={props.onRegionIdChange}
        equalityTest={(option: any, value: any): boolean => option.id === value.id && option.name === value.name}
      />
    </div>
  );
};

export default DashboardRegionFilter;
