import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from './NewSpanFilter.module.css';

interface IProps {
    onFilterChanged: Function;
    clearFilters: number;
    cycleId: string;
}

interface INewSpanFilter {
    name: string;
    isSelected: boolean;
}

const labelStyle = {
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7F4A8'
    }
};

export function NewSpanFilter(props: IProps) {

    const allNewSpanOptions: INewSpanFilter[] = [
        { name: 'Contractor created', isSelected: false },
    ];

    const [newSpans, setNewSpans] = useState(allNewSpanOptions);
    const [lastClearFilters, setLastClearFilters] = useState(0);

    useEffect(() => {
        if (props.clearFilters != lastClearFilters) {
              newSpans.forEach((s) => (s.isSelected = false));
              setNewSpans(newSpans.slice());
              setLastClearFilters(props.clearFilters);
            }
      }, [props.clearFilters, lastClearFilters]);

    //Handle when cycle has been changed
    useEffect(() => {
        newSpans.forEach((s) => (s.isSelected = false));
        setNewSpans(newSpans.slice()); 
        let selectedNewSpans = newSpans.filter(s => s.isSelected).map(s => s.name);

        props.onFilterChanged(selectedNewSpans);
    }, [props.cycleId]);

    function onCheckboxChanged(newSpan: INewSpanFilter) {
        newSpan.isSelected = !newSpan.isSelected;
        setNewSpans(newSpans.slice());
        let selectedNewSpans = newSpans.filter(s => s.isSelected).map(s => s.name);
        props.onFilterChanged(selectedNewSpans);
    };

    let checkboxes = newSpans.map(newSpan => {
        return(
            <FormControlLabel
                key={newSpan.name}
                sx={{ '& .MuiFormControlLabel-label': newSpan.isSelected ? labelStyle.labelSelected : labelStyle.label }}
                control={<Checkbox color = "secondary" checked={newSpan.isSelected} style={{ opacity: '0.5 !important' }}  onChange={() => onCheckboxChanged(newSpan)} name={newSpan.name} size={'small'} />}
                label={newSpan.name}
            />
        );
    });

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <FormGroup>
                    {checkboxes}
                </FormGroup>
            </div>
        </div>
    );
}
