import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from './CompanyFilter.module.css';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import settings from '../../../../config';

interface IProps {
    onFilterChanged: Function;
    clearFilters: number;
    cycleId: string;
}

interface ICompanyFilter {
    id: number;
    name: string;
    isSelected: boolean;
}

const labelStyle = {
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7F4A8'
    }
};

export function CompanyFilter(props: IProps) {
   
    const defaultCompanies: ICompanyFilter[] = [];
    const [companies, setCompanies] = useState(defaultCompanies);
    const [filteredCompanies, setFilteredCompanies] = useState(defaultCompanies);
    const [lastClearFilters, setLastClearFilters] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const searchFilterRef = useRef<HTMLInputElement>(null);

    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {
        if (props.clearFilters != lastClearFilters) {
              companies.forEach((s) => (s.isSelected = false));
              setCompanies(companies.slice());
              setSelectAll(false);
            setLastClearFilters(props.clearFilters);

            //reset the search field textbox
            if (searchFilterRef.current) {
                searchFilterRef.current.value = "";
            }
            setFilteredCompanies(companies);
            }
    }, [props.clearFilters, lastClearFilters]);

    //Handle when cycle has been changed
    useEffect(() => {
        async function getCompanies() {
            try {
                let companies: ICompanyFilter[] = [];
                companies.forEach(s => s.isSelected = false);
                let selectedCompanyIds = companies.filter(s => s.isSelected && s.name != 'Select All').map(s => s.id);
                props.onFilterChanged(selectedCompanyIds);
                if (props.cycleId != '0') {
                    var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + "company/cycle/" + props.cycleId);
                    var companiesResponse = response.data;

                    companiesResponse.forEach(((company: { name: any; id: any; }) => {
                        var newCompany = { id: company.id, name: company.name, isSelected: false };
                        companies.push(newCompany);
                    }));

                    setCompanies(companies);
                    setFilteredCompanies(companies);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        getCompanies();
    }, [props.cycleId]);

    function onSelectAll(selectAll: boolean) {
        if (!selectAll) {
            filteredCompanies.forEach((c) => (c.isSelected = true));
        } else {
            filteredCompanies.forEach((c) => (c.isSelected = false));
        }
        setCompanies(companies.slice());
        let selectedcompanyIds = companies.filter((c) => c.isSelected).map((c) => c.id);
        props.onFilterChanged(selectedcompanyIds);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(company: ICompanyFilter) {
        company.isSelected = !company.isSelected;
        setCompanies(companies.slice());
        let selectedCompanyIds = companies.filter(s => s.isSelected).map(s => s.id);
        props.onFilterChanged(selectedCompanyIds);
        syncSelectAll();
    };

    function syncSelectAll() {
        let selectedCompanies = companies.filter(s => s.isSelected);
        if (!selectAll && selectedCompanies.length == companies.length) {
            setSelectAll(true);
        } else if (selectAll && selectedCompanies.length < companies.length) {
            setSelectAll(false);
        }
    }

    let checkboxes = companies.map(company => {
        return(
            <FormControlLabel
                key={company.name}
                sx={{ '& .MuiFormControlLabel-label': company.isSelected ? labelStyle.labelSelected : labelStyle.label }}
                control={<Checkbox color="secondary" checked={company.isSelected} style={{ opacity: '0.5 !important' }} onChange={() => onCheckboxChanged(company)} name={company.name} size={'small'} />}
                label={company.name}
            />
        );
    });

    let selectAllCheckbox = (
        <FormControlLabel
            key={"Select All"}
            sx={{ '& .MuiFormControlLabel-label': selectAll ? labelStyle.labelSelected : labelStyle.label }}
            control={<Checkbox color="secondary"  style={{ opacity: '0.5 !important' }} checked={selectAll}  onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
            label={"Select All"}
        />
    );

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <FormGroup>
                    {selectAllCheckbox}
                    {checkboxes}
                </FormGroup>
            </div>
        </div>
    );
}
