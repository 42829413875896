import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from './RegionFilter.module.css';
import TextField from '@mui/material/TextField';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import settings from '../../../../config';


interface IRegionFilterProps {
    onFilterChanged: Function;
    clearFilters: number;
    cycleId: string;
}

interface IRegion {
    id: number;
    name: string;
    isSelected: boolean;
}

const labelStyle = {
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7F4A8'
    }
};

export function RegionFilter(props: IRegionFilterProps) {
    const initialRegions: IRegion[] = [];
    const [regions, setRegions] = useState(initialRegions);
    const [filteredRegions, setFilteredRegions] = useState(initialRegions);
    const [lastClearFilters, setLastClearFilters] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const searchFilterRef = useRef<HTMLInputElement>(null);

    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {
        if (props.clearFilters != lastClearFilters) {
            regions.forEach(s => s.isSelected = false);
            setRegions(regions.slice());
            setSelectAll(false);
            setLastClearFilters(props.clearFilters);

            //reset the search field textbox
            if (searchFilterRef.current) {
                searchFilterRef.current.value = "";
            }
            setFilteredRegions(regions);
        }

    }, [props.clearFilters, lastClearFilters, regions]);

    //Handle when cycle has been changed
    useEffect(() => {
        async function getRegions() {
            try {
                let regionList: IRegion[] =[];
                regions.forEach(s => s.isSelected = false);
                let selectedRegions = regions.filter(s => s.isSelected && s.name != 'Select All').map(s => s.name);
                props.onFilterChanged(selectedRegions);
                if (props.cycleId != '0') {
                    var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + "Region/cycle/" + props.cycleId);
                    var regionsResponse = response.data;

                    regionsResponse.forEach(((region: { name: any; regionId: any; }) => {
                        var newRegion = { id: region.regionId, name: region.name, isSelected: false };
                        regionList.push(newRegion);
                    }));

                    setRegions(regionList);
                    setFilteredRegions(regionList);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        getRegions();
    }, [props.cycleId]);

    function onSelectAll(selectAll: boolean) {
        if (!selectAll) {
            filteredRegions.forEach(s => s.isSelected = true);
        } else {
            filteredRegions.forEach(s => s.isSelected = false);
        }

        setRegions(regions.slice());
        let selectedRegions = regions.filter(s => s.isSelected).map(s => s.id);
        props.onFilterChanged(selectedRegions);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(Region: IRegion) {
        Region.isSelected = !Region.isSelected;
        setRegions(regions.slice());
        let selectedRegions = regions.filter(s => s.isSelected).map(s => s.id);
        props.onFilterChanged(selectedRegions);
        syncSelectAll();
    };

    function syncSelectAll() {
        let selectedRegions = regions.filter(s => s.isSelected);
        if (!selectAll && selectedRegions.length == regions.length) {
            setSelectAll(true);
        } else if (selectAll && selectedRegions.length < regions.length) {
            setSelectAll(false);
        }
    }

    function onRegionChange(value: string) {
        if (value == "") {
            syncSelectAll();
            setFilteredRegions(regions);
        } else {
            setFilteredRegions(regions.filter(s => s.name.toLowerCase().includes(value.toLowerCase())));
        }
    };

    let regionCheckboxes: JSX.Element[] = [];
    filteredRegions.forEach(Region => {
        regionCheckboxes.push(
            <FormControlLabel
                key={Region.name}
                sx={{ '& .MuiFormControlLabel-label': Region.isSelected ? labelStyle.labelSelected : labelStyle.label }}
                control={<Checkbox color = "secondary" checked={Region.isSelected} style={{ opacity: '0.5 !important' }}  onChange={() => onCheckboxChanged(Region)} name={Region.name} size={'small'} />}
                label={Region.name}
            />
        );
    });

    let selectAllCheckbox = (
        <FormControlLabel
            key={"Select All"}
            sx={{ '& .MuiFormControlLabel-label': selectAll ? labelStyle.labelSelected : labelStyle.label }}
            control={<Checkbox color="secondary"  style={{ opacity: '0.5 !important' }} checked={selectAll}  onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
            label={"Select All"}
        />
    );

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <FormGroup>
                    <TextField inputRef={searchFilterRef} size="small" style={{ color: '#F7F4A8', marginTop:8 }} id="Region-filter" label="Search" onChange={(event) => onRegionChange(event.target.value)} />
                    {selectAllCheckbox}
                    {regionCheckboxes}
                </FormGroup>
            </div>
        </div>
    );
}
