import * as React from 'react';

let styles =  require('./Common.module.css');


//-------------------------------- UTIL functions ---------------------------
export function deepClone<T>(o:T):T {
    const JSClone = (o:any) => JSON.parse(JSON.stringify(o));
    return JSClone(o) as T;
}


const usePrevious = (value: any) => {
    const ref = React.useRef();

    React.useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

export default usePrevious;