//Source https://stackoverflow.com/a/7220510
export function prettyJSON(json: string) {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        var cls = 'color: darkorange;';             //Number
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'color: #287ccc;';            //Key
            } else {
                cls = 'color: #35d4c7;';            //String
            }
        } else if (/true|false/.test(match)) {
            cls = 'color: blue;';                   //boolean
        } else if (/null/.test(match)) {
            cls = 'color: magenta;';                //null
        }
        return '<span style="' + cls + '; white-space: pre-wrap">' + match + '</span>';
    });
}
