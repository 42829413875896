import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import settings from '../../../../config';
import { fetchWithAuthorisationHeader, postWithAuthorisationHeader } from '../../../../services/AuthenticationService';

import { IDashboardFilters } from '../../../../models/IDashboardFilters';
import { NoAuditManagementAreasWidget } from '../Widgets/NoAuditManagementAreasWidget/NoAuditManagementAreasWidget';
import { IAssetTaskQualityValues } from '../../../../models/IAssetTaskQualityValues';
import { AuditNonConformanceWidget } from '../Widgets/AuditNonConformanceWidget/AuditNonConformanceWidget';
import { SRAuditConformanceWidget } from '../Widgets/SRAuditConformanceWidget/SRAuditConformanceWidget';
import { FPAuditConformanceWidget } from '../Widgets/FPAuditConformanceWidget/FPAuditConformanceWidget';
import { CompleteProgramMasWithAuditWidget } from '../Widgets/CompleteProgramMAsWithAuditWidget/CompleteProgramMAsWithAuditWidget';


// To Do: Replace this with filter props 
interface IProps {
    cycleId: string;
    filters: IDashboardFilters;
    chartBackgroundColor: string;
}

export function AuditQualityContainer(props: IProps) {

    const [assetTaskQualityCounts, setAssetTaskQualityCounts] = useState<IAssetTaskQualityValues | null > (null);
      

    // Handle when the cycle has changed
    async function getAssetTaskQualityCounts() {
        try {
            var response = await postWithAuthorisationHeader(settings.CMPLY_API_URL + "dashboard/assetTaskQualityValues/", props.filters);
         
            setAssetTaskQualityCounts(response.data);
            console.log(response.data)

        }
        catch (e) {
            console.log('Failed to get span performance values:' + e);
        }
    }
    useEffect(() => {
        if (props.filters.cycleId != '0') {
            getAssetTaskQualityCounts();
        }

    }, [props.filters]);

    return <Card sx={{ height: "100%", width: "100%", bgcolor: '#161616', backgroundImage: 'none' }}>
        <Grid container direction="column" >
            <Grid item sx={{ borderRadius: '5px', marginBottom: 1 }}>
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} md={6} lg={5}>

                        <Box mb={3} sx={{ height: "100%", borderRadius: '5px' }}>
                            <CompleteProgramMasWithAuditWidget assetTaskQualityCounts={assetTaskQualityCounts} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box mb={3} sx={{ height: "100%", borderRadius: '5px' }}>
                            <NoAuditManagementAreasWidget assetTaskQualityCounts={assetTaskQualityCounts} />
                        </Box>
                    </Grid>
                  
                </Grid>
            </Grid>
            <Grid item sx={{ borderRadius: '5px', marginBottom: 1 }}>
                <Grid item xs={12} md={6} lg={4}>
                    <Box sx={{ minWidth: "400px", height: "100%", borderRadius: '5px', marginBottom: 1 }}>
                        <FPAuditConformanceWidget assetTaskQualityCounts={assetTaskQualityCounts} />
                    </Box>
                </Grid>
            </Grid>
            <Grid item sx={{ borderRadius: '5px', marginBottom: 1 }}>
                <Grid item xs={12} md={6} lg={4}>
                    <Box sx={{ minWidth: "400px", height: "100%", borderRadius: '5px', marginBottom: 1 }}>
                        <SRAuditConformanceWidget assetTaskQualityCounts={assetTaskQualityCounts} />
                    </Box>
                </Grid>
            </Grid>
            <Grid item sx={{ borderRadius: '5px', marginBottom: 1 }}>
                <Grid item xs={12} md={6} lg={4}>
                    <Box sx={{ minWidth: "400px", height: "100%", borderRadius: '5px', marginBottom: 1 }}>
                        <AuditNonConformanceWidget assetTaskQualityCounts={assetTaskQualityCounts} />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    </Card >
}