import React, { useState, useCallback, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';

import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import { postWithAuthorisationHeader } from "../../../../services/AuthenticationService";
import settings from '../../../../config';

import styles from './AssetList.module.css';
import { IAsset } from '../../../../models/IAsset';
import { AssetListItem } from '../AssetListItem/AssetListItem';
import { IAssetSearchCreateResponse } from '../../../../models/IAssetSearchCreateResponse';
import { IAssetSearchFetchRequest } from '../../../../models/IAssetSearchFetchRequest';
import { IAssetSearchFetchResponse } from '../../../../models/IAssetSearchFetchResponse';


interface IAssetResultsProps {
    cycleId: string;
    managementAreaId: number;
    totalAssetCount: number;
    onAssetSelected: Function;
    assetSearch: IAssetSearchCreateResponse | null;
}

interface IFetchCallbackProps {
    pageIndex: number;
}

export function AssetList(props: IAssetResultsProps) {
    const pageSize: number = 2;
    const defaultPageIndex: number = 1;
    const defaultAssetArray: IAsset[] = [];
    const defaultRenderedAssetResults: JSX.Element[] = [];
    const prevManagemnetAreaIdRef = useRef(0);
    const scrollPos = useRef(0);
    const listRef = useRef(null);
   
    // State
    const [pageIndex, setPageIndex] = useState(defaultPageIndex);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);
    const [assetResults, setAssetResults] = useState(defaultAssetArray);
    const [loading, setLoading] = useState(false);
    const [renderedAssetResults, setRrenderedAssetResults] = useState(defaultRenderedAssetResults);
    const [initialised, setInitialised] = useState(false);

    const fetchAssets = useCallback(async (cbprops: IFetchCallbackProps) => {
        if (props == null || props.managementAreaId == null) {
            return;
        }

        try {
            const assetFetchRequest: IAssetSearchFetchRequest = {
                searchId: props.assetSearch?.searchId ?? '00000000-0000-0000-0000-a00000000000',
                cycleId: props.cycleId,
                managementAreaId: props.managementAreaId,
                paginated: true,
                pageIndex: cbprops.pageIndex,
                pageSize: pageSize
            };

            setRrenderedAssetResults(defaultRenderedAssetResults);
            let response = await postWithAuthorisationHeader(settings.CMPLY_API_URL + "assetSearch/fetch", assetFetchRequest);

            if (response.status === 200) {
                let searchResponse = response.data as IAssetSearchFetchResponse;
               
                let newLength = assetResults.length + searchResponse.assets.length;
                setAssetResults(prev => [...prev, ...searchResponse.assets]);

                setInitialised(true);
                setLoading(false);
                if (newLength < props.totalAssetCount) {
                    setHasNextPage(true);
                }
                setIsNextPageLoading(false);             
            }
            else {
                console.log("Failed to get asset results. Code = " + response.status);
            }
        }
        catch (e) {
            console.log(e);
        }

    }, [pageIndex, pageSize, props.totalAssetCount, assetResults]);

    function loadNextPage(startIndex: number, stopIndex: number) {
        setLoading(true);
        setIsNextPageLoading(true);
        let newPageIndex = pageIndex + 1;
        if (!initialised) {
            // Make sure InititeScroll loads from the first index      
            newPageIndex = 1;
        }

        setPageIndex(newPageIndex);

        return fetchAssets({ pageIndex: newPageIndex });
    };
    const itemCount = hasNextPage ? assetResults.length + 1 : assetResults.length;
    const isItemLoaded = (index: number) => {

        return !hasNextPage || index < assetResults.length;
    }
    const emptyFunc = (startIndex: number, stopIndex: number) => { };
    let loadMoreItems = isNextPageLoading ? emptyFunc : loadNextPage;

    useEffect(() => {
        let doSearch = false;
        let thePageIndex = pageIndex;
     
        if (props.managementAreaId !== prevManagemnetAreaIdRef.current) {
            doSearch = true;
            setRrenderedAssetResults(defaultRenderedAssetResults);
            setInitialised(false);
            //The search has changed so reset to defaults
            thePageIndex = defaultPageIndex;
            setPageIndex(thePageIndex);
        }

        //Store the searchid so we can work out when it changes
        prevManagemnetAreaIdRef.current = props.managementAreaId;

        if (doSearch && props.totalAssetCount > 0) {
            setInitialised(true);
            //dont fetch here let inifinite scroll take care of it
        }
        // this needs to be done to reset the infinite loader cache
        setAssetResults(defaultAssetArray);

    }, [props.managementAreaId]);
    

    function AssetRow(rowProps: ListChildComponentProps) {
        let asset = assetResults[rowProps.index];
        if (asset != null) {
            if (renderedAssetResults[rowProps.index] == null || renderedAssetResults[rowProps.index] == undefined) {

                // Render it if it is not already rendered.
                renderedAssetResults[rowProps.index] =
                    <div key={asset.id} style={rowProps.style}>
                      <AssetListItem 
                        asset={asset}
                        onAssetSelected={(asset:any) => props.onAssetSelected(asset)} 
                      />
                   </div>
            }

            return renderedAssetResults[rowProps.index];
        }
        else {
            return null;
        }
    }

    return (
        <Box display="flex" flexDirection="column" className={styles.content} >
            <Box flexGrow={1} >
                <div style={{ height: '100%' }} ref={listRef} >
                    {props.managementAreaId && props.totalAssetCount > 0 ?
                        <Box>
                            <InfiniteLoader
                                isItemLoaded={isItemLoaded}
                                itemCount={itemCount}
                                loadMoreItems={loadMoreItems}                                
                            >
                                {({ onItemsRendered, ref }) => (
                                    <List
                                        className="List"
                                        height={255}
                                        itemCount={itemCount}
                                        itemSize={46}
                                        width={415}
                                        ref= {ref}
                                        initialScrollOffset={scrollPos != null ? scrollPos.current : 0}
                                        onItemsRendered={onItemsRendered}
                                        onScroll={(e: any) => scrollPos.current = e.scrollOffset}
                                    >
                                        {AssetRow}
                                    </List>
                                )}
                            </InfiniteLoader>
                        </Box> : null}
                </div>
            </Box>
        </Box>
    );
}
