import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import settings from '../../../../config';
import { postWithAuthorisationHeader } from '../../../../services/AuthenticationService';

import { AssessmentsPerformedWidget } from '../Widgets/AssessmentsPerformedWidget/AssessmentsPerformedWidget';
import { TreatmentsDueIn30DaysWidget } from '../Widgets/TreatmentsDueIn30DaysWidget/TreatmentsDueIn30DaysWidget';
import { FireAreaSpansWidget } from '../Widgets/FireAreaSpansWidget/FireAreaSpansWidget';
import { IDashboardFilters } from '../../../../models/IDashboardFilters';
import { TreatmentsOverdueWidget } from '../Widgets/TreatmentsOverdueWidget/TreatmentsOverdueWidget';

interface IProps {
    cycleId: string;
    filters: IDashboardFilters;
    chartBackgroundColor:string;
}

export function NetworkHealthContainer(props: IProps) {
    const lastFiltersRef = React.useRef<IDashboardFilters | null>(null);

    const defaultsetSpanPerformanceValues: Record<string, number> = {
        "spans_with_atleast_one_assessment": 0,
        "total_spans": 0,
        "complete_action_spans": 0,
        "no_action_spans": 0,
        "incomplete_action_spans": 0,
        "treatments_due_in_30days": 0,
        "treatments_overdue": 0,         
        "hbra_treatments_overdue": 0,
        "lbra_treatments_overdue": 0 ,
        "hbra_spans": 0,
        "lbra_spans": 0,
        "hbra_and_lbra_spans": 0,
        "hbra_spans_complete": 0,
        "hbra_spans_no_action": 0,
        "hbra_spans_incomplete": 0,
        "lbra_spans_complete": 0,
        "lbra_spans_no_action": 0,
        "lbra_spans_incomplete": 0,
        "total_hbra_spans": 0,
        "total_lbra_spans": 0,
    }
    const [spanPerformanceValues, setSpanPerformanceValues] = useState(defaultsetSpanPerformanceValues);

    async function getSpanPerformanceValues() {
        try {
            var response = await postWithAuthorisationHeader(settings.CMPLY_API_URL + "dashboard/spanPerformanceValues/", props.filters);
            setSpanPerformanceValues(response.data);
        }
        catch (e) {
            console.log('Failed to get span performance values:' + e);
        }
    }

    useEffect(() => {
        if (props.filters.cycleId != '0' && props.filters != lastFiltersRef.current) {
            lastFiltersRef.current = props.filters;
            getSpanPerformanceValues();
        }

    }, [props.filters]);

    return <Card sx={{ height: "100%", width: "100%", bgcolor: '#161616', backgroundImage: 'none' }}>
        <Grid container direction="column" >
            <Grid item >
                <Box sx={{ borderRadius: '5px', marginBottom: 1 }}>
                    <AssessmentsPerformedWidget spanPerformanceValues={spanPerformanceValues} />
                </Box>
   
            </Grid>
            <Grid item>
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box mr={3} sx={{ width: "100%", borderRadius: '5px' }}>
                            <TreatmentsDueIn30DaysWidget count={spanPerformanceValues["treatments_due_in_30days"]} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box mr={3} sx={{ width: "100%", borderRadius: '5px' }}>
                            <TreatmentsOverdueWidget count={spanPerformanceValues["treatments_overdue"]} countHBRA={spanPerformanceValues["hbra_treatments_overdue"]} countLBRA={spanPerformanceValues["lbra_treatments_overdue"]} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item >
                <Box mt={3}>
                    <FireAreaSpansWidget spanPerformanceValues={spanPerformanceValues} />
                </Box>
            </Grid>
        </Grid>
    </Card>
}