import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from './ProgramNameFilter.module.css';
import TextField from '@mui/material/TextField';
import { fetchWithAuthorisationHeader } from '../../../../../services/AuthenticationService';
import settings from '../../../../../config';


interface IProgramNameFilterProps {
    onFilterChanged: Function;
    clearFilters: number;
    cycleId: string;
}

interface IProgramName {
    id: number;
    name: string;
    isSelected: boolean;
}

const labelStyle = {
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7F4A8'
    }
};

export function ProgramNameFilter(props: IProgramNameFilterProps) {
    const initialProgramNames: IProgramName[] = [];
    const [programNames, setProgramNames] = useState(initialProgramNames);
    const [filteredProgramNames, setFilteredProgramNames] = useState(initialProgramNames);
    const [lastClearFilters, setLastClearFilters] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const searchFilterRef = useRef<HTMLInputElement>(null);

    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {
        if (props.clearFilters != lastClearFilters) {
            programNames.forEach(s => s.isSelected = false);
            setProgramNames(programNames.slice());
            setSelectAll(false);
            setLastClearFilters(props.clearFilters);

            //reset the search field textbox
            if (searchFilterRef.current) {
                searchFilterRef.current.value = "";
            }
            setFilteredProgramNames(programNames);
        }
    }, [props.clearFilters, lastClearFilters, programNames]);

    //Handle when cycle has been changed
    useEffect(() => {
        async function getProgramNames() {
            try {
                let programNameList: IProgramName[] =[];
                programNames.forEach(s => s.isSelected = false);
                let selectedProgramNames = programNames.filter(s => s.isSelected && s.name != 'Select All').map(s => s.id);
                props.onFilterChanged(selectedProgramNames);
                if (props.cycleId != '0') {
                    var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + "program/cycle/" + props.cycleId);
                    var programsResponse = response.data;

                    programsResponse.forEach(((program: { name: any; id: any; }) => {
                        var newProgramName = { id: program.id, name: program.name, isSelected: false };
                        programNameList.push(newProgramName);
                    }));

                    setProgramNames(programNameList);
                    setFilteredProgramNames(programNameList);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        getProgramNames();
    }, [props.cycleId]);

    function onSelectAll(selectAll: boolean) {
        if (!selectAll) {
            filteredProgramNames.forEach(s => s.isSelected = true);
        } else {
            filteredProgramNames.forEach(s => s.isSelected = false);
        }

        setProgramNames(programNames.slice());
        let selectedProgramNames = programNames.filter(s => s.isSelected).map(s => s.id);
        props.onFilterChanged(selectedProgramNames);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(programName: IProgramName) {
        programName.isSelected = !programName.isSelected;
        setProgramNames(programNames.slice());
        let selectedProgramNames = programNames.filter(s => s.isSelected).map(s => s.id);
        props.onFilterChanged(selectedProgramNames);
        syncSelectAll();
    };

    function syncSelectAll() {
        let selectedProgramNames = programNames.filter(s => s.isSelected);
        if (!selectAll && selectedProgramNames.length == programNames.length) {
            setSelectAll(true);
        } else if (selectAll && selectedProgramNames.length < programNames.length) {
            setSelectAll(false);
        }
    }

    function onProgramNameChange(value: string) {
        if (value == "") {
            syncSelectAll();
            setFilteredProgramNames(programNames);
        } else {
            setFilteredProgramNames(programNames.filter(s => s.name.toLowerCase().includes(value.toLowerCase())));
        }
    };

    let ProgramNameCheckboxes: JSX.Element[] = [];
    filteredProgramNames.forEach(programName => {
        ProgramNameCheckboxes.push(
            <FormControlLabel
                key={programName.id}
                sx={{ '& .MuiFormControlLabel-label': programName.isSelected ? labelStyle.labelSelected : labelStyle.label }}
                control={<Checkbox color="secondary" checked={programName.isSelected} style={{ opacity: '0.5 !important' }} onChange={() => onCheckboxChanged(programName)} name={programName.name} size={'small'} />}
                label={programName.name}
            />
        );
    });

    let selectAllCheckbox = (
        <FormControlLabel
            key={"Select All"}
            sx={{ '& .MuiFormControlLabel-label': selectAll ? labelStyle.labelSelected : labelStyle.label }}
            control={<Checkbox color="secondary"  style={{ opacity: '0.5 !important' }} checked={selectAll}  onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
            label={"Select All"}
        />
    );

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <FormGroup>
                    <TextField inputRef={searchFilterRef}  size="small" style={{ color: '#F7F4A8', marginTop:8 }} id="ProgramName-filter" label="Search" onChange={(event) => onProgramNameChange(event.target.value)} />
                    {selectAllCheckbox}
                    {ProgramNameCheckboxes}
                </FormGroup>
            </div>
        </div>
    );
}
