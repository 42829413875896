import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { IMapBaseLayer } from '../../../models/IMapBaseLayer';
import useComponentVisible from '../../../hooks/useComponentVisible';

import styles from "./MapBaseLayerSelector.module.css";

interface IMapBaseLayerSelectorProps {
    baseMapLayers: IMapBaseLayer[];
    currentBaseLayer: string;
    onBaseMapLayerSelected: Function;
    showSelector: boolean;
    onHideSelector: Function;
}

export function MapBaseLayerSelector(props: IMapBaseLayerSelectorProps) {
    const [currentShowSelector, setShowSelector] = useState(props.showSelector);
    // Handle when the showSelector is requested   
    useEffect(() => {
        if (props.showSelector != currentShowSelector) {
            setShowSelector(props.showSelector);
        }

    }, [props.showSelector, currentShowSelector]);
    let selectedLayer = props.baseMapLayers.find(l=>l.baseLayerTitle === props.currentBaseLayer );
    if (selectedLayer == null)
        return null;

    let thumbnails: JSX.Element[] = [];

    let i=0;
    props.baseMapLayers.forEach(l => {
        let layerClass = styles.baseLayer + ' ';
        if ( i === 0) layerClass += styles.topBaseLayer;
        else if ( i === props.baseMapLayers.length -1) layerClass += styles.bottomBaseLayer;
        ++i;

        if ( l.baseLayerTitle === props.currentBaseLayer)
            layerClass += ' ' + styles.selectedBaseLayerInList;

        thumbnails.push(
            <div key={l.baseLayerId} className={layerClass} style={{ width: 56, height: 54}}>
                <img src={l.thumbnailUrl} 
                     title={l.baseLayerTitle}   
                     onClick={ () => { 
                         setShowSelector(false); 
                         props.onHideSelector();
                         props.onBaseMapLayerSelected(l.baseLayerTitle);
                     }} />
            </div>
        )
    });

    let thumbnailStyle = {
        display: currentShowSelector ? 'block' : 'none'
    }    


    return <div >
             <Box display="flex" flexDirection="column" style={{height:'100%'}}>
                 <Box>
                    <div className={styles.baseLayers} style={thumbnailStyle}> 
                       {thumbnails}
                    </div>
                 </Box>                     
            </Box>
          </div>
}
