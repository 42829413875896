import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';

import { IDashboardProgramManagementArea } from '../../../../../models/IDashboardProgramManagementArea';
import { IChartLegend } from '../../../../../models/IChartLegend';
import { LineChartWidget } from '../../../Shared/Widgets/LineChartWidget/LineChartWidget';
import { ICycle } from '../../../../../models/ICycle';
import moment from 'moment';

interface IProps {
    programManagemetAreas: IDashboardProgramManagementArea[];
    cycle: ICycle;
}

export function SpanPerformanceWidget(props: IProps) {

    const titleText = 'Span schedule - plan vs actual';

    const [chartData, setChartData] = useState<object | null>(null);
    const [chartLegends, setChartLegends] = useState<IChartLegend[]>([]);

    // Handle when the data has changed
    useEffect(() => {

        if (props.cycle == null || props.cycle.startDate == "") {
            return;
        }

        let cycleStartDate = new Date(props.cycle.startDate);

        let chartValuesTillToday = calculateChartDataBetweenDateRange(cycleStartDate, moment().toDate());
        let chartValues30DaysFromToday = calculateChartDataBetweenDateRange(cycleStartDate, moment().add(30, 'days').toDate());
        let chartValues60DaysFromToday = calculateChartDataBetweenDateRange(cycleStartDate, moment().add(60, 'days').toDate());
        let chartValues90DaysFromToday = calculateChartDataBetweenDateRange(cycleStartDate, moment().add(90, 'days').toDate());
       
        setChartData(getChartData(chartValuesTillToday, chartValues30DaysFromToday, chartValues60DaysFromToday, chartValues90DaysFromToday));

        setChartLegends([
            { text: 'Actual', colour: '#80b4ff', borderColour: '#80B4FF' },
            { text: 'Difference', colour: '#EE77A2', borderColour: '#EE77A2' },
            { text: 'Plan', colour: '#FFF798', borderColour: '#FFF798' },
        ]);

    }, [props.programManagemetAreas]);

    // Calculate values to display on chart
    function calculateChartDataBetweenDateRange(startDate: Date, endDate: Date) {
      
        let programManagementAreasTillDate = props.programManagemetAreas.filter(pma => {

            let dueDate = new Date(pma.scheduledDueDate);
            if (dueDate >= startDate && dueDate <= endDate) {
                return pma;
            };
        });

        let totalSpans = programManagementAreasTillDate.reduce((a, c) => a + c.totalAssetsInManagementArea, 0);

        let completedProgramAssetCount = 0;
        programManagementAreasTillDate.forEach(pma => {
            completedProgramAssetCount += pma.completedProgramAssetsCount;
        });

        let inCompleteProgramAssetCount = totalSpans - completedProgramAssetCount;

        return [totalSpans, completedProgramAssetCount, inCompleteProgramAssetCount];

    }
    
    // Returns a ChartJS data object
    function getChartData(chartValuesTillToday: number[], chartValues30DaysFromToday: number[], chartValues60DaysFromToday: number[], chartValues90DaysFromToday: number[]) {

        let cumulativeValues = [chartValuesTillToday[0], chartValues30DaysFromToday[0], chartValues60DaysFromToday[0], chartValues90DaysFromToday[0]];
        let actualValues = [chartValuesTillToday[1], chartValues30DaysFromToday[1], chartValues60DaysFromToday[1], chartValues90DaysFromToday[1]];
        let planValues = [chartValuesTillToday[2], chartValues30DaysFromToday[2], chartValues60DaysFromToday[2], chartValues90DaysFromToday[2]];

        const labels = ['Today', '+30 days', '+60 days', '+90 days'];
        let data = {
            labels,
            datasets: [
                {
                    label: 'Actual',
                    data: actualValues,
                    borderColor: 'rgb(128,180,255) ',
                    borderWidth: 1,
                    backgroundColor: 'rgba(128,180,255,0.25)',
                    fill: {
                        target: 'origin',
                        below:  'rgba(128,180,255,0.25)'
                    }
                },
                {
                    label: 'Difference',
                    data: planValues,
                    borderColor: 'rgb(238,119, 162)',
                    borderWidth: 1,
                    backgroundColor: 'rgba(238,119, 162,0.25)',
                    fill: {
                        target: 'origin',
                        below: 'rgba(238,119, 162,0.25)'
                    }
                },
                {
                    label: 'Plan',
                    data: cumulativeValues,
                    borderColor: '#FFF798',
                    borderWidth: 1,
                    backgroundColor: '#FFF798'
                },
            ],
        };
        return data;
    }

    return <Card sx={{ height: "100%", backgroundImage:'none' }}>
        {chartData &&
            <LineChartWidget
                titleText={titleText}
                chartLegends={chartLegends}
                chartData={chartData}
            ></LineChartWidget>
        }
    </Card>
}