import React from 'react';
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";

import { Doughnut } from "react-chartjs-2";

import { Chart, registerables } from 'chart.js';
import { IChartConfig } from '../../../models/IChartConfig';
Chart.register(...registerables);


interface IProps {
    chartConfig:IChartConfig;
    backgroundColor: string;
}

export function DoughnutChart(props: IProps){

    return <Card sx={{ height: "100%",bgcolor: props.backgroundColor, backgroundImage:'none'}}
                style={{boxShadow: '0px 1px 1px rgba(30, 30, 32, 0.14), 0px 2px 1px rgba(30, 30, 32, 0.12), 0px 1px 3px rgba(30, 30, 32, 0.2)'}} >
                <Box>                    
                    <Doughnut data={props.chartConfig.data} options={props.chartConfig.options} />   
                </Box>
            </Card>
}