import React, { useState, useCallback, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { postWithAuthorisationHeader } from "../../../services/AuthenticationService";
import settings from '../../../config';

import useComponentVisible from '../../../hooks/useComponentVisible';

//Models
import { ICycle } from '../../../models/ICycle';
import { IManagementAreaSearchFilters } from '../../../models/IManagementAreaSearchFilters';
import { IManagementAreaSearchCreateRequest } from '../../../models/IManagementAreaSearchCreateRequest';
import { IManagementAreaSearchCreateResponse } from '../../../models/IManagementAreaSearchCreateResponse';

import styles from './ManagementAreaSearch.module.css'
import { deepClone } from '../../Common/Common';

//Filters
import { RegionFilter } from '../Filters/RegionFilter/RegionFilter'; 
import { ManagementAreaNameFilter } from '../Filters/ManagementAreaFilter/ManagementAreaNameFilter/ManagementAreaNameFilter';
import { ManagementAreaTypeFilter } from '../Filters/ManagementAreaFilter/ManagementAreaTypeFilter/ManagementAreaTypeFilter';
import { ProgramNameFilter } from '../Filters/ProgramFilter/ProgramNameFilter/ProgramNameFilter';
import { ProgramTypeFilter } from '../Filters/ProgramFilter/ProgramTypeFilter/ProgramTypeFilter';
import { ProgramStatusFilter } from '../Filters/ProgramFilter/ProgramStatusFilter/ProgramStatusFilter';
import { DateFilter } from '../Filters/DateFilter/DateFilter';
import { AssetIdFilter } from '../Filters/AssetIdFilter/AssetIdFilter';
import { TaskTypeFilter } from '../Filters/TaskTypeFilter/TaskTypeFilter';
import { FireAreaFilter } from '../Filters/FireAreaFilter/FireAreaFilter';
import { ApprovalStatusFilter } from '../Filters/ApprovalStatusFilter/ApprovalStatusFilter';
import { AssetStatusFilter } from '../Filters/AssetStatusFilter/AssetStatusFilter';
import { NewSpanFilter } from '../Filters/NewSpanFilter/NewSpanFilter';
import { CompanyFilter } from '../Filters/CompanyFilter/CompanyFilter';
import { ManagementAreaStatusFilter } from '../Filters/ManagementAreaStatusFilter/ManagementAreaStatusFilter';

interface IManagementAreaSearchProps {
    cycle: ICycle;
    refreshSearchResultsFlag: number;
    onManagementAreaSearchCreated: Function;
    onUpdateIsLoadingCheck: Function;
    onFilterValuesChange : Function;
 };

interface ISearchFilter {
    name: string;
    hasChip: boolean,                     //whether to add a ui chip
    isDisplayed: boolean;                 //whether the filter component is currently shown
    displayX: number;                     //where we show the filter component x
    displayY: number;                     //where we show the filter component y  
    filterItemsSelectedCount: number;     //how many items are selected in the filter component
    clearFiltersFlag: number;             //used to signal to the filter component to clear itself
    listOrder: number;                    //used to track where the filter should appear in the filter list
    lastUpdateTime?: Date                 //the last time the filter was updated
};

/*used for displaying filter container windows at the correct coordinates*/

const FILTER_DISPLAY_OFFSET_Y: number = 32;
const FILTER_DISPLAY_MIN_X: number = 11; 

const DATE_BOX_DIMENSION: number = 630;
const FILTER_BOX_DIMENSION: number = 230;

const FILTER_NAME = Object.freeze({
    REGION: "Region",
    MA_NAME: "MA Name",
    MA_MODEL: "MA Model",
    PROGRAM_NAME: "Program Name",
    PROGRAM_TYPE: "Program Type",
    PROGRAM_STATUS: "Program Status",
    SCHEDULED_START_DATE: "Scheduled Start Date",
    SCHEDULED_DUE_DATE: "Scheduled Due Date",
    PRACTICAL_COMPLETION_DATE: "Practical Completion Date",
    COMPLIANCE_DATE: "Compliance Date",
    ASSET_ID: "Span ID",
    TASK_TYPE: "Task Type",
    FIRE_AREA: "Fire Area",
    APPROVAL_STATUS: "Approval Status",
    ASSET_STATUS: "Span Status",
    NEW_SPAN: "New Span",
    COMPANY: "Company",
    MA_STATUS: "MA Status"
});  

interface ISearchFilter {
    name: string;
    hasChip: boolean,                     //whether to add a ui chip
    isDisplayed: boolean;                 //whether the filter component is currently shown
    displayX: number;                     //where we show the filter component x
    displayY: number;                     //where we show the filter component y  
    filterItemsSelectedCount: number;     //how many items are selected in the filter component
    clearFiltersFlag: number;             //used to signal to the filter component to clear itself
    listOrder: number;                    //used to track where the filter should appear in the filter list
    lastUpdateTime?: Date                 //the last time the filter was updated
};

export function ManagementAreaSearch(props: IManagementAreaSearchProps) {

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    // When adding a new filter you need to add code to clearFilter()
    const allSearchFilters: ISearchFilter[] = [
        { listOrder: 0, name: FILTER_NAME.REGION, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 1, name: FILTER_NAME.MA_NAME, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 2, name: FILTER_NAME.MA_MODEL, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 3, name: FILTER_NAME.PROGRAM_NAME, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 4, name: FILTER_NAME.PROGRAM_TYPE, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 5, name: FILTER_NAME.PROGRAM_STATUS, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 6, name: FILTER_NAME.SCHEDULED_START_DATE, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 7, name: FILTER_NAME.SCHEDULED_DUE_DATE, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 8, name: FILTER_NAME.PRACTICAL_COMPLETION_DATE, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 9, name: FILTER_NAME.COMPLIANCE_DATE, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 10, name: FILTER_NAME.ASSET_ID, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 11, name: FILTER_NAME.TASK_TYPE, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 12, name: FILTER_NAME.FIRE_AREA, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 13, name: FILTER_NAME.APPROVAL_STATUS, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 14, name: FILTER_NAME.ASSET_STATUS, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 15, name: FILTER_NAME.NEW_SPAN, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 16, name: FILTER_NAME.COMPANY, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
        { listOrder: 17, name: FILTER_NAME.MA_STATUS, hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
    ];

    const filterOrderSort = (a: ISearchFilter, b: ISearchFilter) => {
        if (a.listOrder > b.listOrder) return 1;
        else if (a.listOrder < b.listOrder) return -1;
        return 0;
    }

    const defaultManagementAreaSearchFilters: IManagementAreaSearchFilters = {
        cycleId: props.cycle.id,
        regionIds: [],
        managementAreaNameIds: [],
        managementAreaTypes: [],
        programNameIds: [],
        programTypeIds: [],
        programStatuses: [],
        scheduledStartDateRange: [],
        scheduledDueDateRange: [],
        practicalCompletionDateRange: [],
        complianceDateRange: [],
        taskTypeIds: [],
        assetId: null,
        fireAreas: [],
        approvalStatuses: [],
        assetStatuses: [],
        newSpan: null,
        companyIds: [],
        managementAreaStatusIds: [],
    };
    const [managementAreaSearchFilters, setManagementAreaSearchFilters] = useState(defaultManagementAreaSearchFilters);
    const [searchFilters, setSearchFilters] = useState(allSearchFilters);
    const [showAllFilters, setShowAllFilters] = useState(false);
    const [showDateControl, setShowDateControl] = useState(true);
    const lastManagementAreaSearchFiltersRef = React.useRef(defaultManagementAreaSearchFilters);
    const scrollRef:any = useRef(null);
    const lastCycleIdRef = useRef('0');

    const resetScroll = () => {
        scrollRef.current.scrollLeft = 0;
    };

    const createSearch = useCallback(async () => {
        resetScroll();
        if (JSON.stringify(managementAreaSearchFilters) !== JSON.stringify(lastManagementAreaSearchFiltersRef.current)) {
            lastManagementAreaSearchFiltersRef.current = managementAreaSearchFilters;
            props.onFilterValuesChange(managementAreaSearchFilters);
        }

        props.onUpdateIsLoadingCheck(true);
        hideFilter();
        let zoomToExtent: boolean = false;

        if (props == null || props.cycle.id == '0')
            return;

        try {
            managementAreaSearchFilters.cycleId = props.cycle.id;

            const createSearchRequest: IManagementAreaSearchCreateRequest = {
              searchId: createGuid(),
              filters: managementAreaSearchFilters,
            };

            if (props.cycle.id !== lastCycleIdRef.current) {
                lastCycleIdRef.current = props.cycle.id;
                props.onFilterValuesChange(defaultManagementAreaSearchFilters);
                createSearchRequest.filters = defaultManagementAreaSearchFilters;
            }

            let response = await postWithAuthorisationHeader(settings.CMPLY_API_URL + 'search/create', createSearchRequest);
            if (response.status === 200) {
              let searchResponse = response.data as IManagementAreaSearchCreateResponse;
              zoomToExtent = true;
              props.onManagementAreaSearchCreated(searchResponse, zoomToExtent);
              props.onUpdateIsLoadingCheck(false);
            } 
            else {
              props.onManagementAreaSearchCreated(null, zoomToExtent);
              console.log('Failed to get create search. Code = ' + response.status);
            }
        }
        catch (e) {
            console.log(e);
        }

    }, [props.cycle.id, managementAreaSearchFilters]);


    let filterChips: JSX.Element[] = [];
    let debug: number[] = [];
    let searchFilterInstances: JSX.Element[] = [];

    useEffect(() => {
        createSearch();
        clearFilters();
    }, [props.cycle.id]);

    useEffect(() => {
        if (props.refreshSearchResultsFlag > 0) {
            createSearch();
        }
    }, [props.refreshSearchResultsFlag]);

    function createGuid() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    }
    
    // If a filter is shown then hide it
    function hideFilter() {

        if (searchFilters.filter(f => f.isDisplayed).length > 0) {
            // A filter was shown and is about to be hidden.

            let updatedFilters = searchFilters.filter(f => f.lastUpdateTime != null);
            if (updatedFilters != null) {
                //Sort filters into correct order

                let updatedFiltersWithValues = updatedFilters.filter(f => f.filterItemsSelectedCount > 0);

                searchFilters.forEach((f: ISearchFilter) => { f.listOrder += searchFilters.length; })

                if (updatedFiltersWithValues.length > 0) {
                    updatedFiltersWithValues.sort((a, b) => (a.lastUpdateTime != null && b.lastUpdateTime != null && a.lastUpdateTime < b.lastUpdateTime) ? 1 : -1);
                    let sortOrder = -1;
                    updatedFiltersWithValues.forEach(f => f.listOrder = ++sortOrder);
                }

                searchFilters.sort(filterOrderSort);
            }
        }

        searchFilters.forEach(f => f.isDisplayed = false);
        setSearchFilters(searchFilters.slice());
    }

    // Clear the selected items in all filters
    function clearFilters() {
        resetScroll();
        //clear search results
        props.onManagementAreaSearchCreated(null);

        // random number that will trigger an update 
        let clearFiltersFlag = new Date().getTime();
        let cache: ISearchFilter[] = deepClone(allSearchFilters);
        cache.forEach(f => {
            f.clearFiltersFlag = clearFiltersFlag;
        });

        setSearchFilters(cache);

        setManagementAreaSearchFilters({ ...defaultManagementAreaSearchFilters });
    }

    function setFilterState(filter: any, selectedFilterValues: any) {
        // update state 
        setManagementAreaSearchFilters({ ...managementAreaSearchFilters });

        filter.filterItemsSelectedCount = selectedFilterValues.length;

        // Set the last update time
        filter.lastUpdateTime = new Date();

        // update state 
        setSearchFilters(searchFilters.slice());
    }

    searchFilters.forEach((filter: ISearchFilter) => {
        debug.push(filter.listOrder);
        let userHasSelectedFilters = filter.filterItemsSelectedCount > 0;

        if (filter.hasChip) {
            // Create a chip

            filterChips.push(
                <span key={filter.name} >
                    <Badge
                        max={9}
                        badgeContent={filter.filterItemsSelectedCount}
                        color="secondary"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}>
                        <Chip
                            size="medium"
                            variant={userHasSelectedFilters ? "filled" : "outlined"}
                            icon={filter.isDisplayed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            label={filter.name}
                            onClick={(e: any) => toggleFilterDisplay(e, filter)}
                            onDelete={userHasSelectedFilters ? () => clearFilter(filter) : undefined}
                        />
                    </Badge>
                </span>
            );
        }

        let searchFilterInstance: JSX.Element | null = null;

        // Add an offset so the menus line up nicely

        let filterStyle = {
            right: filter.displayX,
            top: filter.displayY,
            display: filter.isDisplayed ? 'block' : 'none',
            overflowY: "auto" as "auto",
            maxHeight: "90%"
        };

        if (filter.name === FILTER_NAME.REGION) {
            searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
                <RegionFilter
                    clearFilters={filter.clearFiltersFlag}
                    cycleId={props.cycle.id}
                    onFilterChanged={(selectedRegionIds: number[]) => {
                        // Take the filter output and set it on the filter object that will be passed to the create search api
                        managementAreaSearchFilters.regionIds = selectedRegionIds;
                        setFilterState(filter, selectedRegionIds);
                    }} />
            </div>
        }
        if (filter.name === FILTER_NAME.MA_NAME) {
            searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
                <ManagementAreaNameFilter
                    clearFilters={filter.clearFiltersFlag}
                    cycleId={props.cycle.id}
                    onFilterChanged={(selectedManagementAreaIds: number[]) => {
                        // Take the filter output and set it on the filter object that will be passed to the create search api
                        managementAreaSearchFilters.managementAreaNameIds = selectedManagementAreaIds;
                        setFilterState(filter, selectedManagementAreaIds);
                    }} />
            </div>
        }
        if (filter.name === FILTER_NAME.MA_MODEL) {
            searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
                <ManagementAreaTypeFilter
                    clearFilters={filter.clearFiltersFlag}
                    cycleId={props.cycle.id}
                    onFilterChanged={(selectedManagementAreaTypes: string[]) => {
                        // Take the filter output and set it on the filter object that will be passed to the create search api
                        managementAreaSearchFilters.managementAreaTypes = selectedManagementAreaTypes;
                        setFilterState(filter, selectedManagementAreaTypes);
                    }} />
            </div>
        }
        if (filter.name === FILTER_NAME.PROGRAM_NAME) {
            searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
                <ProgramNameFilter
                    clearFilters={filter.clearFiltersFlag}
                    cycleId={props.cycle.id}
                    onFilterChanged={(selectedProgramNameIds: number[]) => {
                        // Take the filter output and set it on the filter object that will be passed to the create search api
                        managementAreaSearchFilters.programNameIds = selectedProgramNameIds;
                        setFilterState(filter, selectedProgramNameIds);
                    }} />
             </div>
        }
        if (filter.name === FILTER_NAME.PROGRAM_TYPE) {
            searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
                <ProgramTypeFilter
                    clearFilters={filter.clearFiltersFlag}
                    cycleId={props.cycle.id}
                    onFilterChanged={(selectedProgramTypeIds: number[]) => {
                        // Take the filter output and set it on the filter object that will be passed to the create search api
                        managementAreaSearchFilters.programTypeIds = selectedProgramTypeIds;
                        setFilterState(filter, selectedProgramTypeIds);
                    }} />
            </div>
        }
        if (filter.name === FILTER_NAME.PROGRAM_STATUS) {
            searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
                <ProgramStatusFilter
                    clearFilters={filter.clearFiltersFlag}
                    cycleId={props.cycle.id}
                    onFilterChanged={(selectedProgramStatuses: string[]) => {
                        // Take the filter output and set it on the filter object that will be passed to the create search api
                        managementAreaSearchFilters.programStatuses = selectedProgramStatuses;
                        setFilterState(filter, selectedProgramStatuses);
                    }} />
            </div>
        }

        if (filter.name === FILTER_NAME.SCHEDULED_START_DATE
            || filter.name === FILTER_NAME.SCHEDULED_DUE_DATE
            || filter.name === FILTER_NAME.PRACTICAL_COMPLETION_DATE
            || filter.name === FILTER_NAME.COMPLIANCE_DATE
        ) {
            searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
                {showDateControl &&
                    <DateFilter
                        clearFilters={filter.clearFiltersFlag}
                        filterName ={filter.name}
                        isDisplayed={filter.isDisplayed}
                        onFilterChanged={(selectedDateRange: string[]) => {

                            // Take the filter output and set it on the filter object that will be passed to the create search api
                            switch (filter.name) {
                                case FILTER_NAME.SCHEDULED_START_DATE: managementAreaSearchFilters.scheduledStartDateRange = selectedDateRange; break;
                                case FILTER_NAME.SCHEDULED_DUE_DATE: managementAreaSearchFilters.scheduledDueDateRange = selectedDateRange; break;
                                case FILTER_NAME.PRACTICAL_COMPLETION_DATE: managementAreaSearchFilters.practicalCompletionDateRange = selectedDateRange; break;
                                case FILTER_NAME.COMPLIANCE_DATE: managementAreaSearchFilters.complianceDateRange = selectedDateRange; break;
                            }
                            // update state 
                            setFilterState(filter, selectedDateRange);
                        }} />
                }
            </div>
        }
        if (filter.name === FILTER_NAME.ASSET_ID) {
            searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
                <AssetIdFilter
                    clearFilters={filter.clearFiltersFlag}
                    onFilterChanged={(assetId: string) => {
                        managementAreaSearchFilters.assetId = assetId.length === 0 ? null : assetId;
                        setFilterState(filter, managementAreaSearchFilters.assetId != null ? [managementAreaSearchFilters.assetId] : [] );
                    }} />
            </div>
        }

        if (filter.name === FILTER_NAME.TASK_TYPE) {
            searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
                <TaskTypeFilter
                    clearFilters={filter.clearFiltersFlag}
                    cycleId={props.cycle.id}
                    onFilterChanged={(selectedTaskTypeIds: number[]) => {
                        // Take the filter output and set it on the filter object that will be passed to the create search api
                        managementAreaSearchFilters.taskTypeIds = selectedTaskTypeIds;
                        setFilterState(filter, selectedTaskTypeIds);
                    }} />
            </div>
        }

        if (filter.name === FILTER_NAME.FIRE_AREA) {
            searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
                <FireAreaFilter
                    clearFilters={filter.clearFiltersFlag}
                    cycleId={props.cycle.id}
                    onFilterChanged={(selectedFireAreas: string[]) => {
                        managementAreaSearchFilters.fireAreas = selectedFireAreas;
                        setFilterState(filter, selectedFireAreas);
                    }} />
            </div>
        }

        if (filter.name === FILTER_NAME.APPROVAL_STATUS) {
            searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
                <ApprovalStatusFilter
                    clearFilters={filter.clearFiltersFlag}
                    cycleId={props.cycle.id}
                    onFilterChanged={(approvalStatuses: string[]) => {
                        // Take the filter output and set it on the filter object that will be passed to the create search api
                        managementAreaSearchFilters.approvalStatuses = approvalStatuses;
                        setFilterState(filter, approvalStatuses);
                    }} />
            </div>
        }

        if (filter.name === FILTER_NAME.ASSET_STATUS) {
            searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
                <AssetStatusFilter
                    clearFilters={filter.clearFiltersFlag}
                    cycleId={props.cycle.id}
                    onFilterChanged={(selectedStatuses: string[]) => {
                        // Take the filter output and set it on the filter object that will be passed to the create search api
                        managementAreaSearchFilters.assetStatuses = selectedStatuses;
                        setFilterState(filter, selectedStatuses);
                    }} />
            </div>
        }

        if (filter.name === FILTER_NAME.NEW_SPAN) {
            searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
                <NewSpanFilter
                    clearFilters={filter.clearFiltersFlag}
                    cycleId={props.cycle.id}
                    onFilterChanged={(selectedNewSpans: any[]) => {
                        managementAreaSearchFilters.newSpan = selectedNewSpans.length === 1;
                        setFilterState(filter, selectedNewSpans );
                    }} />
            </div>
        }

        if (filter.name === FILTER_NAME.COMPANY) {
            searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
                <CompanyFilter
                    cycleId={props.cycle.id}
                    clearFilters={filter.clearFiltersFlag}
                    onFilterChanged={(selectedCompanyIds: any[]) => {
                        managementAreaSearchFilters.companyIds = selectedCompanyIds;
                        setFilterState(filter, selectedCompanyIds);
                    }} />
            </div>
        }

        if (filter.name === FILTER_NAME.MA_STATUS) {
            searchFilterInstance = (
                <div key={filter.name} className={styles.filterContainer} style={filterStyle}>
                    <ManagementAreaStatusFilter
                        clearFilters={filter.clearFiltersFlag}
                        cycleId={props.cycle.id}
                        onFilterChanged={(managementAreaStatusIds: number[]) => {
                            // Take the filter output and set it on the filter object that will be passed to the create search api
                            managementAreaSearchFilters.managementAreaStatusIds = managementAreaStatusIds;
                            setFilterState(filter, managementAreaStatusIds);
                        }}
                    />
                </div>
            );
        }

        // Add new filters here
        if (searchFilterInstance != null)
            searchFilterInstances.push(searchFilterInstance);
        searchFilters.sort(filterOrderSort);

        if (searchFilters.find(f => f.isDisplayed) != null && !isComponentVisible) {
            hideFilter();
        }
    });
    // Show or hide a filter
    function toggleFilterDisplay(clickEvent: any, filter: ISearchFilter) {

        let newState = !filter.isDisplayed;

        searchFilters.forEach(f => f.isDisplayed = false);

        filter.isDisplayed = newState;
        //handle for going offscreen
        let rect: any = clickEvent.currentTarget.getBoundingClientRect();

        if (filter.name === FILTER_NAME.SCHEDULED_START_DATE
            || filter.name === FILTER_NAME.SCHEDULED_DUE_DATE
            || filter.name === FILTER_NAME.PRACTICAL_COMPLETION_DATE
            || filter.name === FILTER_NAME.COMPLIANCE_DATE
        ) {

            filter.displayX = windowSize.current[0] - (rect.x + DATE_BOX_DIMENSION) > FILTER_DISPLAY_MIN_X
                ? windowSize.current[0] - (rect.x + DATE_BOX_DIMENSION) : FILTER_DISPLAY_MIN_X;
        }
        else {
            filter.displayX = windowSize.current[0] - (rect.x + FILTER_BOX_DIMENSION) > FILTER_DISPLAY_MIN_X
                ? windowSize.current[0] - (rect.x + FILTER_BOX_DIMENSION) : FILTER_DISPLAY_MIN_X;
        }
        filter.displayY = rect.y - FILTER_DISPLAY_OFFSET_Y;

        setIsComponentVisible(newState);

        setSearchFilters(searchFilters.slice());
    }

    function clearFilter(filter: ISearchFilter) {

        // Set the sort order so this filter is the first filter with no items 
        for (let searchFilter of searchFilters) {
            if (searchFilter.name != filter.name && searchFilter.filterItemsSelectedCount == 0) {
                filter.listOrder = searchFilter.listOrder - 1;
                break;
            }
        }
        searchFilters.sort(filterOrderSort);

        filter.filterItemsSelectedCount = 0;
        filter.lastUpdateTime = undefined;

        // random number that will trigger an update 
        let clearFiltersFlag = new Date().getTime();
        filter.clearFiltersFlag = clearFiltersFlag;

        setSearchFilters(searchFilters.slice());

        //Clear the value on the filter.  New filters will need to be added here

        switch (filter.name) {
            case FILTER_NAME.REGION: managementAreaSearchFilters.regionIds = defaultManagementAreaSearchFilters.regionIds; break;
            case FILTER_NAME.MA_NAME: managementAreaSearchFilters.managementAreaNameIds = defaultManagementAreaSearchFilters.managementAreaNameIds; break;
            case FILTER_NAME.MA_MODEL: managementAreaSearchFilters.managementAreaTypes = defaultManagementAreaSearchFilters.managementAreaTypes; break;
            case FILTER_NAME.PROGRAM_NAME: managementAreaSearchFilters.programNameIds = defaultManagementAreaSearchFilters.programNameIds; break;
            case FILTER_NAME.PROGRAM_TYPE: managementAreaSearchFilters.programTypeIds = defaultManagementAreaSearchFilters.programTypeIds; break;
            case FILTER_NAME.PROGRAM_STATUS: managementAreaSearchFilters.programStatuses = defaultManagementAreaSearchFilters.programStatuses; break;
            case FILTER_NAME.SCHEDULED_START_DATE: managementAreaSearchFilters.scheduledStartDateRange = defaultManagementAreaSearchFilters.scheduledStartDateRange; break;
            case FILTER_NAME.SCHEDULED_DUE_DATE: managementAreaSearchFilters.scheduledDueDateRange = defaultManagementAreaSearchFilters.scheduledDueDateRange; break;
            case FILTER_NAME.PRACTICAL_COMPLETION_DATE: managementAreaSearchFilters.practicalCompletionDateRange = defaultManagementAreaSearchFilters.practicalCompletionDateRange; break;
            case FILTER_NAME.COMPLIANCE_DATE: managementAreaSearchFilters.complianceDateRange = defaultManagementAreaSearchFilters.complianceDateRange; break;
            case FILTER_NAME.ASSET_ID: managementAreaSearchFilters.assetId = defaultManagementAreaSearchFilters.assetId; break;
            case FILTER_NAME.TASK_TYPE: managementAreaSearchFilters.taskTypeIds = defaultManagementAreaSearchFilters.taskTypeIds; break;
            case FILTER_NAME.FIRE_AREA: managementAreaSearchFilters.fireAreas = defaultManagementAreaSearchFilters.fireAreas; break;
            case FILTER_NAME.APPROVAL_STATUS: managementAreaSearchFilters.approvalStatuses = defaultManagementAreaSearchFilters.approvalStatuses; break;
            case FILTER_NAME.ASSET_STATUS: managementAreaSearchFilters.assetStatuses = defaultManagementAreaSearchFilters.assetStatuses; break;
            case FILTER_NAME.NEW_SPAN: managementAreaSearchFilters.newSpan = defaultManagementAreaSearchFilters.newSpan; break;
            case FILTER_NAME.COMPANY: managementAreaSearchFilters.companyIds = defaultManagementAreaSearchFilters.companyIds; break;
            case FILTER_NAME.MA_STATUS: managementAreaSearchFilters.managementAreaStatusIds = defaultManagementAreaSearchFilters.managementAreaStatusIds; break;
        }
        
        setManagementAreaSearchFilters({ ...managementAreaSearchFilters });
    }

    return (<div className={styles.managementAreaSearchContainer}>
        <div className={styles.managementAreaSearchContent} >
            <Typography className={styles.text} variant="h5" gutterBottom>
                    {props.cycle.name}
            </Typography>
            <Box display="flex" flexDirection="column">
                <Box display="flex" justifyContent="flex-start" >
                    <Box display="flex"
                        ref={scrollRef}
                        justifyContent="flex-start"
                        sx={{ flexWrap: showAllFilters ? "Wrap" : "noWrap" }}
                        style={{ height: showAllFilters ? 170 :70, overflowX: 'auto' as 'auto', paddingTop: 12, paddingLeft: 13 }}
                        onWheel={(e: any) => { if (!isComponentVisible) e.currentTarget.scrollLeft -= e.nativeEvent.wheelDeltaY; }}
                        className={styles.chip}>
                        {filterChips}
                        <div ref={ref} onClick={() => setIsComponentVisible(true)}>
                            {searchFilterInstances}
                        </div>
                    </Box>
                </Box>
            </Box>
            
            <Grid container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ flexWrap: "nowrap" }}
            >
                <Grid item>
                    <Box>
                        {!showAllFilters &&
                            <IconButton
                                onClick={(e: any) => setShowAllFilters(true)}
                                style={{ marginLeft: 8, height: 17, width: 17 }}>
                                <ExpandMoreIcon />
                            </IconButton>
                        }
                        {showAllFilters &&
                            <IconButton
                                onClick={(e: any) => setShowAllFilters(false)}
                                style={{ marginLeft: 8, height: 17, width: 17 }}>
                                <ExpandLessIcon />
                            </IconButton>
                        }
                    </Box>
                </Grid>

                <Grid item>
                    <Box justifyContent="flex-end" style={{ marginTop: 8 }}>
                        <Button variant="text" color="secondary" endIcon={<CancelIcon />} style={{ marginRight: 20 }}  onClick={clearFilters}>
                            CLEAR ALL
                        </Button>
                        <Button size="small" color="secondary" variant="contained" style={{ marginRight: 8}} onClick={() => createSearch()} >
                            APPLY
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </div>
        </div>
    );
}