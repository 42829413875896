import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { BarChart } from '../../../../Charts/BarChart/BarChart';
import settings from '../../../../../config';
import { fetchWithAuthorisationHeader } from '../../../../../services/AuthenticationService';

import getChartConfig from "../../../SharedChartConfig/BarChartConfig";
import { IChartConfig } from '../../../../../models/IChartConfig';
import { IDashboardProgramManagementArea } from '../../../../../models/IDashboardProgramManagementArea';
import { SimpleDisplayCountWidget } from '../../../Shared/Widgets/SimpleDisplayCountWidget/SimpleDisplayCountWidget';

interface IProps {
    programManagementAreas: IDashboardProgramManagementArea[];
}

export function InProgressManagementAreasWidget(props: IProps) {

    const topText = 'MAs in progress';
    const height = 120;
    const width = 164;
    const color = "#222222";
    const [bottomText, setBottomText] = useState('')
    const [count, setCount] = useState(0);

  
    useEffect(() => {

        let pmasWithNoPracticalDate = props.programManagementAreas.filter(x => x.practicalCompletionDate == null || x.practicalCompletionDate === '');
        let inProgressCount = pmasWithNoPracticalDate.filter(pma => pma.taskCount > 0).length;
        let notInProgressCount = pmasWithNoPracticalDate.filter(pma => pma.taskCount === 0).length;
        setCount(inProgressCount);
        setBottomText(`${notInProgressCount} MAs not started`);
 
 }, [props.programManagementAreas]);


 return <Card sx={{ height: "100%", backgroundImage:'none' }}>
            <SimpleDisplayCountWidget
                height={height}
                width={width}
                color={color}
                topText={topText}
                count={count}
                bottomText={bottomText}/>
        </Card>
}