import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { IAssetTaskQualityValues } from '../../../../../models/IAssetTaskQualityValues';
import { SimpleDisplayCountWidget } from '../../../Shared/Widgets/SimpleDisplayCountWidget/SimpleDisplayCountWidget';

interface IProps {
    assetTaskQualityCounts: IAssetTaskQualityValues | null;
}

export function CompleteProgramMasWithAuditWidget(props: IProps) {

    const topText = 'Prac. complete MAs with audits';
    const height = 145;
    const width = 184;
    const color = "#222222";
    const [bottomText, setBottomText] = useState('')
    const [count, setCount] = useState(0);


    useEffect(() => {
        let totalCount = 0
        if (props.assetTaskQualityCounts != null) {
            setCount(props.assetTaskQualityCounts.totalCompleteProgramMAWithAudits);
            totalCount = props.assetTaskQualityCounts.totalCompleteProgramMAs;
        }
        setBottomText("Across " + totalCount + " prac. complete MA" + (totalCount > 1 ? 's' : ''));
    }, [props.assetTaskQualityCounts]);

    return <Card sx={{ height: "100%", backgroundImage: 'none' }}>
        <SimpleDisplayCountWidget
            height={height}
            width={width}
            color={color}
            topText={topText}
            count={count}
            bottomText={bottomText} />
    </Card>
}