
// Returns data and options config for use with react-chartjs-2 / chart.js
// See https://www.chartjs.org/docs/3.9.1/configuration/

function getConfig() {
  return {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
            enabled: true,
            callbacks: {
            }
        }
      },
      scales: {
        y: {
          stacked: true,
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            color: "rgba(255, 255, 255, .2)",
          },
        },
        x: {
            stacked: true,
            ticks: {
              display: true,
              color: "#E2E2E2",
              font: {
                size: 10,
                lineHeight: 1,
              },
            },
        },
      },
    },
  };
}

export default getConfig;
