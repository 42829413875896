import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from './FireAreaFilter.module.css';

interface IProps {
    onFilterChanged: Function;
    clearFilters: number;
    cycleId: string;
}

interface IFireAreaFilter {
    name: string;
    isSelected: boolean;
}

const labelStyle = {
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7F4A8'
    }
};

export function FireAreaFilter(props: IProps) {

    const allFireAreas: IFireAreaFilter[] = [
        { name: 'HBRA', isSelected: false },
        { name: 'LBRA', isSelected: false },
    ];

    const [fireAreas, setFireAreas] = useState(allFireAreas);
    const [lastClearFilters, setLastClearFilters] = useState(0);
    const [selectAll, setSelectAll] = useState(false);

    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {
        if (props.clearFilters != lastClearFilters) {
              fireAreas.forEach((s) => (s.isSelected = false));
              setFireAreas(fireAreas.slice());
              setSelectAll(false);
              setLastClearFilters(props.clearFilters);
            }
      }, [props.clearFilters, lastClearFilters]);

    function onSelectAll(selectAll: boolean) {
        if (!selectAll) {
          fireAreas.forEach((c) => (c.isSelected = true));
        } else {
          fireAreas.forEach((c) => (c.isSelected = false));
        }
        setFireAreas(fireAreas.slice());
        let selectedAssetLinks = fireAreas
          .filter((c) => c.isSelected)
          .map((c) => c.name);
        props.onFilterChanged(selectedAssetLinks);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(fireArea: IFireAreaFilter) {
        fireArea.isSelected = !fireArea.isSelected;
        setFireAreas(fireAreas.slice());
        let selectedFireAreas = fireAreas.filter(s => s.isSelected).map(s => s.name);
        props.onFilterChanged(selectedFireAreas);
        syncSelectAll();
    };

    function syncSelectAll() {
        let selectedFireAreas = fireAreas.filter(s => s.isSelected);
        if (!selectAll && selectedFireAreas.length == fireAreas.length) {
            setSelectAll(true);
        } else if (selectAll && selectedFireAreas.length < fireAreas.length) {
            setSelectAll(false);
        }
    }

    let checkboxes = fireAreas.map(fireArea => {
        return(
            <FormControlLabel
                key={fireArea.name}
                sx={{ '& .MuiFormControlLabel-label': fireArea.isSelected ? labelStyle.labelSelected : labelStyle.label }}
                control={<Checkbox color = "secondary" checked={fireArea.isSelected} style={{ opacity: '0.5 !important' }}  onChange={() => onCheckboxChanged(fireArea)} name={fireArea.name} size={'small'} />}
                label={fireArea.name}
            />
        );
    });

    let selectAllCheckbox = (
        <FormControlLabel
            key={"Select All"}
            sx={{ '& .MuiFormControlLabel-label': selectAll ? labelStyle.labelSelected : labelStyle.label }}
            control={<Checkbox color="secondary"  style={{ opacity: '0.5 !important' }} checked={selectAll}  onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
            label={"Select All"}
        />
    );

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <FormGroup>
                    {selectAllCheckbox}
                    {checkboxes}
                </FormGroup>
            </div>
        </div>
    );
}
