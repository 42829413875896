import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { IDashboardProgramManagementArea } from '../../../../../models/IDashboardProgramManagementArea';
import { ManagementAreasScheduleWidget } from '../ManagementAreasScheduleWidget/ManagementAreasScheduleWidget';
import { IChartLegend } from '../../../../../models/IChartLegend';


import styles from './ManagementAreasSchedulesWidget.module.css'

interface IProps {
    programManagementAreas: IDashboardProgramManagementArea[];
}

export function ManagementAreasSchedulesWidget(props: IProps) {

     const chartLegends: IChartLegend[] = [
            {text: 'Complete', colour: '#94E1E3', borderColour: '#94E1E3' },
            {text: 'Incomplete',colour: '#8D75CC', borderColour: '#8D75CC'},            
            ];

    let chartLegendItems: JSX.Element[] = []; 
    chartLegends.forEach( (chartLegend: IChartLegend ) => {

        chartLegendItems.push( 
            <Grid mt={2} item >
                <Box style={{color:'white'}}>
                    <Typography variant="h6" style={{fontSize:12}}>                    
                        <span className={styles.dot} style={{height: 10, width: 10, backgroundColor: chartLegend.colour, marginRight:9, borderColor: chartLegend.borderColour }}></span>
                        {chartLegend.text}
                    </Typography>
                </Box>
            </Grid>
            );
    })    

    return <Card sx={{ height: "100%", backgroundImage:'none', bgcolor:'#222222'}} style={{padding:10}} >
                
                <Grid 
                    container
                    direction="row"
                    alignItems="left"  
                    spacing={1}
                >
                    <Grid item >
                        <ManagementAreasScheduleWidget 
                            title={'Outstanding MAs due next 30 days'}
                            dateRangeStartDay={0}
                            dateRangeEndDay={30}
                            programManagementAreas={props.programManagementAreas}></ManagementAreasScheduleWidget>
                    </Grid>
                    <Grid item >
                        <ManagementAreasScheduleWidget 
                            title={'Outstanding MAs due next 31-60 days'}
                            dateRangeStartDay={31}
                            dateRangeEndDay={60}
                            programManagementAreas={props.programManagementAreas}></ManagementAreasScheduleWidget>
                    </Grid>
                    <Grid item >
                        <ManagementAreasScheduleWidget 
                            title={'MAs overdue'}
                            dateRangeStartDay={0}
                            dateRangeEndDay={0}
                            programManagementAreas={props.programManagementAreas}></ManagementAreasScheduleWidget>
                    </Grid>
                </Grid>

                <Grid 
                    container
                    direction="row"
                    alignItems="left"              
                    spacing={1}
                >
                    {chartLegendItems}
                </Grid>
           </Card>
}