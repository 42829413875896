import React, { useState, useEffect } from 'react';

import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

import { ISnackbarConfig } from '../../models/ISnackbarConfig';
interface ISnackbarProps {
  config: ISnackbarConfig | null;
}


type SnackbarVerticalPosition = "bottom" | "top";

export function WarningSnackbar(props: ISnackbarProps) {
    const [open, setOpen] = React.useState(false);
   
    useEffect(() => {
        if (props.config != null) {
            setOpen(true);
        }
    }, [props.config]);

    if (props.config == null)
        return null;


    return <Snackbar
        open={open}
        autoHideDuration={props.config.autoHideDuration != null ? props.config.autoHideDuration : 5000}
        anchorOrigin={{ vertical: props.config.verticalAnchorOrigin as SnackbarVerticalPosition, horizontal: 'center' }}
        onClose={() => setOpen(false)}
        sx={{
            marginTop: '50px', backgroundColor: '#161616',
            textAlign: 'center' }}
    >
        <SnackbarContent
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#161616',                
                color: '#EE924F'
            }}
            message={props.config.message}
            action={props.config.action != null ? props.config.action : undefined}
        />
    </Snackbar>
}
