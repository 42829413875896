import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';

import { IDashboardProgramManagementArea } from '../../../../../models/IDashboardProgramManagementArea';
import { HalfDoughnutWidget } from '../../../Shared/Widgets/HalfDonutWidget/HalfDoughnutWidget';
import { IChartLegend } from '../../../../../models/IChartLegend';


interface IProps {
    programManagementAreas: IDashboardProgramManagementArea[];
}

export function CompletedManagementAreasWidget(props: IProps) {

    const titleText = 'MAs practically complete';
    
    const [totalCount,setTotalCount] = useState(0);
    const [completedCount,setCompletedCount] = useState(0);
    const [centreBigText,setCentreBigText] = useState('');
    const [topText,setTopText] = useState('');
    const [chartData,setChartData] = useState<object|null>(null);
    const [chartLegends,setChartLegends] = useState<IChartLegend[]>([]);

    // Handle when the data has changed
    useEffect(() => {

        let currentDate = removeTime(new Date());

        let theCompletedCount = props.programManagementAreas.filter(pma => pma.practicalCompletionDate !=null ).length;
        let theTotalCount = props.programManagementAreas.length;
        let targetCount = 0;
        
        props.programManagementAreas.forEach(pma => {
            if (pma.scheduledDueDate != null && currentDate > removeTime(new Date(pma.scheduledDueDate)) )
            {
                ++targetCount;
            }
        });

        setCompletedCount( theCompletedCount );
        setTopText('Target ' + targetCount.toString());
        setTotalCount(theTotalCount);
        setCentreBigText(theCompletedCount.toString());

        setChartData( getChartData(theCompletedCount, targetCount, theTotalCount) );

        setChartLegends( [
            {text: 'Completed', colour: '#4ACBC6', borderColour: '#4ACBC6' },
            { text: 'Target', colour: '#7E4B74', borderColour: '#F4A6F5'},            
            {text: 'Remaining', colour: '#434343', borderColour: '#434343'}, 
            ]);

    }, [props.programManagementAreas]);

    function removeTime(date: Date) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
    }

    function tooltipLabelCallback(tooltipItem:any ){
        // This is called by ChartJs onMouseOver the chart
        return tooltipItem.label + ': ' + (tooltipItem.label === 'Completed' ? completedCount.toString() : tooltipItem.formattedValue);
    };

    function tooltipFooterCallback(tooltipItems:any ){
        // This is called by ChartJs onMouseOver the chart

        let tooltipItem = tooltipItems[0];

        let itemValue = tooltipItem.dataset.data[tooltipItem.dataIndex];

        if ( tooltipItem.label === 'Completed' )
        {
            // Need to override as we have split completed to have the target slice
            itemValue = completedCount;
        }

        let percent = Math.round( (itemValue/totalCount) * 100 );

        return percent + ' Percent';
    }

    // Returns a ChartJS data object
    function getChartData(completedCount:number, targetCount:number, totalCount:number){

        let data: any = {};
        
        let dataset: any = {};
        data.datasets = [dataset];
        dataset.borderWidth = 2;

        if ( completedCount < targetCount ){ 
            data.labels = ['Completed','Target remaining','Remaining' ];
            dataset.data = [ completedCount, ( targetCount - completedCount) , (totalCount - targetCount)];
          
        }
        else {
            data.labels = ['Target completed','Completed','Remaining' ];
            dataset.data = [targetCount, (completedCount - targetCount), (totalCount - completedCount)];            
         
        }        
        dataset.borderColor = ['#4ACBC6','#F4A6F5','#434343'];
        dataset.backgroundColor = ['#4ACBC6', '#7E4B74', '#434343'];
        return data;
    }

    return <Card sx={{ width: "100%", height: "100%", backgroundImage: 'none' }}>
                { chartData &&
                    <HalfDoughnutWidget 
                        topText={topText}
                        centreBigText={centreBigText} 
                        titleText={titleText}
                        totalCount={totalCount} 
                        chartLegends={chartLegends}                        
                        chartData={chartData}
                        tooltipFooterCallback={tooltipFooterCallback}
                        tooltipLabelCallback={tooltipLabelCallback}
                    ></HalfDoughnutWidget>
                }
           </Card>
}