import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from './AssetStatusFilter.module.css';
import TextField from '@mui/material/TextField';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import settings from '../../../../config';


interface IAssetStatusFilterProps {
    onFilterChanged: Function;
    clearFilters: number;
    cycleId: string;
}

interface IAssetStatusFilter {
    status: string;
    color: string;
    isSelected: boolean;
}

const labelStyle = {
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7F4A8'
    }
};

export function AssetStatusFilter(props: IAssetStatusFilterProps) {
    const initialAssetStatuses: IAssetStatusFilter[] = [];
    const [assetStatuses, setAssetStatuses] = useState(initialAssetStatuses);
    const [filteredAssetStatuses, setFilteredAssetStatuses] = useState(initialAssetStatuses);
    const [lastClearFilters, setLastClearFilters] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const searchFilterRef = useRef<HTMLInputElement>(null);

    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {
        if (props.clearFilters != lastClearFilters) {
            assetStatuses.forEach(s => s.isSelected = false);
            setAssetStatuses(assetStatuses.slice());
            setSelectAll(false);
            setLastClearFilters(props.clearFilters);

            //reset the search field textbox
            if (searchFilterRef.current) {
                searchFilterRef.current.value = "";
            }
            setFilteredAssetStatuses(assetStatuses);
        }

    }, [props.clearFilters, lastClearFilters, assetStatuses]);

    //Handle when cycle has been changed
    useEffect(() => {
        async function getAssetStatuses() {
            try {
                let assetStatusList: IAssetStatusFilter[] = [];
                assetStatuses.forEach(s => s.isSelected = false);
                let selectedAssetStatuses = assetStatuses.filter(s => s.isSelected && s.status != 'Select All').map(s => s.status);
                props.onFilterChanged(selectedAssetStatuses);
                if (props.cycleId != '0') {
                    var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + "asset/statuses");

                    response.data.forEach((t: any) => {
                        // Null values need not be displayed for now. So filter them. 
                        if (t != null) {
                            var newAssetStatus = { status: t.status, color: t.statusColor, isSelected: false };
                            assetStatusList.push(newAssetStatus);
                        }
                    });

                    setAssetStatuses(assetStatusList);
                    setFilteredAssetStatuses(assetStatusList);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        getAssetStatuses();
    }, [props.cycleId]);

    function onSelectAll(selectAll: boolean) {
        if (!selectAll) {
            filteredAssetStatuses.forEach(s => s.isSelected = true);
        } else {
            filteredAssetStatuses.forEach(s => s.isSelected = false);
        }

        setAssetStatuses(assetStatuses.slice());
        let selectedAssetStatuses = assetStatuses.filter(s => s.isSelected).map(s => s.status);
        props.onFilterChanged(selectedAssetStatuses);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(AssetStatus: IAssetStatusFilter) {
        AssetStatus.isSelected = !AssetStatus.isSelected;
        setAssetStatuses(assetStatuses.slice());
        let selectedAssetStatuses = assetStatuses.filter(s => s.isSelected).map(s => s.status);
        props.onFilterChanged(selectedAssetStatuses);
        syncSelectAll();
    };

    function syncSelectAll() {
        let selectedAssetStatuses = assetStatuses.filter(s => s.isSelected);
        if (!selectAll && selectedAssetStatuses.length == assetStatuses.length) {
            setSelectAll(true);
        } else if (selectAll && selectedAssetStatuses.length < assetStatuses.length) {
            setSelectAll(false);
        }
    }

    function onAssetStatusChange(value: string) {
        if (value == "") {
            syncSelectAll();
            setFilteredAssetStatuses(assetStatuses);
        } else {
            setFilteredAssetStatuses(assetStatuses.filter(s => s.status.toLowerCase().includes(value.toLowerCase())));
        }
    };

    let assetStatusCheckboxes: JSX.Element[] = [];
    filteredAssetStatuses.forEach(assetStatus => {
        assetStatusCheckboxes.push(
            <FormControlLabel
                key={assetStatus.status}
                sx={{ '& .MuiFormControlLabel-label': assetStatus.isSelected ? labelStyle.labelSelected : labelStyle.label }}
                control={<Checkbox color="secondary" checked={assetStatus.isSelected} style={{ opacity: '0.5 !important' }} onChange={() => onCheckboxChanged(assetStatus)} name={assetStatus.status} size={'small'} />}
                label={
                    <>
                        <span
                            style={{
                                position: 'absolute',
                                transform: 'translateY(-50%)',
                                width: '8px',
                                height: '8px',
                                borderRadius: '50%',
                                backgroundColor: assetStatus.color,
                                marginTop:'10px'
                            }}
                        />
                        <span style={{marginLeft: '15px'}}>{assetStatus.status}</span>
                    </>
                }
            />
        );
    });

    let selectAllCheckbox = (
        <FormControlLabel
            key={"Select All"}
            sx={{ '& .MuiFormControlLabel-label': selectAll ? labelStyle.labelSelected : labelStyle.label }}
            control={<Checkbox color="secondary" style={{ opacity: '0.5 !important' }} checked={selectAll} onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
            label={"Select All"}
        />
    );

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <FormGroup>
                    <TextField inputRef={searchFilterRef} size="small" style={{ color: '#F7F4A8', marginTop: 8 }} id="AssetStatus-filter" label="Search" onChange={(event) => onAssetStatusChange(event.target.value)} />
                    {selectAllCheckbox}
                    {assetStatusCheckboxes}
                </FormGroup>
            </div>
        </div>
    );
}