import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { addDays } from 'date-fns';
import { IDashboardProgramManagementArea } from '../../../../../models/IDashboardProgramManagementArea';
import { BarChart } from '../../../../Charts/BarChart/BarChart';
import moment from 'moment';
import getConfig from './BarChartConfig'; 
import { IChartConfig } from '../../../../../models/IChartConfig';


interface IProps {
    title: string;
    programManagementAreas: IDashboardProgramManagementArea[];
    dateRangeStartDay:number;
    dateRangeEndDay:number;
}

export function ManagementAreasScheduleWidget(props: IProps) {
    const minChartWidth = 450;
    const [chartConfig, setChartConfig] = useState<IChartConfig | null>(null);
    const [chartTitle,setChartTitle] = useState('');
    const [chartWidth,setChartWidth] = useState( minChartWidth );

    // Handle when the data has changed
    useEffect(() => {

        let matchingProgramManagementAreas = props.programManagementAreas.filter(pma => pma.practicalCompletionDate == null && matchesDateRangeFilter(pma.scheduledDueDate) )
        matchingProgramManagementAreas.sort( (a:IDashboardProgramManagementArea,b:IDashboardProgramManagementArea) => a.scheduledDueDate.localeCompare(b.scheduledDueDate));

        // Calculate the chart width so that we can scroll and it displays nicely

        let pixelsPerBar = 27;
        let chartAxisAreaSize = 55;

        let theChartWidth =  chartAxisAreaSize + ( matchingProgramManagementAreas.length * pixelsPerBar );
        if ( theChartWidth < minChartWidth )
        {
            theChartWidth = minChartWidth;
        }

        setChartWidth( theChartWidth );

        let config:any = getConfig();
        let data = getChartData( matchingProgramManagementAreas);

        // Add text to tooltip
        config.options.plugins.tooltip.callbacks.afterBody = function (tooltipItems:any[]){
            
            let tooltipItem = tooltipItems[0];
            let tooltipData: any = tooltipItem.dataset.tooltipData[tooltipItem.dataIndex];

            let tooltipText = [
                'Scheduled Due Date ' + tooltipData.scheduledDueDate,
                tooltipData.completeProgramAssetsCount + ' Complete assets',
                tooltipData.incompleteProgramAssetsCount + ' Incomplete assets',
                ];

            return tooltipText;
        };

        setChartConfig( {options: config.options, data:data });

        setChartTitle( props.title + ' - ' + matchingProgramManagementAreas.length );

    }, [props.programManagementAreas]);

    function matchesDateRangeFilter(scheduledDueDate:string){

        if ( scheduledDueDate === null || scheduledDueDate.length === 0 )
            return false;

        let currentDate = removeTime(new Date());
        let dueDate = removeTime(new Date(scheduledDueDate));

        if ( props.dateRangeEndDay > 0 )
        {
            // Schedule due date is today or future
            let startDate = addDays(currentDate, props.dateRangeStartDay);
            let endDate = addDays(currentDate, props.dateRangeEndDay);
            return dueDate >= startDate && dueDate <= endDate;
        }
        else {
            // Schedule due date is in the past
            return dueDate < currentDate;
        }
    }

    function removeTime(date: Date) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
    }

    // Returns a ChartJS data object
    function getChartData(programManagementAreas: IDashboardProgramManagementArea[]){

        let data: any = {};
        
        let incompleteDataset: any = {};
        let completeDataset: any = {};

        data.datasets = [ incompleteDataset, completeDataset];
        data.labels = [];
        incompleteDataset.data = [];
        completeDataset.data = [];

        incompleteDataset.tooltipData = [];
        completeDataset.tooltipData = [];

        incompleteDataset.barThickness = 15;
        incompleteDataset.maxBarThickness = 15;

        completeDataset.barThickness = 15;
        completeDataset.maxBarThickness = 15;

        incompleteDataset.backgroundColor = '#8D75CC';
        completeDataset.backgroundColor = '#94E1E3';

        programManagementAreas.forEach( (pma:IDashboardProgramManagementArea ) => {
            data.labels.push(pma.managementAreaName );

            let incompleteProgramAssetsCount = pma.totalAssetsInManagementArea - pma.completedProgramAssetsCount;

            incompleteDataset.data.push(incompleteProgramAssetsCount);
            completeDataset.data.push(pma.completedProgramAssetsCount);

            let tooltipData = {
                completeProgramAssetsCount:pma.completedProgramAssetsCount,
                incompleteProgramAssetsCount:incompleteProgramAssetsCount,
                scheduledDueDate: moment.utc(new Date(pma.scheduledDueDate),"DD-MM-YYYY hh:mm:ss a").local().format('DD/MM/YY')
            }

            incompleteDataset.tooltipData.push( tooltipData );
            completeDataset.tooltipData.push( tooltipData);
        });

        return data;
    }

    return <Card sx={{ height: 210, width: 450, backgroundImage:'none', bgcolor:'#222222', boxShadow: 'none' }}>
                { chartConfig &&
                    <Box >
                        <Box pt={1} pb={1} px={1} style={{textAlign: 'left'}}>
                            <Typography variant="h6">
                                {chartTitle}
                            </Typography>                            
                        </Box>  
                        <Box style={{width: '100%', overflowX: 'auto', overflowY: 'hidden'}}>
                            <Box sx={{height: 'calc(100% - 45px)',width: chartWidth, borderRadius: '5px'}}>
                                <BarChart backgroundColor={'#222222'} chartConfig={chartConfig}/>
                            </Box>
                        </Box>
                    </Box>
                }
           </Card>
}