import React, { useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import styles from './AssetIdFilter.module.css';
import TextField from '@mui/material/TextField';

interface IAssetIdFilterProps {
  onFilterChanged: Function;
  clearFilters: number;
}

export function AssetIdFilter(props: IAssetIdFilterProps) {

  const [AssetIdString, setAssetIdString] = useState("");
  const [lastClearFilters, setLastClearFilters] = useState(0);

  // Handle when the filters have been cleared
  // A number is passed as a flag so we can tell when it has changed
  useEffect(() => {

    if (props.clearFilters != lastClearFilters) {
      setAssetIdString("");
      setLastClearFilters(props.clearFilters);
    }

  }, [props.clearFilters, lastClearFilters, AssetIdString]);
  
    function onAssetIdChange(value:string) {
      setAssetIdString(value);
      props.onFilterChanged(value.trim()); //Remove leading and trailing spaces
    };

  
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <FormGroup>
                    <TextField
                        style={{ color: '#F7B500', marginTop: 8, marginBottom: 8 }}
                        id="AssetId-filter"
                        label="Enter complete span ID"
                        onChange={(event) => onAssetIdChange(event.target.value)}
                        value={AssetIdString}
                        size="small"
                    />
                </FormGroup>
            </div>
        </div>
    );
  }