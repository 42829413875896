// Returns data and options config for use with react-chartjs-2 / chart.js
// See https://www.chartjs.org/docs/3.9.1/configuration/

function getConfigs() {
  return {
    type: 'doughnut',
    options: {
      aspectRatio: 3, // clips edges tight so no wasted space
      borderWidth: 0,
      cutout: '80%',
      circumference: 360,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          //enabled false means use our own externalTooltip handler
          enabled: false,
          callbacks: {
            //footer: function (tooltipItems){
            // Override this function for custom tooltips
            //},
          },
        },
      },
      responsive: true,
      rotation: 270,
      scales: {
        y: {
          beginAtZero: true,
          display: false,
          max: 1000,
        },
        x: {
          display: false,
        },
      },
    },
  };
}

export default getConfigs;
