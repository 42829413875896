import * as React from 'react';
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";

import { Pie } from "react-chartjs-2";

import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { IChartConfig } from '../../../models/IChartConfig';
Chart.register(...registerables);

interface IChartProps {
    chartConfig:IChartConfig;
    backgroundColor:string;
}

export function PieChart(props: IChartProps){

    return <Card sx={{ height: "100%", bgcolor: props.backgroundColor, backgroundImage:'none', boxShadow: 'none'}}>
                <Box>
                    <Pie data={props.chartConfig.data} options={props.chartConfig.options} plugins={[ChartDataLabels]} />   
                </Box>
            </Card>
}