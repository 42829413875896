import React, { Fragment } from 'react';

import { Autocomplete, AutocompleteRenderInputParams, FormControl, Grid, List, TextField } from '@mui/material';
import { fetchWithAuthorisationHeader, postWithAuthorisationHeader } from '../../services/AuthenticationService';

import styles from './CycleSelector.module.css';

import settings from '../../config';
import { UserPreference } from '../../Enum/UserPreference';
import { INameValue } from '../../models/INameValue';
import { ICycle } from '../../models/ICycle';

interface Props {
  onCycleIdChange: Function;
  organisationId: number;
  lastAccessedCycleId: string;
}

const CycleSelector = (props: Props) => {
  const maxCycleNameCharacters = 24;
  let emptyCycles: ICycle[] = [];

  /* Initialisation of the states */
  const [cycles, setCycles] = React.useState(emptyCycles); //Represents the full list of campiagns
  const [iniliatised, setIniliatised] = React.useState(false); //Indicates whether the api has been called or not
  let isDisabled = cycles.length === 0;
  
  React.useEffect(() => {
    async function getCycles() {
      try {
        let response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'cycle/roles');
        setIniliatised(true);
        if (response && response.data) {
          setCycles(response.data.sort((a: ICycle, b: ICycle) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (props.organisationId != 0) {
      getCycles();
    }
  }, [props.organisationId]);

  React.useEffect(() => {
    //Setting a default value in the cycle dropdown does not trigger a onChange event , so explictly calling it
    if (props.lastAccessedCycleId !== '0') {
      let cycle = cycles.find((i) => i.id == props.lastAccessedCycleId);
      if (cycle !== null) {
          props.onCycleIdChange({ id: props.lastAccessedCycleId, name: cycle?.name ?? '', startDate: cycle?.startDate });
      }
    }
  }, [cycles, props.lastAccessedCycleId]);

  const onCycleChange = async (event: React.ChangeEvent<{}>, selectedCycle: any) => {
    if (selectedCycle != null) {
      let cycle = cycles.find((i) => i.id == selectedCycle.id);
        props.onCycleIdChange({ id: selectedCycle.id, name: cycle?.name ?? '', startDate: selectedCycle.startDate });
      try {
        var userPreference: INameValue = {
          name: UserPreference.LAST_ACCESSED_CYCLE,
          value: selectedCycle.id,
        };
        await postWithAuthorisationHeader(settings.CMPLY_API_URL + 'preference', userPreference);
      } catch (e) {
        console.log(e);
      }
    } else {
        props.onCycleIdChange({ id: '0', name: '', startDate: '' });
    }
  };

  const onRenderCycleOption = (props: any, cycle: ICycle, state: object) => {
    let displayName = cycle.name.length > maxCycleNameCharacters ? cycle.name.substring(0, maxCycleNameCharacters) + '...' : cycle.name;
    return (
      <li {...props} key={cycle.id} title={displayName != cycle.name ? cycle.name : undefined}>
        {displayName}
      </li>
    );
  };

  const onRenderInput = (params: AutocompleteRenderInputParams) => {
    let inputProps = params.inputProps as any;
    let cycleName = inputProps.value;
    let titleText = cycleName.length > maxCycleNameCharacters ? cycleName : undefined;

    return (
      <TextField
        {...params}
        title={titleText}
        label={isDisabled ? "No cycles available" : "Select a Cycle"}
        variant="filled"
        size="small"
        fullWidth
        disabled = {isDisabled}
        margin="dense"
        InputLabelProps={{
          className: styles.label,
        }}
      />
    );
  };

  /*Render*/
  if (!iniliatised) return null; // Render nothing if the api is not called

  return (
    <div>
      <FormControl>
        <Autocomplete
          options={cycles}
          renderOption={onRenderCycleOption}
          getOptionLabel={(option) => option.name}
          value={cycles.find((i) => i.id == props.lastAccessedCycleId) ? cycles.find((i) => i.id == props.lastAccessedCycleId) : null}
          disabled = {isDisabled}
          classes={{
            root: styles.root,
            inputRoot: styles.inputRoot,
            input: styles.input,
            listbox: styles.listbox,
            option: styles.listOption,
            popupIndicator: styles.indicator,
            clearIndicator: styles.indicator,
          }}
          onChange={(event, value) => {
            onCycleChange(event, value);
          }}
          ListboxComponent={List}
          renderInput={onRenderInput}
        />
      </FormControl>
    </div>
  );
};

export default CycleSelector;
