import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from './ProgramStatusFilter.module.css';
import TextField from '@mui/material/TextField';
import { fetchWithAuthorisationHeader } from '../../../../../services/AuthenticationService';
import settings from '../../../../../config';


interface IProgramStatusFilterProps {
    onFilterChanged: Function;
    clearFilters: number;
    cycleId: string;
}

interface IProgramStatus {
    programIds: number[];
    name: string;
    isSelected: boolean;
}

const labelStyle = {
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7F4A8'
    }
};

export function ProgramStatusFilter(props: IProgramStatusFilterProps) {
    const initialProgramStatus: IProgramStatus[] = [];
    const [programStatuses, setProgramStatus] = useState(initialProgramStatus);
    const [filteredProgramStatus, setFilteredProgramStatus] = useState(initialProgramStatus);
    const [lastClearFilters, setLastClearFilters] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const searchFilterRef = useRef<HTMLInputElement>(null);

    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {
        if (props.clearFilters != lastClearFilters) {
            programStatuses.forEach(s => s.isSelected = false);
            setProgramStatus(programStatuses.slice());
            setSelectAll(false);
            setLastClearFilters(props.clearFilters);

            //reset the search field textbox
            if(searchFilterRef.current) {
                searchFilterRef.current.value = "";
            }
            setFilteredProgramStatus(programStatuses);
        }
    }, [props.clearFilters, lastClearFilters, programStatuses]);


    function groupByKey(keyArray: string[], objectArray: any[], property: any, fallbackKey: any) {
        return objectArray.reduce((acc, obj) => {
            const objKey = (obj[property] as string);
            const key = keyArray.includes(objKey) ? objKey : fallbackKey;
            const curGroup = acc[key] ?? [];

            return { ...acc, [key]: [...curGroup, obj] };
        }, {});
    }

    //Handle when cycle has been changed
    useEffect(() => {
        async function getProgramStatus() {
            try {
                let programStatusList: IProgramStatus[] =[];
                programStatuses.forEach(s => s.isSelected = false);
                let selectedProgramStatus = programStatuses.filter(s => s.isSelected && s.name != 'Select All').map(s => s.name);
                props.onFilterChanged(selectedProgramStatus);
                if (props.cycleId != '0') {
                    var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + "program/cycle/" + props.cycleId);
                    var programsResponse = response.data;

                    const fallbackType = "other";
                    const statusArray:string[] = Array.from(new Set(programsResponse.map((program:any) => program.status)));
                    const grouped = groupByKey(statusArray, programsResponse, "status", fallbackType);
                    const programStatusList = Object.entries(grouped).map(([key, value]) => {
                        const programArray = value as Array<any>; // Type assertion

                        return {
                            programIds: programArray.map(program => program.id),
                            name: key,
                            isSelected: false,
                        }
                    });                                    
                  
                    setProgramStatus(programStatusList.sort((a, b) => (a.name < b.name ? -1 : 1)));
                    setFilteredProgramStatus(programStatusList);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        getProgramStatus();
    }, [props.cycleId]);

    function onSelectAll(selectAll: boolean) {
        if (!selectAll) {
            filteredProgramStatus.forEach(s => s.isSelected = true);
        } else {
            filteredProgramStatus.forEach(s => s.isSelected = false);
        }

        setProgramStatus(programStatuses.slice());
        let selectedProgramStatus = programStatuses.filter(s => s.isSelected).map(s => s.name);
        props.onFilterChanged(selectedProgramStatus);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(programStatus: IProgramStatus) {
        programStatus.isSelected = !programStatus.isSelected;
        setProgramStatus(programStatuses.slice());
        let selectedProgramStatus = programStatuses.filter(s => s.isSelected).map(s => s.name);
        props.onFilterChanged(selectedProgramStatus);
        syncSelectAll();
    };

    function syncSelectAll() {
        let selectedProgramStatus = programStatuses.filter(s => s.isSelected);
        if (!selectAll && selectedProgramStatus.length == programStatuses.length) {
            setSelectAll(true);
        } else if (selectAll && selectedProgramStatus.length < programStatuses.length) {
            setSelectAll(false);
        }
    }

    function onProgramStatusChange(value: string) {
        if (value == "") {
            syncSelectAll();
            setFilteredProgramStatus(programStatuses);
        } else {
            setFilteredProgramStatus(programStatuses.filter(s => s.name?.toLowerCase().includes(value.toLowerCase())));
        }
    };

    let ProgramStatusCheckboxes: JSX.Element[] = [];
    filteredProgramStatus.forEach(programStatus => {
        ProgramStatusCheckboxes.push(
            <FormControlLabel
                key={programStatus.programIds.toString()}
                sx={{ '& .MuiFormControlLabel-label': programStatus.isSelected ? labelStyle.labelSelected : labelStyle.label }}
                control={<Checkbox color="secondary" checked={programStatus.isSelected} style={{ opacity: '0.5 !important' }} onChange={() => onCheckboxChanged(programStatus)} name={programStatus.name} size={'small'} />}
                label={programStatus.name}
            />
        );
    });

    let selectAllCheckbox = (
        <FormControlLabel
            key={"Select All"}
            sx={{ '& .MuiFormControlLabel-label': selectAll ? labelStyle.labelSelected : labelStyle.label }}
            control={<Checkbox color="secondary"  style={{ opacity: '0.5 !important' }} checked={selectAll}  onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
            label={"Select All"}
        />
    );

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <FormGroup>
                    <TextField inputRef={searchFilterRef} size="small" style={{ color: '#F7F4A8', marginTop: 8 }} id="ProgramStatus-filter" label="Search" onChange={(event) => onProgramStatusChange(event.target.value)} />
                    {selectAllCheckbox}
                    {ProgramStatusCheckboxes}
                </FormGroup>
            </div>
        </div>
    );
}
