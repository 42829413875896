import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from './TaskTypeFilter.module.css';
import TextField from '@mui/material/TextField';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import settings from '../../../../config';


interface ITaskTypeFilterProps {
    onFilterChanged: Function;
    clearFilters: number;
    cycleId: string;
}

interface ITaskTypeFilter {
    id: number;
    type: string;
    isSelected: boolean;
}

const labelStyle = {
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7F4A8'
    }
};

export function TaskTypeFilter(props: ITaskTypeFilterProps) {
    const initialTaskTypes: ITaskTypeFilter[] = [];
    const [taskTypes, setTaskTypes] = useState(initialTaskTypes);
    const [filteredTaskTypes, setFilteredTaskTypes] = useState(initialTaskTypes);
    const [lastClearFilters, setLastClearFilters] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const searchFilterRef = useRef<HTMLInputElement>(null);

    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {
        if (props.clearFilters != lastClearFilters) {
            taskTypes.forEach(s => s.isSelected = false);
            setTaskTypes(taskTypes.slice());
            setSelectAll(false);
            setLastClearFilters(props.clearFilters);

            //reset the search field textbox
            if (searchFilterRef.current) {
                searchFilterRef.current.value = "";
            }
            setFilteredTaskTypes(taskTypes);
        }

    }, [props.clearFilters, lastClearFilters, taskTypes]);

    //Handle when cycle has been changed
    useEffect(() => {
        async function getTaskTypes() {
            try {
                let TaskTypeList: ITaskTypeFilter[] = [];
                taskTypes.forEach(s => s.isSelected = false);
                let selectedTaskTypeIds = taskTypes.filter(s => s.isSelected && s.type != 'Select All').map(s => s.id);
                props.onFilterChanged(selectedTaskTypeIds);
                if (props.cycleId != '0') {
                    var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + "Task/types");
                    
                    response.data.forEach((t: any) => {
                        // Null values need not be displayed for now. So filter them. 
                        if (t != null) {
                            var newTaskType = { id: t.id, type: t.name, isSelected: false };
                            TaskTypeList.push(newTaskType);
                        }
                   });

                    setTaskTypes(TaskTypeList);
                    setFilteredTaskTypes(TaskTypeList);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        getTaskTypes();
    }, [props.cycleId]);

    function onSelectAll(selectAll: boolean) {
        if (!selectAll) {
            filteredTaskTypes.forEach(s => s.isSelected = true);
        } else {
            filteredTaskTypes.forEach(s => s.isSelected = false);
        }

        setTaskTypes(taskTypes.slice());
        let selectedTaskTypeIds = taskTypes.filter(s => s.isSelected).map(s => s.id);
        props.onFilterChanged(selectedTaskTypeIds);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(taskType: ITaskTypeFilter) {
        taskType.isSelected = !taskType.isSelected;
        setTaskTypes(taskTypes.slice());
        let selectedTaskTypes = taskTypes.filter(s => s.isSelected).map(s => s.id);
        props.onFilterChanged(selectedTaskTypes);
        syncSelectAll();
    };

    function syncSelectAll() {
        let selectedTaskTypes = taskTypes.filter(s => s.isSelected);
        if (!selectAll && selectedTaskTypes.length == taskTypes.length) {
            setSelectAll(true);
        } else if (selectAll && selectedTaskTypes.length < taskTypes.length) {
            setSelectAll(false);
        }
    }

    function onTaskTypeChange(value: string) {
        if (value == "") {
            syncSelectAll();
            setFilteredTaskTypes(taskTypes);
        } else {
            setFilteredTaskTypes(taskTypes.filter(s => s.type.toLowerCase().includes(value.toLowerCase())));
        }
    };

    let TaskTypeCheckboxes: JSX.Element[] = [];
    filteredTaskTypes.forEach(TaskType => {
        TaskTypeCheckboxes.push(
            <FormControlLabel
                key={TaskType.type}
                sx={{ '& .MuiFormControlLabel-label': TaskType.isSelected ? labelStyle.labelSelected : labelStyle.label }}
                control={<Checkbox color = "secondary" checked={TaskType.isSelected} style={{ opacity: '0.5 !important' }}  onChange={() => onCheckboxChanged(TaskType)} name={TaskType.type} size={'small'} />}
                label={TaskType.type}
            />
        );
    });

    let selectAllCheckbox = (
        <FormControlLabel
            key={"Select All"}
            sx={{ '& .MuiFormControlLabel-label': selectAll ? labelStyle.labelSelected : labelStyle.label }}
            control={<Checkbox color="secondary"  style={{ opacity: '0.5 !important' }} checked={selectAll}  onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
            label={"Select All"}
        />
    );

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <FormGroup>
                    <TextField inputRef={searchFilterRef} size="small" style={{ color: '#F7F4A8', marginTop: 8 }} id="TaskType-filter" label="Search" onChange={(event) => onTaskTypeChange(event.target.value)} />
                    {selectAllCheckbox}
                    {TaskTypeCheckboxes}
                </FormGroup>
            </div>
        </div>
    );
}
