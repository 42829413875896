import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';

import { IChartLegend } from '../../../../../models/IChartLegend';
import { IAssetTaskQualityValues } from '../../../../../models/IAssetTaskQualityValues';
import { DonutChartWidget } from '../../../Shared/Widgets/DonutChartWidget/DonutChartWidget';

interface IProps {
    assetTaskQualityCounts: IAssetTaskQualityValues | null;
}

export function FPAuditConformanceWidget(props: IProps) {

    const titleText = 'Audit conformance - FP'

    const [firstChartLegends, set1stChartLegends] = useState<IChartLegend[]>([]);
  
    const [chartData, setChartData] = useState<object | null>(null);

    const [centreBigText, setCentreBigText] = useState('');
    const [totalAuditCount, setTotalAuditCount] = useState(0);

    useEffect(() => {
        if (props.assetTaskQualityCounts != null && props.assetTaskQualityCounts.auditReasonsData != null) {

            setTotalAuditCount(props.assetTaskQualityCounts.totalFPAuditTasks);
            setCentreBigText(props.assetTaskQualityCounts.totalFPAuditTasks.toString());
            setChartData(getChartData(props.assetTaskQualityCounts));
        }
    }, [props.assetTaskQualityCounts]);

    // Returns a ChartJS data object
    function getChartData(auditValues: IAssetTaskQualityValues) {

        let data: any = {};
        let dataset: any = {};
        data.datasets = [dataset];
        dataset.borderWidth = 2;
      
        set1stChartLegends([
            { text: 'Spans conformant', colour: '#224A20', borderColour: '#224A20' },
            { text: 'Spans non-conformant', colour: '#B58B39', borderColour: '#B58B39' },
        ]);

        data.labels = ['Spans conformant', 'Spans non-conformant'];
        dataset.data = [auditValues.totalFPAuditConformant,
                        auditValues.totalFPAuditNonConformant];
        dataset.borderColor = [' #224A20', '#B58B39'];
        dataset.backgroundColor = [' #224A20', '#B58B39'];

        return data;
    }

    function tooltipLabelCallback(tooltipItem: any) {
        // This is called by ChartJs onMouseOver the chart
        return tooltipItem.label + ': ' + tooltipItem.formattedValue;
    };

    return <Card sx={{ height: "100%", backgroundImage: 'none' }}>
        {chartData &&
            <DonutChartWidget
                topText={''}
                centreBigText={centreBigText}
                titleText={titleText}
                totalCount={totalAuditCount}
                firstChartLegends={firstChartLegends}
                chartData={chartData}
                tooltipLabelCallback={tooltipLabelCallback}
            ></DonutChartWidget>
        }
    </Card>
}