import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import { Avatar, LinearProgress } from '@mui/material';
import { Divider, IconButton } from '@mui/material';
import { IAssetTaskExtension } from '../../models/IAssetTaskExtension';
import { fetchWithAuthorisationHeader } from '../../services/AuthenticationService';
import settings from '../../config';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Draggable from 'react-draggable'; 
import closeIcon from '../../images/close.png';
import styles from './AssetTaskDetail.module.css';

interface IProps {
  assetId: number;
  assetTaskId: number;
  cycleId: string;
  managementAreaId: number;
  onClose: Function;
}

export function AssetTaskDetail(props: IProps) {
    const [assetTask, setAssetTask] = useState<IAssetTaskExtension | null>(null);
    const [assetTaskValues, setAssetTaskValues] = useState<any>({});
    const lastAssetTaskIdRef = React.useRef(0);
    const [showMetadataList, setShowMetadataList] = useState(true);
    const windowNodeRef = React.useRef<any|null>(null);

    const windowHeight: number = 450;
    const headerHeight: number = 48;

    useEffect(() => {

        async function getAssetTaskDetail() {
          try {
            var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'task/taskDetail/' + props.assetTaskId + '/' + props.assetId + '/' + props.managementAreaId + '/' + props.cycleId);
            let theAssetTask = response.data as IAssetTaskExtension;
            let theAssetTaskValues: any = {};
            
            theAssetTaskValues['Program External Reference Id'] = theAssetTask.programExternalReferenceId;
            theAssetTaskValues['Asset External Reference Id'] = theAssetTask.assetExternalReferenceId;
            theAssetTaskValues['Job External Reference Id'] = theAssetTask.jobExternalReferenceId;
            theAssetTaskValues['External Task Description'] = theAssetTask.externalTaskDescription;
            theAssetTaskValues['Company'] = theAssetTask.company;
            theAssetTaskValues['Asset Type'] = theAssetTask.assetType;
            theAssetTaskValues['Task Type'] = theAssetTask.taskType;
            theAssetTaskValues['Task Method'] = theAssetTask.taskMethod;
            theAssetTaskValues['Task Status'] = theAssetTask.taskStatus;
            theAssetTaskValues['Assignee Or Crew'] = theAssetTask.assigneeOrCrew;
            theAssetTaskValues['Task Finalised Date'] = theAssetTask.taskFinalisedDate;
            theAssetTaskValues['Created By'] = theAssetTask.createdBy;
            theAssetTaskValues['Created Date'] = moment(theAssetTask.createdDate).local().format('DD-MM-YYYY');
            theAssetTaskValues['Updated By'] = theAssetTask.updatedBy;
            theAssetTaskValues['Updated Date'] = theAssetTask.updatedDate != null ? moment(theAssetTask.updatedDate).local().format('DD-MM-YYYY') : '';

            setAssetTaskValues(theAssetTaskValues);
            setAssetTask(theAssetTask);
            setShowMetadataList(true);

          } catch (e) {
            console.log(e);
            setAssetTask(null);
          }
        }

        if (props.assetTaskId > 0 &&  props.assetTaskId != lastAssetTaskIdRef.current ) {
          lastAssetTaskIdRef.current  = props.assetTaskId;
          getAssetTaskDetail();
        }
      }, [props.assetTaskId]);


     function getStringItem(obj:string) {
        return <Typography noWrap variant="h6" style={{fontSize:12, color:'#ffffff'}}>                    
                { obj }
              </Typography>
     }

     function getItem(obj: any) {
        if (obj) {
            if (typeof obj === 'string') {
                return getStringItem(obj);
            }
            else if (Array.isArray(obj)) {
                return getArrayItem(obj);
            }
            else if (typeof obj === 'object') {
                return getMetadataItem(obj);
            }
        }
        return <div></div>;
     }

     function getArrayItem(arr:any[]){
        if (arr.length === 0 ) {
             return <div></div>;
        }

        var firstValue = arr[0];

        if ( typeof firstValue === 'string'){
             return <Grid container >
                        { arr.map( (val:string) => {
                        
                            return <Grid item xs={12}>
                                        { getStringItem(val) }
                                   </Grid>
                        })}
                    </Grid>       
        }
        else if ( typeof firstValue === 'object'){
             return <Grid container >
                        { arr.map( (val:any) => {
                        
                            return <Grid item xs={12} style={{marginBottom:20}}>
                                        { getMetadataItem(val) }
                                   </Grid>
                        })}
                    </Grid>  
        }

        return <div></div>;
     }

     function getMetadataItem(metadataObj:any) { 

        let orderedMetadata = Object.keys(metadataObj).sort( (a:string, b:string) => a.toLowerCase().localeCompare(b.toLowerCase()) ).reduce(
              (obj:any, key:string) => { 
                obj[key] = metadataObj[key]; 
                return obj;
              }, 
              {}
            );
       return  Object.keys(orderedMetadata).map( (key:any) => {
         return <Grid container >
                    <Grid item xs={5}>
                        <Typography noWrap variant="h6" style={{fontSize:12, color:'#747474', whiteSpace: 'pre-wrap'}}>                    
                            {key}
                        </Typography>
                    </Grid>
                    <Grid item xs={7} style={{whiteSpace: 'pre-wrap'}}>
                        {  getItem( orderedMetadata[key]) }
                    </Grid>
                </Grid>
        })
     }

  return ( <div style={{ height: '100%', width: '100%', display: props.assetTaskId > 0 ? 'block' : 'none', backgroundColor: '#222222' }} onScroll={ (e:any) => e.preventDefault() } >
                <Draggable handle="strong" nodeRef={windowNodeRef} >
                    <div ref={windowNodeRef} className={styles.windowContainer} style={{height: windowHeight}}>
                        <strong className={styles.moveCursor}>
                            <div className={styles.draggableWindowHeader} style={{ display: 'block'}}>
                                <div className={styles.draggableWindowHeaderContent} style={{ fontWeight: 400, paddingLeft: 15, color: 'white' }}>
                                      
                                    <Grid container style={{textAlign:'left'}} direction="row">
                                        <Grid item style={{marginLeft: 0}}>
                                        </Grid>
                                        <Grid item xs={3} >
                                            <Typography noWrap variant="h6" style={{fontSize:12, color:'#ffffff'}}>                    
                                                {assetTask?.taskType}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} >
                                            <Typography noWrap variant="h6" style={{fontSize:12, color:'#ffffff'}}>                    
                                                {assetTask?.taskFinalisedDate != null ? moment(assetTask?.taskFinalisedDate).local().format('DD-MM-YYYY') : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} >
                                                <Box display="flex" justifyContent="flex-end" style={{marginTop:-10}}>
                                                <IconButton onClick={()=> props.onClose() } >
                                                    <Avatar style={{ width: 20, height: 20 }} src={closeIcon} />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>

                            </div>
                        </strong>
                        <div style={{ backgroundColor: '#222222', height: windowHeight - headerHeight, overflowY:'auto'}}>
                            <div style={{ margin:15, backgroundColor: '#222222'}}>

                                <Box display="flex" style={{ textAlign: 'left', fontWeight: 400, height: '100%' }}>
                                {assetTask == null && (
                                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height:40}}>
                                    <LinearProgress style={{ width: '260px', color: 'red', marginBottom: '8px' }} />
                                    </Box>
                                )}
                                {assetTask != null && (
                                    <Grid container spacing={0} >
                                    <Grid item xs={12}>
                                        { Object.keys(assetTaskValues).map( (key:any) => {

                                            return <Grid container >
                                                        <Grid item xs={5}>
                                                            <Typography noWrap variant="h6" style={{fontSize:12, color:'#747474'}}>                    
                                                                {key}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                                <Typography noWrap variant="h6" style={{fontSize:12, color:'#ffffff'}}>                    
                                                                {assetTaskValues[key]}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                            })}                
                                    </Grid>
                                    <Grid item xs={12} style={{marginTop:10,marginBottom:10}}>
                                        <Divider light={true} />
                                    </Grid>
                                    <Grid item xs={11} style={{marginBottom:10}}>
                                        <Box className={styles.itemHeader}>
                                        <Typography noWrap variant="subtitle2">Attributes</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} alignSelf="center" justifySelf="flex-end">
                                        <Box>
                                        <IconButton onClick={(e: any) => setShowMetadataList(!showMetadataList)} style={{ marginLeft: 8, height: 17, width: 17 }}>
                                            {showMetadataList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                        </Box>
                                    </Grid>
                                    {showMetadataList && (
                                        <Grid item xs={12} style={{marginBottom:10}}>
                                            { 
                                              getMetadataItem(assetTask.metadata )
                                            }                
                                        </Grid>
                                    )}
                                    </Grid>
                                )}
                                </Box>
                            </div>
                        </div>
                    </div>
                </Draggable>
             </div>
  );
}