import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';

import { IChartLegend } from '../../../../../models/IChartLegend';
import { HalfDoughnutWidget } from '../../../Shared/Widgets/HalfDonutWidget/HalfDoughnutWidget';

interface IProps {
    spanPerformanceValues: Record<string, number>;
}

export function AssessmentsPerformedWidget(props: IProps) {

    const titleText = 'Assessments completed'

    const [chartLegends, setChartLegends] = useState<IChartLegend[]>([]);
    const [chartData, setChartData] = useState<object | null>(null);

    const [centreBigText, setCentreBigText] = useState('');
    const [totalCount, setTotalCount] = useState(0);

    // Handle when the cycle has changed
    useEffect(() => {

        if (props.spanPerformanceValues == null || props.spanPerformanceValues.count == 0) {
            return;
        }
        setCentreBigText(props.spanPerformanceValues["spans_with_atleast_one_assessment"].toString());
        setTotalCount(props.spanPerformanceValues["total_spans"]);
        let spansNotAssessed = props.spanPerformanceValues["total_spans"] - props.spanPerformanceValues["spans_with_atleast_one_assessment"];

        setChartData(getChartData(props.spanPerformanceValues["no_action_spans"], props.spanPerformanceValues["incomplete_action_spans"], props.spanPerformanceValues["complete_action_spans"], spansNotAssessed));

        setChartLegends([
            { text: 'Complete action spans', colour: ' #A0D272', borderColour: ' #A0D272' },
            { text: 'No action spans', colour: '#9875FF', borderColour: '#9875FF' },
            { text: 'Incomplete action spans', colour: '#F08575', borderColour: '#F08575' },
            { text: 'Spans not assessed', colour: '#434343', borderColour: '#434343' },
        ]);

    }, [props.spanPerformanceValues]);

    // Returns a ChartJS data object
    function getChartData(noActionSpansCount: number, incompleteActionSpansCount: number, completeActionSpansCount: number, spansNotAssessed: number) {

        let data: any = {};

        let dataset: any = {};
        data.datasets = [dataset];
        dataset.borderWidth = 2;

        data.labels = ['Complete action spans', 'No action spans', 'Incomplete action spans', 'Spans not assessed'];
        dataset.data = [completeActionSpansCount, noActionSpansCount, incompleteActionSpansCount, spansNotAssessed];
        dataset.borderColor = [' #A0D272', '#9875FF', '#F08575', '#434343'];
        dataset.backgroundColor = [' #A0D272', '#9875FF', '#F08575', '#434343'];
     
        return data;
    }

    function tooltipLabelCallback(tooltipItem: any) {
        // This is called by ChartJs onMouseOver the chart
        return tooltipItem.label + ':' +  tooltipItem.formattedValue;
    };

    return <Card sx={{ height: "100%", backgroundImage: 'none' }}>
        {chartData &&
            <HalfDoughnutWidget
                topText={''}
                centreBigText={centreBigText}
                titleText={titleText}
                totalCount={totalCount}
                chartLegends={chartLegends}
                chartData={chartData}
                tooltipLabelCallback={tooltipLabelCallback}
            ></HalfDoughnutWidget>
        }
    </Card>
}