import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { Grid, Typography, TextField } from '@mui/material';
import { Avatar, LinearProgress } from '@mui/material';
import { Divider, IconButton, Button } from '@mui/material';
import { IApprovalDetail } from '../../models/IApprovalDetail';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { fetchWithAuthorisationHeader, postWithAuthorisationHeader } from '../../services/AuthenticationService';
import settings from '../../config';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import Draggable from 'react-draggable'; 
import closeIcon from '../../images/close.png';
import styles from './ApprovalDetail.module.css';

interface IProps {
  approvalId: number;
  canEdit: boolean;
  onClose: Function;
  onApprovalUpdate: Function;
}

export function ApprovalDetail(props: IProps) {
    const [approval, setApproval] = useState<IApprovalDetail | null>(null);
    const [approvalValues, setApprovalValues] = useState<any>({});
    const lastApprovalIdRef = React.useRef(0);
    const [showMetadataList, setShowMetadataList] = useState(true);
    const windowNodeRef = React.useRef<any|null>(null);
    const [status, setStatus] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const [responseSent, setResponseSent] = useState(false);
    const [comment, setComment] = useState('');
    const statuses: string[] = [
        'Approved',
        'Created',
        'Deferred',
        'On-hold',
    ];

    const windowHeight: number = 450;
    const headerHeight: number = 48;

    const getApprovalValues = (theApproval: IApprovalDetail) => {
        let theApprovalValues: any = {};

        if (props.canEdit) {
            setComment(theApproval.comment ? theApproval.comment: "");
            setStatus(theApproval.status);
        } else {
            theApprovalValues['Status'] = theApproval.status;
            theApprovalValues['Comment'] = theApproval.comment;
        }

        theApprovalValues['Is Active'] = theApproval.isActive.toString();
        theApprovalValues['Approval Unique Id'] = theApproval.approvalUniqueId;
        theApprovalValues['Span Id'] = theApproval.assetExternalReferenceId;
        theApprovalValues['Created By'] = theApproval.createdBy;
        theApprovalValues['Created Date'] = moment(theApproval.createdDate).local().format('DD-MM-YYYY');
        theApprovalValues['Updated By'] = theApproval.updatedBy;
        theApprovalValues['Updated Date'] = theApproval.updatedDate != null ? moment(theApproval.updatedDate).local().format('DD-MM-YYYY') : '';
        theApprovalValues['Response Sent'] = theApproval.responseSent.toString();
        theApprovalValues['Response Date'] = theApproval.responseDate != null ? moment(theApproval.responseDate).local().format('DD-MM-YYYY') : '';
        theApprovalValues['Reviewed By'] = theApproval.reviewedBy;
        theApprovalValues['Reviewed Date'] = theApproval.reviewedDate != null ? moment(theApproval.reviewedDate).local().format('DD-MM-YYYY') : '';

        return theApprovalValues;
    };

    useEffect(() => {

        async function getApprovalDetail() {
          try {
            var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'approval/detail/' + props.approvalId );
            let theApproval = response.data as IApprovalDetail;
            let theApprovalValues = getApprovalValues(theApproval);

            setResponseSent( theApproval.responseSent );
            setApprovalValues(theApprovalValues);
            setApproval(theApproval);
            setShowMetadataList(true);
            setIsDirty(false);

          } catch (e) {
            console.log(e);
            setApproval(null);
          }
        }

        if (props.approvalId !== lastApprovalIdRef.current) {
            lastApprovalIdRef.current = props.approvalId;
            if (props.approvalId > 0) {
                getApprovalDetail();
            }
        }
        
      }, [props.approvalId]);

    async function onApplyChanges(){
        try {

            let data = {
                approvalId: props.approvalId,
                status: status,
                comment: comment
            }

            if (data.comment !== approval?.comment || data.status !== approval?.status) {
                var response = await postWithAuthorisationHeader(settings.CMPLY_API_URL + "approval/update", data);
                let theApproval = response.data as IApprovalDetail;
                
                let theApprovalValues = getApprovalValues(theApproval);
                setResponseSent(theApproval.responseSent);
                setApprovalValues(theApprovalValues);
                setApproval(theApproval);
                props.onApprovalUpdate();
                setIsDirty(false);
            }
        }
        catch (e) {
            console.log('Failed to update approval:' + e);
        }
    }

     function getStringItem(obj:string) {
        return <Typography noWrap variant="h6" style={{fontSize:12, color:'#ffffff'}}>                    
                { obj }
              </Typography>
     }

     function getItem(obj:any) {
        if ( typeof obj === 'string' ){
            return getStringItem(obj);
        }
        else if ( Array.isArray(obj) ){
            return getArrayItem(obj);
        }
        else if ( typeof obj === 'object' ){
            return getMetadataItem(obj);
        }

        return <div></div>;
     }

     function getArrayItem(arr:any[]){
        if (arr.length === 0 ) {
             return <div></div>;
        }

        var firstValue = arr[0];

        if ( typeof firstValue === 'string'){
             return <Grid container >
                        { arr.map( (val:string) => {
                        
                            return <Grid item xs={12}>
                                        { getStringItem(val) }
                                   </Grid>
                        })}
                    </Grid>       
        }
        else if ( typeof firstValue === 'object'){
             return <Grid container >
                        { arr.map( (val:any) => {
                        
                            return <Grid item xs={12} style={{marginBottom:20}}>
                                        { getMetadataItem(val) }
                                   </Grid>
                        })}
                    </Grid>  
        }

        return <div></div>;
     }

     function getMetadataItem(metadataObj:any) { 

        let orderedMetadata = Object.keys(metadataObj).sort( (a:string, b:string) => a.toLowerCase().localeCompare(b.toLowerCase()) ).reduce(
              (obj:any, key:string) => { 
                obj[key] = metadataObj[key]; 
                return obj;
              }, 
              {}
            );
       return  Object.keys(orderedMetadata).map( (key:any) => {
         return <Grid container >
                    <Grid item xs={5}>
                        <Typography noWrap variant="h6" style={{fontSize:12, color:'#747474', whiteSpace: 'pre-wrap'}}>                    
                            {key}
                        </Typography>
                    </Grid>
                    <Grid item xs={7} style={{whiteSpace: 'pre-wrap'}}>
                        {  getItem( orderedMetadata[key]) }
                    </Grid>
                </Grid>
        })
     }

  return ( <div style={{ height: '100%', width: '100%', display: props.approvalId > 0 ? 'block' : 'none', backgroundColor: '#222222' }} onScroll={ (e:any) => e.preventDefault() } >
                <Draggable handle="strong" nodeRef={windowNodeRef} >
                    <div ref={windowNodeRef} className={styles.windowContainer} style={{height: windowHeight}}>
                        <strong className={styles.moveCursor}>
                            <div className={styles.draggableWindowHeader} style={{ display: 'block'}}>
                                <div className={styles.draggableWindowHeaderContent} style={{ fontWeight: 400, paddingLeft: 15, color: 'white' }}>
                                      
                                    <Grid container style={{textAlign:'left'}} direction="row">
                                        <Grid item style={{marginLeft: 0}}>
                                        </Grid>
                                        <Grid item xs={3} >
                                            <Typography noWrap variant="h6" style={{fontSize:12, color:'#ffffff'}}>                    
                                                Approval {approval?.approvalId}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} >
                                        </Grid>
                                        <Grid item xs={6} >
                                                <Box display="flex" justifyContent="flex-end" style={{marginTop:-10}}>
                                                <IconButton onClick={()=> props.onClose() } >
                                                    <Avatar style={{ width: 20, height: 20 }} src={closeIcon} />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>

                            </div>
                        </strong>
                        <div style={{ backgroundColor: '#222222', height: windowHeight - headerHeight, overflowY:'auto'}}>
                            <div style={{ margin:15, backgroundColor: '#222222'}}>

                                <Box display="flex" style={{ textAlign: 'left', fontWeight: 400, height: '100%' }}>
                                {approval == null && (
                                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height:40}}>
                                    <LinearProgress style={{ width: '260px', color: 'red', marginBottom: '8px' }} />
                                    </Box>
                                )}
                                {approval != null && (
                                  <Grid container spacing={0} >
                                    { props.canEdit &&
                                      <React.Fragment>
                                        <Grid container >
                                            <Grid item xs={5}>
                                                <Typography noWrap variant="h6" style={{fontSize:12, color:'#747474'}}>                    
                                                    Status
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} >
                                                  <Select 
                                                     style={{height:36,fontSize:16,marginBottom:10}}
                                                     value={status}
                                                     onChange={ (e:SelectChangeEvent ) => { 
                                                        let val = e.target.value;
                                                        setStatus(val);
                                                        setIsDirty(val !== approval.status);
                                                     }}
                                                     >
                                                    { statuses.map( (s:string ) => {
                                                        return <MenuItem value={s}>{s}</MenuItem>
                                                     })} 
                                                  </Select>  
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Box display="flex" justifyContent="flex-end" style={{width:'100%'}}>
                                                    <Button size="small" color="secondary" variant="contained" style={{ marginLeft: 24, marginTop: 4 }} disabled={!isDirty} onClick={() => onApplyChanges()}>
                                                        APPLY
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        { responseSent && 
                                              <Grid container >
                                                    <Grid item xs={5}>
                                                    </Grid>
                                                    <Grid item xs={5} >
                                                          <Typography variant="h6" style={{fontSize:12, color:'#747474'}}>                    
                                                            <div style={{width:'100%'}} >
                                                              This status has already been received by the contractor, please notify the contractor if changing this value
                                                            </div> 
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                        }
                                        <Grid container >
                                            <Grid item xs={5}>
                                                <Typography noWrap variant="h6" style={{fontSize:12, color:'#747474'}}>                    
                                                    Comment
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Typography noWrap variant="h6" style={{fontSize:12, color:'#ffffff', marginBottom:10, width:'100%'}}>                    
                                                  <TextField
                                                      multiline
                                                      onChange={ (e:any) => {
                                                        let val = e.target.value;
                                                        setComment(val);
                                                        setIsDirty(val !== approval.comment);
                                                      }}
                                                      variant="outlined"
                                                      value={comment}
                                                      >
                                                  </TextField>  
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                       </React.Fragment>
                                    }
                                    <Grid item xs={12}>
                                        { Object.keys(approvalValues).map( (key:any) => {

                                            return <Grid container >
                                                        <Grid item xs={5}>
                                                            <Typography noWrap variant="h6" style={{fontSize:12, color:'#747474'}}>                    
                                                                {key}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                                <Typography noWrap variant="h6" style={{fontSize:12, color:'#ffffff'}}>                    
                                                                {approvalValues[key]}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                            })}                
                                    </Grid>
                                    <Grid item xs={12} style={{marginTop:10,marginBottom:10}}>
                                        <Divider light={true} />
                                    </Grid>
                                    <Grid item xs={11} style={{marginBottom:10}}>
                                        <Box className={styles.itemHeader}>
                                        <Typography noWrap variant="subtitle2">Attributes</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} alignSelf="center" justifySelf="flex-end">
                                        <Box>
                                        <IconButton onClick={(e: any) => setShowMetadataList(!showMetadataList)} style={{ marginLeft: 8, height: 17, width: 17 }}>
                                            {showMetadataList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                        </Box>
                                    </Grid>
                                    {showMetadataList && (
                                        <Grid item xs={12} style={{marginBottom:10}}>
                                            { 
                                              getMetadataItem(approval.metadata )
                                            }                
                                        </Grid>
                                    )}
                                    </Grid>
                                )}
                                </Box>
                            </div>
                        </div>
                    </div>
                </Draggable>
             </div>
  );
}