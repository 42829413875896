import React from 'react';
import { Tooltip} from '@mui/material';

interface IUserIconProps {
    firstName:string;
    surname:string | null;
    emailAddress:string;
    tooltip:string | null;    
    marginLeft: number;
    marginRight: number;
    height: number;
    width: number;
    fontSize: number;
}

export function UserIcon(props: IUserIconProps) {

    function stringToColour(str:string) {
        let hash = 0;
        for (let i = 0; i < str.length; ++i) {
         hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        let colour = '#';
        for (var i = 0; i < 3; ++i) {
            let value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    function adjustColourRange(hexColour:string, val:number, higher: boolean) {

        let adjustedColour:string = '#';
        let idx = 1;

        // Adjust the rgb values
        // Either adjust the value so that it not greater on not less than val
        for(let x=0; x<3; ++x){
            let hex = hexColour.substr(idx,2);

            // colourNum is a num 0-255
            let colourNum = parseInt(hex, 16); 

            if (higher && colourNum > val )
                colourNum = val - (colourNum - val);

            else if (!higher && colourNum < val )
                colourNum = val + (val - colourNum);

            adjustedColour += colourNum.toString(16);
            idx+=2;
        }

        return adjustedColour;
    }

    let initials = props.firstName[0].toUpperCase();

    // Surname is optional
    if ( props.surname != null && props.surname.length > 0 )
        initials += props.surname[0].toUpperCase();

    // Generate a unique colour from the email address 
    let userNameBackgroundColor = stringToColour(props.emailAddress);

    // Reduce the whiteness
    userNameBackgroundColor = adjustColourRange(userNameBackgroundColor,220,true);

    // Reduce the blackness
    userNameBackgroundColor = adjustColourRange(userNameBackgroundColor,25,false);

    return <Tooltip title={props.tooltip != null ? props.tooltip : '' }>
            <div style={{ marginLeft: props.marginLeft, marginRight: props.marginRight, height: props.height, width: props.width,borderRadius:15,color:'white', backgroundColor:userNameBackgroundColor,display:'flex',alignItems:'center',justifyContent:'center'}}>
                <div style={{ fontSize: props.fontSize,marginLeft:1}}>{initials}</div>        
            </div>
           </Tooltip>
}
