import { IManagementAreaSearchFilters } from '../models/IManagementAreaSearchFilters';

export function isFilterAssetSensitive(filtervalues: IManagementAreaSearchFilters | null) {
    if (filtervalues === null) {
        return false;
    } else {
        let isAssetId: Boolean = filtervalues.assetId !== null;
        let tasktypeIds: Boolean = filtervalues.taskTypeIds !== null && filtervalues.taskTypeIds !== undefined && filtervalues.taskTypeIds?.length > 0;
        let fireAreas: Boolean = filtervalues.fireAreas !== null && filtervalues.fireAreas !== undefined && filtervalues.fireAreas?.length > 0;
        let approvalStatuses: Boolean = filtervalues.approvalStatuses !== null && filtervalues.approvalStatuses !== undefined && filtervalues.approvalStatuses?.length > 0;
        let assetStatuses: Boolean = filtervalues.assetStatuses !== null && filtervalues.assetStatuses !== undefined && filtervalues.assetStatuses?.length > 0;
        let newSpan: Boolean = filtervalues.newSpan !== null && filtervalues.newSpan === true;
        let companyIds: Boolean = filtervalues.companyIds !== null && filtervalues.companyIds !== undefined && filtervalues.companyIds?.length > 0;
        return isAssetId || tasktypeIds || fireAreas || approvalStatuses || assetStatuses || newSpan || companyIds;
    }
}