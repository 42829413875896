import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IChartLegend } from '../../../../../models/IChartLegend';
import { PieChart } from '../../../../Charts/PieChart/PieChart';
import { IChartConfig } from '../../../../../models/IChartConfig';
import styles from './FireAreaSpansWidget.module.css'
import getConfig from './PieChartConfig'; 

interface IProps {
    spanPerformanceValues: Record<string, number>;
}

export function FireAreaSpansWidget(props: IProps) {

    const [chartLegends, setChartLegends] = useState<IChartLegend[]>([]);
    const [hbraSpansWithAssessment, setHbraSpansWithAssessment] = useState('');
    const [lbraSpansWithAssessment, setLbraSpansWithAssessment] = useState('');
    const [totalFireSpansWithAssessment, setTotalFireSpansWithAssessment] = useState('');
    const [hbraChartConfig, setHBRAChartConfig] = useState<IChartConfig | null>(null);
    const [lbraChartConfig, setLBRAChartConfig] = useState<IChartConfig | null>(null);

    useEffect(() => {

        setHbraSpansWithAssessment( props.spanPerformanceValues["hbra_spans"].toString());
        setLbraSpansWithAssessment( props.spanPerformanceValues["lbra_spans"].toString());
        setTotalFireSpansWithAssessment( props.spanPerformanceValues["hbra_and_lbra_spans"].toString());

        let hbraSpansNotAssessed = props.spanPerformanceValues["total_hbra_spans"] - props.spanPerformanceValues["hbra_spans"];
        let lbraSpansNotAssessed = props.spanPerformanceValues["total_lbra_spans"] - props.spanPerformanceValues["lbra_spans"];

        let pieChartConfig = getConfig();

        setHBRAChartConfig({data: getChartData(props.spanPerformanceValues["hbra_spans_no_action"], props.spanPerformanceValues["hbra_spans_incomplete"], props.spanPerformanceValues["hbra_spans_complete"], hbraSpansNotAssessed), 
                            options: pieChartConfig.options });

        setLBRAChartConfig({data: getChartData(props.spanPerformanceValues["lbra_spans_no_action"], props.spanPerformanceValues["lbra_spans_incomplete"], props.spanPerformanceValues["lbra_spans_complete"], lbraSpansNotAssessed), 
                            options: pieChartConfig.options });

        setChartLegends([
            { text: 'No action spans', colour: '#9875FF', borderColour: '#9875FF' },            
            { text: 'Incomplete action spans', colour: '#F08575', borderColour: '#F08575' },
            { text: 'Complete action spans', colour: '#A0D272', borderColour: '#A0D272' },
            { text: 'Spans not assessed', colour: '#404040', borderColour: '#404040' },
        ]);

    }, [props.spanPerformanceValues]);

    // Returns a ChartJS data object
    function getChartData(noActionSpansCount: number, incompleteActionSpansCount: number, completeActionSpansCount: number, spansNotAssessed: number) {
       
        let data: any = {};

        let dataset: any = {};
        data.datasets = [dataset];

        data.labels = ['Complete action spans', 'No action spans', 'Incomplete action spans', 'Spans not assessed'];
        dataset.borderWidth = 1;
        dataset.data = [completeActionSpansCount, noActionSpansCount, incompleteActionSpansCount, spansNotAssessed];
        dataset.borderColor = '#222222';
        dataset.backgroundColor = [' #A0D272', '#9875FF', '#F08575', '#404040'];
     
        return data;
    }

    return <Card sx={{ height: 500, backgroundImage: 'none', backgroundColor: '#222222' }}>
        <Grid container direction="row" style={{margin: '10px 10px 10px 16px'}}>
             <Grid item sx={{width:100}} style={{textAlign:'left', color: '#E2E2E2' }}>
                <Grid container direction="column" >
                     <Grid item>
                        <Box>
                            <Typography variant="h6" style={{fontSize:17}} >
                                Spans completed
                            </Typography>                            
                        </Box>
                     </Grid>
                     <Grid item style={{marginTop:20}}>
                         <Box>
                            <Typography variant="h6" style={{fontSize:18}} >
                                HBRA
                            </Typography>                            
                        </Box>
                     </Grid>
                     <Grid item>
                         <Box>
                            <Typography sx={{height:45}} variant="h6" style={{fontSize:26}} >
                                {hbraSpansWithAssessment}
                            </Typography>                            
                        </Box>
                     </Grid>
                     <Grid item>
                         <Box>
                            <Typography variant="h6" style={{fontSize:18, color: '#A7A7A7'}} >
                                of {totalFireSpansWithAssessment}
                            </Typography>                            
                        </Box>
                     </Grid>

                     <Grid item style={{marginTop:100}}>
                         <Box>
                            <Typography variant="h6" style={{fontSize:18}} >
                                LBRA
                            </Typography>                            
                        </Box>
                     </Grid>
                     <Grid item>
                         <Box>
                            <Typography sx={{height:45}} variant="h6" style={{fontSize:26}} >
                                {lbraSpansWithAssessment}
                            </Typography>                            
                        </Box>
                     </Grid>
                     <Grid item>
                         <Box>
                            <Typography variant="h6" style={{fontSize:18, color: '#A7A7A7'}} >
                                of {totalFireSpansWithAssessment}
                            </Typography>                            
                        </Box>
                     </Grid>
                </Grid>
             </Grid>
             <Grid item >
                <Grid container direction="column" spacing={1} >
                    <Grid item >
                      <Box sx={{ height: 200, width: 200 }} style={{marginTop:10}} >
                        {hbraChartConfig &&
                            <PieChart backgroundColor={'#222222'} chartConfig={hbraChartConfig}/>
                        }
                      </Box>
                    </Grid>
                    <Grid item >
                      <Box sx={{ height: 200, width: 200 }} style={{marginTop:20}} >
                        {lbraChartConfig &&
                            <PieChart backgroundColor={'#222222'} chartConfig={lbraChartConfig}/>
                        }
                      </Box>
                    </Grid>
                </Grid>
             </Grid>
             <Grid item style={{marginTop:20}}>
                <Grid 
                    sx={{height:80}}
                    container
                    direction="row"
                    alignItems="left"   
                >
                { chartLegends.map( (chartLegend: IChartLegend ) => {

                    return <Grid item >
                                <Box style={{color:'white',marginRight: 10}} >
                                    <Typography variant="h6" style={{fontSize:12}}>                    
                                        <span className={styles.dot} style={{height: 10, width: 10, backgroundColor: chartLegend.colour, marginRight:9 }}></span>
                                        {chartLegend.text}
                                    </Typography>
                                </Box>
                            </Grid>
                 })}
                </Grid>
             </Grid>
        </Grid>
    </Card>
}