import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from './ApprovalStatusFilter.module.css';
import TextField from '@mui/material/TextField';

interface IApprovalStatusFilterProps {
    onFilterChanged: Function;
    clearFilters: number;
    cycleId: string;
}

interface IApprovalStatusFilter {
    status: string;
    isSelected: boolean;
}

const labelStyle = {
    label: {
        fontSize: 14,
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7F4A8',
    },
};

export function ApprovalStatusFilter(props: IApprovalStatusFilterProps) {
    //ordered-alphabatically
    const defaultSatuses: IApprovalStatusFilter[] = [
        { status: 'Approved', isSelected: false },
        { status: 'Created', isSelected: false },
        { status: 'Deferred', isSelected: false },
        { status: 'On-hold', isSelected: false },
    ];

    const [ApprovalStatuses, setApprovalStatuses] = useState(defaultSatuses);
    const [filteredApprovalStatuses, setFilteredApprovalStatuses] = useState(defaultSatuses);
    const [lastClearFilters, setLastClearFilters] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const searchFilterRef = useRef<HTMLInputElement>(null);

    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {
        if (props.clearFilters !== lastClearFilters) {
            ApprovalStatuses.forEach((s) => (s.isSelected = false));
            setApprovalStatuses(ApprovalStatuses.slice());
            setSelectAll(false);
            setLastClearFilters(props.clearFilters);

            //reset the search field textbox
            if (searchFilterRef.current) {
                searchFilterRef.current.value = '';
            }
            setFilteredApprovalStatuses(ApprovalStatuses);
        }
    }, [props.clearFilters, lastClearFilters, ApprovalStatuses]);

    //Handle when cycle has been changed
    useEffect(() => {
        setFilteredApprovalStatuses(defaultSatuses);
        setApprovalStatuses(defaultSatuses);
    }, [props.cycleId]);

    function onSelectAll(selectAll: boolean) {
        if (!selectAll) {
            filteredApprovalStatuses.forEach((s) => (s.isSelected = true));
        } else {
            filteredApprovalStatuses.forEach((s) => (s.isSelected = false));
        }

        setApprovalStatuses(ApprovalStatuses.slice());
        let selectedApprovalStatuses = ApprovalStatuses.filter((s) => s.isSelected).map((s) => s.status);
        props.onFilterChanged(selectedApprovalStatuses);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(ApprovalStatus: IApprovalStatusFilter) {
        ApprovalStatus.isSelected = !ApprovalStatus.isSelected;
        setApprovalStatuses(ApprovalStatuses.slice());
        let selectedApprovalStatuses = ApprovalStatuses.filter((s) => s.isSelected).map((s) => s.status);
        props.onFilterChanged(selectedApprovalStatuses);
        syncSelectAll();
    }

    function syncSelectAll() {
        let selectedApprovalStatuses = ApprovalStatuses.filter((s) => s.isSelected);
        if (!selectAll && selectedApprovalStatuses.length === ApprovalStatuses.length) {
            setSelectAll(true);
        } else if (selectAll && selectedApprovalStatuses.length < ApprovalStatuses.length) {
            setSelectAll(false);
        }
    }

    function onApprovalStatusChange(value: string) {
        if (value === '') {
            syncSelectAll();
            setFilteredApprovalStatuses(ApprovalStatuses);
        } else {
            setFilteredApprovalStatuses(ApprovalStatuses.filter((s) => s.status.toLowerCase().includes(value.toLowerCase())));
        }
    }

    let ApprovalStatusCheckboxes: JSX.Element[] = [];
    filteredApprovalStatuses.forEach((ApprovalStatus) => {
        ApprovalStatusCheckboxes.push(
            <FormControlLabel
                key={ApprovalStatus.status}
                sx={{ '& .MuiFormControlLabel-label': ApprovalStatus.isSelected ? labelStyle.labelSelected : labelStyle.label }}
                control={
                    <Checkbox
                        color="secondary"
                        checked={ApprovalStatus.isSelected}
                        style={{ opacity: '0.5 !important' }}
                        onChange={() => onCheckboxChanged(ApprovalStatus)}
                        name={ApprovalStatus.status}
                        size={'small'}
                    />
                }
                label={ApprovalStatus.status}
            />
        );
    });

    let selectAllCheckbox = (
        <FormControlLabel
            key={'Select All'}
            sx={{ '& .MuiFormControlLabel-label': selectAll ? labelStyle.labelSelected : labelStyle.label }}
            control={
                <Checkbox color="secondary" style={{ opacity: '0.5 !important' }} checked={selectAll} onChange={() => onSelectAll(selectAll)} name={'Select All'} size={'small'} />
            }
            label={'Select All'}
        />
    );

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <FormGroup>
                    <TextField
                        inputRef={searchFilterRef}
                        size="small"
                        style={{ color: '#F7F4A8', marginTop: 8 }}
                        id="ApprovalStatus-filter"
                        label="Search"
                        onChange={(event) => onApprovalStatusChange(event.target.value)}
                    />
                    {selectAllCheckbox}
                    {ApprovalStatusCheckboxes}
                </FormGroup>
            </div>
        </div>
    );
}
