import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

import styles from "./LayerList.module.css";

import { LayerItem } from '../LayerItem/LayerItem';
import { IMapLayer } from '../../../models/IMapLayer';

interface IMapNetworkLayersProps {
    mapLayers: IMapLayer[][],
    onToggleVisibility: Function,
    displayPanel: boolean
}

export function LayerList(props: IMapNetworkLayersProps) {

    const [currentDisplayPanel, setDisplayPanel] = useState(props.displayPanel);
    const [layersJsx, setLayersJsx] = useState(new Array<JSX.Element>());
    useEffect(() => {
        if (props.displayPanel != currentDisplayPanel) {
            setDisplayPanel(props.displayPanel);
        }

    }, [props.displayPanel, currentDisplayPanel]);

    useEffect(() => {
        let tempJsx: JSX.Element[] = [];

        for (let i = 0; i < props.mapLayers.length; i++) {
            for (let j = 0; j < props.mapLayers[i].length; j++) {
                if (!props.mapLayers[i][j].hideFromMenu) {
                    tempJsx.push(<div key={i}>
                        <LayerItem
                            group={props.mapLayers[i][j].group}
                            label={props.mapLayers[i][j].label}
                            isActive={props.mapLayers[i][j].isActive}
                            isAvailable={props.mapLayers[i][j].isAvailable}
                            thumbnail={props.mapLayers[i][j].thumbnail}
                            onVisibilityToggle={(group: string, label: string, checked: boolean) => {
                                //bubble to cmplycontainer
                                props.onToggleVisibility(group, label, checked)
                            }
                            }
                        />
                    </div>)
                }
            }
        }
        setLayersJsx(tempJsx);

    }, [props.mapLayers]);


    let panelStyle = {
        display: currentDisplayPanel ? 'block' : 'none'
    }


    return (
        <Box display="flex" flexDirection="column" className={styles.listContainer} >
            <Box flexGrow={1} className={styles.content }>
                <Box height={'100%'} style={panelStyle} className={styles.LayerPanelStyle}>
                    <div className={styles.layerTabsContainer}>
                        <span>
                            <Typography className={styles.text} variant="body1" gutterBottom>
                                Map Layers
                            </Typography>
                        </span>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        {layersJsx}
                    </div>
                </Box>
            </Box>
        </Box>
    )
}
