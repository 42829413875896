import React from 'react';

import Box from '@mui/material/Box';
import LayersIcon from '@mui/icons-material/Layers';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';

import styles from "./MapToolbar.module.css";
import IconButton from '@mui/material/IconButton';

interface IMapToolbarProps {
    onShowMapBaseLayerPicker: Function;
    onShowNetworkShapefileVisibilityPanel: Function;
}

export function MapToolbar(props: IMapToolbarProps) {

    return <div>
        <Box display="flex" alignItems="center" justifyContent="center">
            <div className={styles.listContainer}>               
                <div className={styles.listItem} title='Network shapefile management'>
                    <IconButton
                        className={styles.iconBtn}
                        aria-controls="Shapefile-Mode"
                        onClick={() => props.onShowNetworkShapefileVisibilityPanel()} >
                        <AddLocationAltIcon />
                    </IconButton>
                </div>
                <div className={styles.listItem} title='Map base layer picker'>
                    <IconButton
                        className={styles.iconBtn}
                        aria-controls="BaseLayer-Mode"
                        onClick={() => props.onShowMapBaseLayerPicker()} >
                        <LayersIcon />
                    </IconButton>
                </div>
              
            </div>
        </Box>
    </div>
}
