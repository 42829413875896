
// Returns data and options config for use with react-chartjs-2 / chart.js
// See https://www.chartjs.org/docs/3.9.1/configuration/

function getConfigs() {
    return {
        type: 'line',
        options: {
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                },
            },
            scales: {
                y: {
                    beginAtZero: true,
                    display: true,
                    position: 'right',
                    min: 0,
                    ticks: {
                        beginAtZero: true,
                        color: 'white',
                        fontFamily: 'Roboto'
                    },
                    grid: {
                        borderDash: [5,5],
                        color: '#3f3f3f',
                    },
                },
                x: {
                    display: true,
                    ticks: {
                        color: 'white',
                        fontFamily: 'Roboto'
                    },
                    grid: {
                        display: false
                    },
                },
            }
        }
    };
}

export default getConfigs;
